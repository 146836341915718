import { logout } from '../../actions/userActions'
import React, { useState, useEffect, useToasts } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Card, Image } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Link, useParams, Route } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { screen_topics_sprints_a, logMathAdd_a, topics_a, sprintAdd_a, topic_a, lastSprint_a, randomQuestion_a, lastAnswer_a, answerNotFirstAdd_a, sprint_a } from '../../actions/mathActions'
import { GeistProvider, CssBaseline, Spinner } from "@geist-ui/react"
import { ToastContainer, toast, Zoom, Bounce, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { SPRINT_ADD_RESET } from '../../constants/mathConstants'
toast.configure()

function Sprint_Result_Screen({ match, history }) {

    const dispatch = useDispatch()

    const sprint_ = useSelector(state => state.sprint_r)
    const { error_sprint_sprint, loading_sprint_sprint, sprint_sprint } = sprint_

    const notify = () => { toast.success(
        <div>Поздравляем! Вы прошли тест! 🥳 </div>
        ,
      { 
        position: "bottom-right",
        autoClose: 6000, 
        delay: 500, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 71,
        theme: "colored",
        transition: Slide
        //  autoClose: false
      } ) }

    useEffect(() => { 
        dispatch(notify)
    }, [])

    const five = () => { toast.success(
        <div>на ОТЛИЧНО! ✌ </div>
        ,
      { 
        position: "bottom-right",
        autoClose: 6000, 
        delay: 500, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 72,
        theme: "colored",
        transition: Slide
        //  autoClose: false
      } ) }

    useEffect(() => { 
        if (sprint_sprint.score==5 && sprint_sprint._id == match.params.id) {
            dispatch(five)
        }
    }, [sprint_sprint])

    const better_score = () => { toast.success(
        <div>Вы улучшили средний балл! 👌  </div>
        ,
      { 
        position: "bottom-right",
        autoClose: 8000, 
        delay: 1500, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 73,
        theme: "colored",
        transition: Slide
        //  autoClose: false
      } ) }

    useEffect(() => { 
        if (sprint_sprint.score>sprint_sprint.avg_score && false) {
            dispatch(better_score)
        }
    }, [sprint_sprint])

    const better_percentile = () => { toast.success(
        <div>Вы улучшили средний процентиль! 👌  </div>
        ,
      { 
        position: "bottom-right",
        autoClose: 2000, 
        delay: 500, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 74,
        theme: "colored",
        transition: Slide
        //  autoClose: false
      } ) }

    useEffect(() => { 
        if (sprint_sprint.percentile>sprint_sprint.avg_percentile  && false) {
            dispatch(better_percentile)
        }
    }, [sprint_sprint])

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const topicsGrade_ = useSelector(state => state.topicsGrade_r)
    const { error_topics_grade, loading_topics_grade, topics_grade, topics_grade_page, topics_grade_pages } = topicsGrade_

    const topic_ = useSelector(state => state.topic_r)
    const { error_topic, loading_topic, topic_topic, topic_page, topic_pages } = topic_

    const randomQuestion_ = useSelector(state => state.randomQuestion_r)
    const { error_random_question, loading_random_question, random_question } = randomQuestion_

    const lastAnswer_ = useSelector(state => state.lastAnswer_r)
    const { error_last_answer, loading_last_answer, last_answer } = lastAnswer_

    const answerNotFirstAdd_ = useSelector(state => state.answerNotFirstAdd_r)
    const { success_answer_add } = answerNotFirstAdd_

    // const lastSprint_ = useSelector(state => state.lastSprint_r)
    // const { error_last_sprint, loading_last_sprint, last_sprint } = lastSprint_

    const sprintAdd_ = useSelector(state => state.sprintAdd_r)
    const { success_sprint_add, loading_sprint_add, new_sprint } = sprintAdd_

// log - start
let user_log = ''
if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
let page = history.location.pathname
let screen_width = useState(window.innerWidth)
let screen_height = useState(window.innerHeight)
useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])
// log - finish

    useEffect(() => {
        if (success_sprint_add) {
            dispatch({ type: SPRINT_ADD_RESET })
            history.push(`/s/${new_sprint._id}`)
        } else {
            dispatch(sprint_a(match.params.id))
        }
        // dispatch(lastAnswer_a())
    }, [success_sprint_add])
    
    const goBack = () => { history.goBack() }

    // console.log(history)

    const goToTopic = () => {
        localStorage.setItem('backbtn', JSON.stringify("true"))
        history.push(`/to/${sprint_sprint.topic._id}/?t=3`) 
    }

    // let id_id = Number(match.params.id)
    const create_sprint_handler = () => {
        localStorage.setItem('orderquestion', JSON.stringify(0))
        localStorage.setItem('backbtn', JSON.stringify("true"))
        // alert('hi')
        // alert(sprint_sprint.topic._id)
        // dispatch(sprintAdd_a(Number(115)))
        dispatch(sprintAdd_a(sprint_sprint.topic._id))
        // alert(report_number)
        // if (sprint_sprint.topic._id) { dispatch(sprintAdd_a(sprint_sprint.topic._id)) }
    }

{/* HANDLING BROWSER BACK - START */}
// const [ locationKeys, setLocationKeys ] = useState([])
// const history2 = useHistory()

// useEffect(() => {
//   return history2.listen(location => {
//     if (history2.action === 'PUSH') {
//       setLocationKeys([ location.key ])
//     }

//     if (history2.action === 'POP') {
//       if (locationKeys[1] === location.key) {
//         setLocationKeys(([ _, ...keys ]) => keys)
//         history2.goForward()
//       } else {
//         setLocationKeys((keys) => [ location.key, ...keys ])
//         // history2.push('/')
//         history2.push(`/to/${sprint_sprint.topic._id}/?t=3`)
//       }
//     }
//   })
// }, [ locationKeys, ])
{/* HANDLING BROWSER BACK - FINISH */}
 
    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        
        {loading_sprint_sprint ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
            : error_sprint_sprint ? <Message variant='danger'>{error_sprint_sprint}</Message>
                : <div>

    {/* Breadcrumb */} <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to={`/d/`}>Классы</Link></li>
    { (sprint_sprint.topic.grade) ? (
        <li class="breadcrumb-item"><Link to={`/g/${sprint_sprint.topic.grade._id}`}>
            {sprint_sprint.topic.grade.grade} класс
        </Link></li>    ):(<p> </p>) }

{/*
    { (sprint_sprint.topic) ? (
        <li class="breadcrumb-item"><Link to={`/to/${sprint_sprint.topic._id}/?t=3`}>
            {sprint_sprint.topic.topic}
        </Link></li>    ):(<p> </p>) }

    { (sprint_sprint) ? (
        <li class="breadcrumb-item"><Link to={`/result/${sprint_sprint._id}`}>            
            Результаты теста
        </Link></li>    ):(<p> </p>) }
*/}
    </ol> {/* breadcrumb-end */}

{/*
    <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
        <i className="fas fa-chevron-left"/> Назад
    </Button>
*/}

{/*
{sprint_sprint && 
<div>Номер спринта (будет скрыто): {sprint_sprint._id} <br/><br/> </div> }
*/}

<h3>Ваш результат</h3>

<div class="alert alert-dismissible alert-primary" style={{ borderRadius: 5, marginBottom: '25px' }}>

<Row className='p-0 m-0'>
    <Col xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} >
        <div style={{ display: '', width: '100%', hight: '100%' }} >
            <div style={{ marginTop: 9, marginLeft: -12, fontSize: 14, lineHeight : 1, color: "", width: '100%' }}>
                Оценка: <i className="far fa-star" style={{ color: '#2a9df4', fontSize: 30 }}> </i>
                <span> </span> <strong style={{ color: 'black', fontSize: 30 }}>{(sprint_sprint.score*1).toFixed(1)}</strong>
            </div> 
        </div>
    </Col>
    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
        <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
            <div style={{ color: 'black', border: '1px solid #2a9df4', marginTop: 17, width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                    {(sprint_sprint.percentile*1).toFixed(0)}%
                <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
            </div>
        </Link>
    </Col>
</Row>

<div >
    <hr style={{ marginLeft: '-5px', marginRight: '-30px',  }} />
</div>

    <div>Кол-во вопросов: <strong>{sprint_sprint.nq}</strong> <br/></div>
    {/* <div>Кол-во отвеченных вопросов: <strong>{sprint_sprint.nga}</strong> <br/></div> */}
    <div>Кол-во правильных ответов: <strong>{sprint_sprint.nca}</strong> <br/> </div>
    <div>Процент правильных ответов: <strong>{(sprint_sprint.nca/sprint_sprint.nq*100).toFixed(0) }%</strong> <br/> </div>
    <div>Общее время теста: <strong>{(sprint_sprint.time/1000).toFixed(1)} секунды</strong> </div>
</div>

{ sprint_sprint.best_to_date &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <span>
            Вы улучшили свой <strong>лучший результат</strong> по теме! 
        </span>
        <span> </span>
        <span>
            Оценка: <strong>{(sprint_sprint.score*1).toFixed(1)}</strong>, время прохождения теста: <strong>{(sprint_sprint.time/1000).toFixed(1)} сек</strong>! 
        </span>
    </div>
}

{ sprint_sprint.score == 5 &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            Вы получили оценку <strong>"Отлично"</strong>!
        </div>
    </div>
}

{ sprint_sprint.percentile >= 75 &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            Хороший результат! Ваш результат по тесту превосходит результаты <strong>{sprint_sprint.percentile}%</strong> всех ранее пройденных тестов (в т.ч. другими пользователями) в системе 
        </div>
    </div>
}

{ sprint_sprint.last_three_over_previous > 0 &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            <strong>Средняя оценка</strong> по "трём последним тестам" <strong>выросла</strong> на {sprint_sprint.last_three_over_previous}% по сравнению со средней оценкой по "трём предшествующим тестам"
        </div>
    </div>
}

{ sprint_sprint.last_three_over_first > 0 &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            <strong>Средняя оценка</strong> по "трём последним тестам" <strong>выше</strong> на {sprint_sprint.last_three_over_first}% по сравнению со средней оценкой по "трём первым тестам"
        </div>
    </div>
}

{ (sprint_sprint.last_five_over_previous > 0 && false) &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            <strong>Средняя оценка</strong> по "пяти последним тестам" <strong>выросла</strong> на {sprint_sprint.last_five_over_previous}% по сравнению со средней оценкой по "пяти предшествующим тестам"
        </div>
    </div>
}

{ (sprint_sprint.last_five_over_first > 0 && false) &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            <strong>Средняя оценка</strong> по "пяти последним тестам" <strong>выше</strong> на {sprint_sprint.last_five_over_first}% по сравнению со средней оценкой по "пяти первым тестам"
        </div>
    </div>
}

{ sprint_sprint.score > sprint_sprint.avg_score &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            Вы <strong>улучшили</strong> вашу <strong>среднюю оценку</strong> по теме!
        </div>
    </div>
}

{ sprint_sprint.percentile > sprint_sprint.avg_percentile &&
    <div class="alert alert-dismissible alert-success" style={{ borderRadius: 5, marginBottom: '25px', marginLeft: '40px', marginRight: '40px' }}>
        <div>
            Вы <strong>улучшили</strong> ваш <strong>средний процентиль</strong> по теме!
        </div>
    </div>
}

<div style={{ margin: '15px' }}>
<div>
    <button type="button" class="btn btn-link" onClick={create_sprint_handler}>
        <span style={{ fontSize: 16, lineHeight : 1, textAlign: "left", paddingTop: "0px", marginBottom: "15px"}}>
        <div className="m-0 p-0 mb-1">Пройти тест еще раз</div></span>
    </button>
</div>
<div>
    <button type="button" class="btn btn-link" onClick={goToTopic}>
        <span style={{ fontSize: 16, lineHeight : 1, textAlign: "left", paddingTop: "0px",}}>
        <div className="m-0 p-0 mb-3">Вернуться на страницу темы "{sprint_sprint.topic.topic}"</div></span>
    </button>
</div>
</div>

<h3 className="mt-5 mb-0">Тестовые вопросы с ответами</h3>

{sprint_sprint.answers.map(x => (
    <Col className="my-4 mx-0 p-0" key={x._id} xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>                                
    <Card><Card.Body className="my-0 mx-0 px-3 py-2">
<Row>
    <Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
        <span style={{ fontSize: 16, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
        <span>{x.order}.</span> 
        
        { x.question.prebody && 
        <span> {x.question.prebody}  |  </span> }

        { x.question.body && 
            <span> {x.question.body} </span> }
        
        { x.question.afterbody && 
            <span>  |  {x.question.afterbody}</span> }
            
        </span>
    </Col>
</Row>
<Row>
    <Col className="p-0 ml-2 mt-2" style={{ marginLeft: '25px' }}>
        {x.correctness ? <span><strong style={{color: "green"}}>Правильно </strong> <span> ({(x.time/1000).toFixed(1)} сек)</span></span> : <span><strong style={{color: "red"}}>Неправильно</strong> <span> ({(x.time/1000).toFixed(1)} сек)</span></span> }
        {false ? <span></span> : <div className="p-0 my-1"><span> Ваш ответ: {x.answer}</span></div>}
        {x.correctness ? <span></span> : <div className="p-0 my-1"><span> Правильный ответ: {x.question.correct}</span></div>}
    </Col>
</Row>
</Card.Body></Card></Col>))}

</div> }
</GeistProvider>        </Container>
    )
}

export default Sprint_Result_Screen
