import React from 'react'
import { Spinner } from 'react-bootstrap'   // importing of bootstrap elements
import { useBootstrapPrefix } from 'react-bootstrap/esm/ThemeProvider'

// code can be obtained from react-bootstrap.github.io
function Loader() {
    return (
        <Spinner
            animation='border'
            role='status'
            style={{
                height: '100px',
                width: '100px',
                margin: 'auto',
                display: 'block'
            }}
        >
            {/* what is it? why is span bellow needed?  */}
            <span className='sr-only'>Loading...</span>
        </Spinner>
    )
}

export default Loader
