import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
// import { pw_reset2_a } from '../actions/userActions'
import { pw_change_request_a, logMathAdd_a } from '../actions/mathActions'
import Menu from '../components/Menu'


function AccountPwRequest_Screen({ location, history }) {

    // const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    // const [password, setPassword] = useState('')
    // const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo } = userRegister

    // useEffect(() => {
    //     if (userInfo) {
    //         history.push(redirect)
    //     }
    // }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        // dispatch(pw_reset2_a(email))
        dispatch(pw_change_request_a({
            email
        }))
        history.push('/pw_rr')
    }

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])
    
    const goBack = () => { history.goBack() }

    return (
        <div>
        <FormContainer>

        <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
            <i className="fas fa-chevron-left"/> Назад
        </Button>

            <h3 style={{ marginTop: '30px', fontWeight: 'bold' }}>Запрос на сброс пароля</h3>
{/*
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
*/}

<div style={{ marginTop: '10px' }}>
    Чтобы запросить сброс пароля для Вашего аккаунта нажмите на кнопку ниже.
</div>
<div style={{ marginTop: '10px' }}>
    После этого Вы получите емейл с линком, нажав на который вы сбросите старый пароль. 
</div>
<div style={{ marginTop: '10px' }}>
    После сброса пароля - новый пароль будет сгенерирован и отправлен на Ваш емейл. 
</div>

            <Form onSubmit={submitHandler}>
            <div>

                <Form.Group controlId='email' style={{ marginTop: '10px' }}>
                    <Form.Control
                        required
                        type='email'
                        placeholder='Введите Ваш емейл'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary' className="my-3">
                    Запросить восстановление пароля
                </Button>

            </div>
            </Form>

        </FormContainer>
        </div>
    )
}

export default AccountPwRequest_Screen
