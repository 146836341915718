import { logout } from '../actions/userActions'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Table, Modal, Card, ProgressBar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { stat_a } from '../actions/mathActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import Menu from '../components/Menu'
import FormContainer from '../components/FormContainer'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"
import { logMathAdd_a } from '../actions/mathActions'

function UserProfile_Screen({ history }) {

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [birth_year, setBirth_year] = useState('')
    const [usergrade, setUsergrade] = useState('')
    const [city, setCity] = useState('')
    const [school, setSchool] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfileReducer)
    const { update_profile_success } = userUpdateProfile

    const stat_ = useSelector(state => state.stat_r)
    const { error_stat, loading_stat, w_sprints_number, w_topics_number, w_grades_number, w_sum_time, w_number_of_5, w_score, w_correct_percent, w_percentile, w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0, today_sprints_number, today_topics_number, today_grades_number, today_sum_time, today_number_of_5, today_score, today_correct_percent, today_percentile, today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0, week_sprints_number, week_topics_number, week_grades_number, week_sum_time, week_number_of_5, week_score, week_correct_percent, week_percentile, week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0 } = stat_

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!user || !user.name || update_profile_success || userInfo._id !== user._id) {
            // if (false) {
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails('profile'))

            } else if (user.profile) {
                setName(user.profile.user_name)
                setSurname(user.profile.user_surname)
                setBirth_year(user.profile.user_birth_year)
                setUsergrade(user.profile.user_grade)
                setCity(user.profile.user_city)
                setSchool(user.profile.user_school)
                setEmail(user.email)
            }

        }
    }, [dispatch, history, userInfo, user, update_profile_success])

    useEffect(() => {
        dispatch(stat_a())
    }, [dispatch, history ])


    const logoutHandler = () => {
        history.push('/login/')
        dispatch(logout())
    }



    const submitHandler = (e) => {
        e.preventDefault()

        if (password != confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'surname': surname, 
                'birth_year': birth_year,
                'usergrade': usergrade,
                'city': city,
                'school': school,
                'email': email,
                'password': password,
            }))
            setMessage('')
            handleShow()
        }
    }

    const goBack = () => { history.goBack() }
    
    return (
        <div>
        <GeistProvider>

        {/* Breadcrumb */} 
<ol class="breadcrumb" style={{ backgroundColor: '', padding: 0, margin: '5px' }} >
<li class="breadcrumb-item"><Link onClick={goBack} style={{ color: "#2a9df4" }}><i className="fas fa-chevron-left" style={{ marginRight: 3 }}></i>Назад</Link></li>
<li class="breadcrumb-item"><Link to={`/db/`} style={{ color: "#2a9df4" }}>Центр</Link></li>
</ol> {/* breadcrumb-end */}

            {/* <Col md={3}> */}
                <h3 style={{ marginBottom: 20, marginTop: 20, marginLeft: 10, fontWeight: 'bold' }}>Профайл</h3>

                {message && <Message variant='danger'>{message}</Message>}
{error && <div><Message variant='danger'>{error}</Message><div style={{ textAlign: 'center'}}>
<Message variant='primary'><h5><strong>Пожалуйста, войдите в систему заново</strong></h5>
<Button variant="primary" onClick={logoutHandler} size="sm" className="mb-3">
<strong >Войти в систему</strong></Button></Message></div></div>}

                {loading || loading_stat ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
                : <div>

<div style={{ paddingLeft: '0%', paddingRight: '0%', paddingTop: 20  }}>

<Row>
    <Col>

{ user && 
<div>
{ user.profile && 
<Row style={{ marginTop: 0, marginBottom: 20, marginLeft: '5px' }}>
    <Col xxs={4} xs={4} sm={3} md={2} lg={2} xl={2}>
        <div>
            <div >
                <Card class="p-0 m-0 mb-2" style={{ border: 'none' }}>
                    <Card.Img src={user.profile.user_photo} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
                </Card>
            </div>
        </div>
        <div style={{ margin: 0, padding: 0, marginBottom: 20, marginTop: 8 }}>
            <button type="button" class="btn btn-link" style={{ margin: 0, padding: 0 }}><Link style={{ fontSize: 12, color: '#2a9df4' }} to={`/image`}><i style={{color: ''}} className="fas fa-edit"></i> Изменить</Link></button>
        </div>
    </Col>
    <Col xxs={8} xs={8} sm={9} md={10} lg={10} xl={10}>
    <div style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}>
        <div style={{ marginTop: 0, marginBottom: 0, marginLeft: 0 }}>
            <div style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}>
                {user.profile.user_name} {user.profile.user_surname}
            </div>
        </div>
        <div style={{ marginTop: 0, marginBottom: 0 }}>
            <div style={{ fontSize: 14, color: 'black', fontWeight: '' }}>
                {user.profile.user_grade} класс
            </div>
        </div>

        <div style={{ marginTop: 0, marginBottom: 0 }}>
            <div style={{ fontSize: 14, color: 'black' }}>
                Школа: {user.profile.user_school}
            </div>
        </div>
        <div style={{ marginTop: 0, marginBottom: 0 }}>
            <div style={{ fontSize: 14, color: 'black', fontWeight: '' }}>
                г. {user.profile.user_city}
            </div>
        </div>

        <div style={{ marginTop: 0, marginBottom: 10 }}>
            <div style={{ fontSize: 14, color: 'black' }}>
                {user.email}
            </div>
        </div>

        <div style={{ margin: 0, padding: 0 }}>
            <button type="button" class="btn btn-link" style={{ margin: 0, padding: 0 }}><Link style={{ fontSize: 12, color: '#2a9df4' }} to={`/profile_edit`}><i style={{color: ''}} className="fas fa-edit"></i> Изменить данные</Link></button>
        </div>
        <div style={{ margin: 0, padding: 0 }}>
            <button type="button" class="btn btn-link" style={{ margin: 0, padding: 0 }}><Link style={{ fontSize: 12, color: '#2a9df4' }} to={`/password_change`}><i style={{color: ''}} className="fas fa-edit"></i> Сменить пароль</Link></button>
        </div>
    </div>
    </Col>
</Row>
}
</div>
}
            </Col>
        </Row>

        <div style={{ color: '', fontSize: 24, marginTop: 15, marginBottom: 15, marginLeft: '5px' }}><i className="fas fa-medal" style={{ color: '#2a9df4', marginBottom: 15 }}> </i> Мастерство в <strong>{w_number_of_5}</strong> темах</div>


{/* DESKTOP - START */}
<div className="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block ">
<Row >

{/* DAY CARD - START */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={10} md={8} lg={6} xl={5}>
<Card className='my-3 mx-3 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px" }}>
    <strong><strong style={{ fontSize: 20, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>СЕГОДНЯ </strong> вы прошли:</strong> </div>

<div className='mx-3'>
    <div><i className="fas fa-running" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{today_sprints_number}</strong> тестов <span style={{ fontSize: 12, color: 'grey' }}> [в {today_topics_number} темах / {today_grades_number} классах] </span></div>
    <div><i className="far fa-clock" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{(today_sum_time*1).toFixed(0)}</strong> минут <span style={{ fontSize: 12, color: 'grey' }}> [общее время]</span></div>
</div>
<hr/>
<div>

    <Row>
        <Col>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}><i style={{color: '#2a9df4'}} className="far fa-star"></i> {(today_score*1).toFixed(2)} <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> средняя оценка </span></div>
        </Col>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} className="m-0 p-0">
            <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '7px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                    {(today_percentile*1).toFixed(0)}%
                    <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
                </Link>
            </div>
        </Col>
    </Row>

    <div style={{marginLeft: 30}} >
        <Row>
            <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} style={{fontSize: 13}}>
                <div><i style={{color: ''}} className="far fa-"></i> 5.00</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.50-4.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.00-4.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 3.50-3.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 2.50-3.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 1.50-2.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> до 1.50</div>
            </Col>
            <Col xxs={7} xs={7} sm={7} md={7} lg={7} xl={7}>
                <div class="progress" style={{ marginTop: 6, maginBottom: 29 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_50 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_45 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_40 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_35 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_25 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_15 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_0 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
            </Col>
        </Row>
    </div>

    <Row>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15 }}>
                {(today_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> правильные ответы </span>
            </div>
        </Col>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15, textAlign: 'right' }}>
                {(100 - today_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> неправильные </span>
            </div>
        </Col>
    </Row>

    <ProgressBar>
        <ProgressBar variant="#93e9be" style={{ backgroundColor: '#93e9be' }} now={today_correct_percent} key={1} />
        <ProgressBar variant="#ff7070" style={{ backgroundColor: '#ffa1a1' }} now={100-today_correct_percent} key={3} />
    </ProgressBar>

</div>

</Card>
</Col>
{/* DAY CARD - END */}

{/* ALL CARD - START */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={10} md={8} lg={6} xl={5}>
<Card className='my-3 mx-3 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px" }}>
    <strong><strong style={{ fontSize: 20, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>ЗА ВСЁ ВРЕМЯ </strong> вы прошли:</strong> </div>

<div className='mx-3'>
    <div><i className="fas fa-running" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{w_sprints_number}</strong> тестов <span style={{ fontSize: 12, color: 'grey' }}> [в {w_topics_number} темах / {w_grades_number} классах] </span></div>
    <div><i className="far fa-clock" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{(w_sum_time*1).toFixed(0)}</strong> минут <span style={{ fontSize: 12, color: 'grey' }}> [общее время]</span></div>
</div>
<hr/>
<div>

    <Row>
        <Col>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}><i style={{color: '#2a9df4'}} className="far fa-star"></i> {(w_score*1).toFixed(2)} <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> средняя оценка </span></div>
        </Col>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} className="m-0 p-0">
            <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '7px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                    {(w_percentile*1).toFixed(0)}%
                    <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
                </Link>
            </div>
        </Col>
    </Row>

    <div style={{marginLeft: 30}} >
        <Row>
            <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} style={{fontSize: 13}}>
                <div><i style={{color: ''}} className="far fa-"></i> 5.00</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.50-4.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.00-4.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 3.50-3.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 2.50-3.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 1.50-2.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> до 1.50</div>
            </Col>
            <Col xxs={7} xs={7} sm={7} md={7} lg={7} xl={7}>
                <div class="progress" style={{ marginTop: 6, maginBottom: 29 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_50 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_45 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_40 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_35 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_25 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_15 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_0 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
            </Col>
        </Row>
    </div>

    <Row>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15 }}>
                {(w_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> правильные ответы </span>
            </div>
        </Col>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15, textAlign: 'right' }}>
                {(100 - w_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> неправильные </span>
            </div>
        </Col>
    </Row>

    <ProgressBar>
        <ProgressBar variant="#93e9be" style={{ backgroundColor: '#93e9be' }} now={w_correct_percent} key={1} />
        <ProgressBar variant="#ff7070" style={{ backgroundColor: '#ffa1a1' }} now={100-w_correct_percent} key={3} />
    </ProgressBar>

</div>

</Card>
</Col>
{/* ALL CARD - END */}

{/* WEEK CARD - START */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={10} md={8} lg={6} xl={5}>
<Card className='my-3 mx-3 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px" }}>
    <strong><strong style={{ fontSize: 20, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>ЗА НЕДЕЛЮ </strong> вы прошли:</strong> </div>

<div className='mx-3'>
    <div><i className="fas fa-running" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{week_sprints_number}</strong> тестов <span style={{ fontSize: 12, color: 'grey' }}> [в {week_topics_number} темах / {week_grades_number} классах] </span></div>
    <div><i className="far fa-clock" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{(week_sum_time*1).toFixed(0)}</strong> минут <span style={{ fontSize: 12, color: 'grey' }}> [общее время]</span></div>
</div>
<hr/>
<div>

    <Row>
        <Col>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}><i style={{color: '#2a9df4'}} className="far fa-star"></i> {(week_score*1).toFixed(2)} <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> средняя оценка </span></div>
        </Col>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} className="m-0 p-0">
            <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '7px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                    {(week_percentile*1).toFixed(0)}%
                    <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
                </Link>
            </div>
        </Col>
    </Row>

    <div style={{marginLeft: 30}} >
        <Row>
            <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} style={{fontSize: 13}}>
                <div><i style={{color: ''}} className="far fa-"></i> 5.00</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.50-4.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.00-4.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 3.50-3.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 2.50-3.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 1.50-2.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> до 1.50</div>
            </Col>
            <Col xxs={7} xs={7} sm={7} md={7} lg={7} xl={7}>
                <div class="progress" style={{ marginTop: 6, maginBottom: 29 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_50 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_45 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_40 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_35 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_25 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_15 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_0 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
            </Col>
        </Row>
    </div>

    <Row>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15 }}>
                {(week_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> правильные ответы </span>
            </div>
        </Col>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15, textAlign: 'right' }}>
                {(100 - week_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> неправильные </span>
            </div>
        </Col>
    </Row>

    <ProgressBar>
        <ProgressBar variant="#93e9be" style={{ backgroundColor: '#93e9be' }} now={week_correct_percent} key={1} />
        <ProgressBar variant="#ff7070" style={{ backgroundColor: '#ffa1a1' }} now={100-week_correct_percent} key={3} />
    </ProgressBar>

</div>

</Card>
</Col>
{/* WEEK CARD - END */}

</Row>
</div>
{/* DESKTOP - END */}

{/* MOBILE - START */}
<div className="d-xs-block d-sm-block d-md-none d-lg-none d-xl-none m-0 p-0" >

<Tabs initialValue="1" style={{ marginLeft: '5px', marginRight: '5px', paddingLeft: 0, paddingRight: 0,  }}>
  <Tabs.Item label="Сегодня" value="1">

{/* DAY CARD - START */}
<Card className='my-3 mx-0 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none', marginRight: '0px' }}>

    <div className="m-0 p-0 mb-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", marginRight: '-50px' }}>
    <strong><strong style={{ fontSize: 20, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>СЕГОДНЯ </strong> вы прошли:</strong> </div>

<div className='mx-3'>
    <div><i className="fas fa-running" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{today_sprints_number}</strong> тестов <span style={{ fontSize: 12, color: 'grey' }}> [в {today_topics_number} темах / {today_grades_number} классах] </span></div>
    <div><i className="far fa-clock" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{(today_sum_time*1).toFixed(0)}</strong> минут <span style={{ fontSize: 12, color: 'grey' }}> [общее время]</span></div>
</div>
<hr/>
<div>

    <Row>
        <Col>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}><i style={{color: '#2a9df4'}} className="far fa-star"></i> {(today_score*1).toFixed(2)} <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> средняя оценка </span></div>
        </Col>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} className="m-0 p-0">
            <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '7px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                    {(today_percentile*1).toFixed(0)}%
                    <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
                </Link>
            </div>
        </Col>
    </Row>

    <div style={{marginLeft: 40}} >
        <Row>
            <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} style={{fontSize: 13}}>
                <div><i style={{color: ''}} className="far fa-"></i> 5.00</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.50-4.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.00-4.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 3.50-3.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 2.50-3.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 1.50-2.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> до 1.50</div>
            </Col>
            <Col xxs={7} xs={7} sm={7} md={7} lg={7} xl={7}>
                <div class="progress" style={{ marginTop: 6, maginBottom: 29 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_50 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_45 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_40 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_35 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_25 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_15 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: today_count_0 / Math.max(today_count_50, today_count_45, today_count_40, today_count_35, today_count_25, today_count_15, today_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
            </Col>
        </Row>
    </div>

    <Row>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15 }}>
                {(today_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> прав. ответы </span>
            </div>
        </Col>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15, textAlign: 'right' }}>
                {(100 - today_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> неправильные </span>
            </div>
        </Col>
    </Row>

    <ProgressBar>
        <ProgressBar variant="#93e9be" style={{ backgroundColor: '#93e9be' }} now={today_correct_percent} key={1} />
        <ProgressBar variant="#ff7070" style={{ backgroundColor: '#ffa1a1' }} now={100-today_correct_percent} key={3} />
    </ProgressBar>

</div>

</Card>

{/* DAY CARD - END */}

</Tabs.Item>
<Tabs.Item label="Неделя" value="2">

{/* WEEK CARD - START */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={10} md={8} lg={6} xl={5}>
<Card className='my-3 mx-0 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px" }}>
    <strong><strong style={{ fontSize: 20, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>ЗА НЕДЕЛЮ </strong> вы прошли:</strong> </div>

<div className='mx-3'>
    <div><i className="fas fa-running" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{week_sprints_number}</strong> тестов <span style={{ fontSize: 12, color: 'grey' }}> [в {week_topics_number} темах / {week_grades_number} классах] </span></div>
    <div><i className="far fa-clock" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{(week_sum_time*1).toFixed(0)}</strong> минут <span style={{ fontSize: 12, color: 'grey' }}> [общее время]</span></div>
</div>
<hr/>
<div>

    <Row>
        <Col>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}><i style={{color: '#2a9df4'}} className="far fa-star"></i> {(week_score*1).toFixed(2)} <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> средняя оценка </span></div>
        </Col>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} className="m-0 p-0">
            <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '7px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                    {(week_percentile*1).toFixed(0)}%
                    <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
                </Link>
            </div>
        </Col>
    </Row>

    <div style={{marginLeft: 40}} >
        <Row>
            <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} style={{fontSize: 13}}>
                <div><i style={{color: ''}} className="far fa-"></i> 5.00</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.50-4.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.00-4.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 3.50-3.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 2.50-3.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 1.50-2.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> до 1.50</div>
            </Col>
            <Col xxs={7} xs={7} sm={7} md={7} lg={7} xl={7}>
                <div class="progress" style={{ marginTop: 6, maginBottom: 29 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_50 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_45 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_40 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_35 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_25 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_15 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: week_count_0 / Math.max(week_count_50, week_count_45, week_count_40, week_count_35, week_count_25, week_count_15, week_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
            </Col>
        </Row>
    </div>

    <Row>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15 }}>
                {(week_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> прав. ответы </span>
            </div>
        </Col>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15, textAlign: 'right' }}>
                {(100 - week_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> неправильные </span>
            </div>
        </Col>
    </Row>

    <ProgressBar>
        <ProgressBar variant="#93e9be" style={{ backgroundColor: '#93e9be' }} now={week_correct_percent} key={1} />
        <ProgressBar variant="#ff7070" style={{ backgroundColor: '#ffa1a1' }} now={100-week_correct_percent} key={3} />
    </ProgressBar>

</div>

</Card>
</Col>
{/* WEEK CARD - END */}

</Tabs.Item>
<Tabs.Item label="Всё" value="3">

{/* ALL CARD - START */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={10} md={8} lg={6} xl={5}>
<Card className='my-3 mx-0 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px" }}>
    <strong><strong style={{ fontSize: 20, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>ЗА ВСЁ ВРЕМЯ </strong> вы прошли:</strong> </div>

<div className='mx-3'>
    <div><i className="fas fa-running" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{w_sprints_number}</strong> тестов <span style={{ fontSize: 12, color: 'grey' }}> [в {w_topics_number} темах / {w_grades_number} классах] </span></div>
    <div><i className="far fa-clock" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{(w_sum_time*1).toFixed(0)}</strong> минут <span style={{ fontSize: 12, color: 'grey' }}> [общее время]</span></div>
</div>
<hr/>
<div>

    <Row>
        <Col>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}><i style={{color: '#2a9df4'}} className="far fa-star"></i> {(w_score*1).toFixed(2)} <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> средняя оценка </span></div>
        </Col>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} className="m-0 p-0">
            <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '7px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                    {(w_percentile*1).toFixed(0)}%
                    <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
                </Link>
            </div>
        </Col>
    </Row>

    <div style={{marginLeft: 40}} >
        <Row>
            <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} style={{fontSize: 13}}>
                <div><i style={{color: ''}} className="far fa-"></i> 5.00</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.50-4.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.00-4.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 3.50-3.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 2.50-3.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 1.50-2.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> до 1.50</div>
            </Col>
            <Col xxs={7} xs={7} sm={7} md={7} lg={7} xl={7}>
                <div class="progress" style={{ marginTop: 6, maginBottom: 29 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_50 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_45 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_40 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_35 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_25 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_15 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: w_count_0 / Math.max(w_count_50, w_count_45, w_count_40, w_count_35, w_count_25, w_count_15, w_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
            </Col>
        </Row>
    </div>

    <Row>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15 }}>
                {(w_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> прав. ответы </span>
            </div>
        </Col>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15, textAlign: 'right' }}>
                {(100 - w_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> неправильные </span>
            </div>
        </Col>
    </Row>

    <ProgressBar>
        <ProgressBar variant="#93e9be" style={{ backgroundColor: '#93e9be' }} now={w_correct_percent} key={1} />
        <ProgressBar variant="#ff7070" style={{ backgroundColor: '#ffa1a1' }} now={100-w_correct_percent} key={3} />
    </ProgressBar>

</div>

</Card>
</Col>
{/* ALL CARD - END */}

</Tabs.Item>
</Tabs>

</div>
{/* MOBILE - END */}

</div>
</div>
}
        </GeistProvider>
        </div>
    )
}

export default UserProfile_Screen