import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import { logMathAdd_a, pi1_a, user_activate_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"
import FormContainer from '../../components/FormContainer'



function N6_Payment_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()

    const [isActive1, setIsActive1] = useState(false)
    const [isActive2, setIsActive2] = useState(false)
    const [isActive3, setIsActive3] = useState(false)

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    const goBack = () => { history.goBack() }

    const firstHandler = (e) => {
        e.preventDefault()
        alert('Подписка доступна для пользователей, которые ознакомились и согласились с Политикой конфиденциальности персональных данных, а также с Публичной оффертой. Пожалуйста ознакомьтесь с документами и в случае согласия поставьте галочки.')
    }
    const secondHandler = (e) => {
        e.preventDefault()
        history.push('/n/n7/')
    }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

    <div style={{ marginTop: 20, marginBottom: 0, fontSize: 24 }}>

<FormContainer>

<Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
    <i className="fas fa-chevron-left"/> Назад
</Button>

<Card className='my-3 mx-3 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-5 mt-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", fontSize: 36, fontWeight: 'bold' }}>
        Покупка
    </div>

    <div className="m-0 p-0 mb-4" style={{ lineHeight : 1.2, color: "#000000", textAlign: "left", paddingTop: "0px", fontSize: 16 }}>
        В настоящий момент продажи подписки закрыты. Доступ к сервису <strong>Matematika Plus</strong> может быть приобретен 1 июня 2022 года.
    </div>

{/*
    <div className="p-0 mb-4" style={{ lineHeight : 1.2, color: "#000000", textAlign: "left", paddingTop: "0px", marginLeft: 20, fontSize: 16 }}>
        <i className="fas fa-barcode" style={{ color: '#2a9df4', marginRight: 10 }}> </i>
        Стоимость подписки: <strong>  <span> 5990 </span> тенге </strong>
    </div>
    <div className="p-0 mb-4" style={{ lineHeight : 1.2, color: "#000000", textAlign: "left", paddingTop: "0px", marginLeft: 20, fontSize: 16 }}>
        <i className="far fa-calendar-alt" style={{ color: '#2a9df4', marginRight: 10 }}> </i>
        Срок подписки: <strong>6 месяцев</strong>
    </div>


<hr className="my-4" />

{ (true) ? 
<div style={{ textAlign: 'center' }}>
<Link onClick={secondHandler}>
    <button disabled={ false } type="button" class="btn btn-primary btn-sm p-1 px-2 m-3" style={{ fontSize: 16 }} > 
        <i className="far fa-credit-card" style={{ marginRight: 10 }}> </i>
        Приобрести доступ
    </button>
</Link>
</div>
:
<div style={{ textAlign: 'center' }}>
<Link onClick={firstHandler}>
    <button disabled={ !isActive1 || !isActive2 } type="button" class="btn btn-primary btn-sm p-1 px-2 m-3" style={{ fontSize: 16 }} > 
        <i className="far fa-credit-card" style={{ marginRight: 10 }}> </i>
        Приобрести доступ
    </button>
</Link>
</div>
}

*/}



<div>


</div>

</Card>

</FormContainer>



</div>
</div>

</Container>
</GeistProvider>
</Container>
    )
}

export default N6_Payment_Screen
