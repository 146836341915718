// rfce - to autofill code for components 

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, Row, NavDropdown, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import { Link } from 'react-router-dom'
// import Message from './Message'
import { getUserDetails } from '../actions/userActions'
import logoImage from '../logo.jpg';
import Menu from '../components/Menu'
import {useHistory} from "react-router-dom"
import { GeistProvider, CssBaseline } from "@geist-ui/react"
import { Modal, useModal } from "@geist-ui/react"
import ReactPlayer from "react-player"


function Header() {

    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails

    let history=useHistory()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch()


    // useEffect(() => {
    //     if (!userInfo) {
    //         dispatch(alogin())
    //     }
    // }, [])



    const logoutHandler = () => {
        history.push('/')
        dispatch(logout())
    }

    const { visible, setVisible, bindings } = useModal()
    
    // later should be switched off !!! 
    useEffect(() => {
        if (!user.profile) { 
            dispatch(getUserDetails('profile'))
        }
    }, [])
    
    return (
        <div className='p-0 m-0' >
        <Menu />
        
        <header className="m-0 p-0 d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block " >
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect  className='p-0 m-0'>
                <Container >
                    <LinkContainer to='/' >
                        <Navbar.Brand  >
                            <img src={logoImage}  width="45px" height="35px" />
                        </Navbar.Brand>
                    </LinkContainer>

{/*
                    {userInfo &&
                    <Nav>
                    <LinkContainer to='/'>
                        <Nav.Link ><i className="fas fa-home"></i> Главная</Nav.Link>
                    </LinkContainer>
                    </Nav>
                    }
*/}

{!userInfo &&
    <Nav>
    <LinkContainer to='/contacts/'>
        <Nav.Link ><i className="far fa-comments" style={{ color: '#a1caf1' }}></i> Контакты</Nav.Link>
    </LinkContainer>
    </Nav>
    }

                    {userInfo &&
                    <Nav>
                    <LinkContainer to='/db/'>
                        <Nav.Link ><i className="fas fa-"></i> Центр</Nav.Link>
                    </LinkContainer>
                    </Nav>
                    }

                    {userInfo &&
                    <Nav>
                    <LinkContainer to='/d/'>
                        <Nav.Link ><i className="fas fa-"></i> Классы</Nav.Link>
                    </LinkContainer>
                    </Nav>
                    }

                    {userInfo &&
                        <Nav>
                        <LinkContainer to='/profile/'>
                            <Nav.Link ><i className="far fa-"></i> Профайл</Nav.Link>
                        </LinkContainer>
                        </Nav>
                    }

                    {userInfo &&
                        <Nav>
                        <LinkContainer to='/map/'>
                            <Nav.Link ><i className="fas fa-"></i> Программа</Nav.Link>
                        </LinkContainer>
                        </Nav>
                    }

                    {userInfo &&
                        <Nav>
                        <LinkContainer to='/mp/'>
                            <Nav.Link ><i className="fas fa-"></i> Усвоение тем</Nav.Link>
                        </LinkContainer>
                        </Nav>
                    }

                    {userInfo &&
                    <Nav>
                    <LinkContainer to='/r/'>
                        <Nav.Link ><i className="fas fa-"></i> Пройденные тесты</Nav.Link>
                    </LinkContainer>
                    </Nav>
                    }


        { (user && false) && <div>
            {user.profile && <div>
                {((!user.profile.act || true ) && user.profile.showbuy && false)
                    &&
                    <Link to='/n/n6/'>
                        <Button className='p-0 m-0 px-2'>
                            <span style={{ lineHeight: 1, fontSize: 12 }}>Купить доступ</span>
                        </Button>
                    </Link>
                }
            </div> }
        </div>}
                    
                    {/*
                    {!userInfo &&
                        <div>
                    <Button auto onClick={() => setVisible(true)}><span style={{ lineHeight: 1, fontSize: 12 }}>Видео о нас</span></Button>
                          <Modal {...bindings}>
                            <Modal.Subtitle><strong>Видео о нас</strong></Modal.Subtitle>
                            <Modal.Content>
                              <p>Узнайте за 5 минут:</p>
                              <p style={{ marginLeft: 20 }}>Как улучшить <strong> математические навыки</strong> благодаря <strong>Matematika Plus</strong> </p>
                                <div>
                                    <div>
                                        <ReactPlayer 
                                            url='https://vimeo.com/600168490' 
                                            width='320px'
                                            height='240px'
                                            style={{ margin: 'auto' }}
                                        />
                                    </div>
                                </div>
                            </Modal.Content>
                            <Modal.Action passive onClick={() => setVisible(false)}><strong>Закрыть</strong></Modal.Action>
                          </Modal>
                        </div>
                    }
                    */}

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">

                    <Nav style={{ marginLeft: 'auto' }}>

                            {userInfo ? (
                                <NavDropdown title={userInfo.name} id='username'>

{/*
<LinkContainer to='/profile'>
    <NavDropdown.Item><i className="far fa-id-card" style={{ color: '#2a9df4' }}></i> Мой профиль</NavDropdown.Item>
</LinkContainer>
*/}
    <NavDropdown.Item>
        <Link to={`/db/`} style={{ textDecoration: 'none', color: 'black' }}>
            <i className="fas fa-" style={{ color: '#2a9df4' }}></i> Центр
        </Link>
    </NavDropdown.Item>
    <NavDropdown.Item>
        <Link to={`/d/`} style={{ textDecoration: 'none', color: 'black' }}>
            <i className="fas fa-" style={{ color: '#2a9df4' }}></i> Классы
        </Link>
    </NavDropdown.Item>
    <NavDropdown.Item>
        <Link to={`/profile/`} style={{ textDecoration: 'none', color: 'black' }}>
            <i className="fas fa-" style={{ color: '#2a9df4' }}></i> Профайл
        </Link>
    </NavDropdown.Item>
    <NavDropdown.Item>
        <Link to={`/map/`} style={{ textDecoration: 'none', color: 'black' }}>
            <i className="fas fa-" style={{ color: '#2a9df4' }}></i> Программа
        </Link>
    </NavDropdown.Item>
    <NavDropdown.Item>
        <Link to={`/mp/`} style={{ textDecoration: 'none', color: 'black' }}>
            <i className="fas fa-" style={{ color: '#2a9df4' }}></i> Усвоение тем
        </Link>
    </NavDropdown.Item>
    <NavDropdown.Item>
        <Link to={`/r/`} style={{ textDecoration: 'none', color: 'black' }}>
            <i className="fas fa-" style={{ color: '#2a9df4' }}></i> Пройденные тесты
        </Link>
    </NavDropdown.Item>

    <NavDropdown.Item>
        <Link to={`/i/`} style={{ textDecoration: 'none', color: 'black' }}>
            <i className="fas fa-" style={{ color: '#2a9df4' }}></i> Как пользоваться и с чего начать?
        </Link>
    </NavDropdown.Item>
<hr/>
    <NavDropdown.Item onClick={logoutHandler}><i className="fas fa-sign-out-alt" style={{ color: '#2a9df4' }}></i> Выйти</NavDropdown.Item>


                                </NavDropdown>
                            ) : (
                                <Nav>
                                    <LinkContainer to='/login'>
                                        <Nav.Link><i className="fas fa-sign-in-alt" style={{ color: '#a1caf1' }}></i> Войти</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/register'>
                                        <Nav.Link><i className="far fa-user" style={{ color: '#a1caf1' }}></i> Зарегистрироваться</Nav.Link>
                                    </LinkContainer>
                                </Nav>
                                )}

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
        </div>
    )
}

export default Header
