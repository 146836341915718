import {
    GRADES_REQUEST, GRADES_SUCCESS, GRADES_FAIL, GRADES_UPDATE, 
    TOPICS_REQUEST, TOPICS_SUCCESS, TOPICS_FAIL, TOPICS_UPDATE, 
    TOPICS_GRADE_REQUEST, TOPICS_GRADE_SUCCESS, TOPICS_GRADE_FAIL,
    TOPIC_REQUEST, TOPIC_SUCCESS, TOPIC_FAIL, TOPIC_UPDATE, 
    LOG_MATH_ADD_REQUEST, LOG_MATH_ADD_SUCCESS, LOG_MATH_ADD_FAIL, LOG_MATH_ADD_RESET,
    SPRINT_ADD_REQUEST, SPRINT_ADD_SUCCESS, SPRINT_ADD_FAIL, SPRINT_ADD_RESET,
    LAST_SPRINT_REQUEST, LAST_SPRINT_SUCCESS, LAST_SPRINT_FAIL, LAST_SPRINT_UPDATE,
    RANDOM_QUESTION_REQUEST, RANDOM_QUESTION_SUCCESS, RANDOM_QUESTION_FAIL, RANDOM_QUESTION_UPDATE, 
    ANSWER_ADD_REQUEST, ANSWER_ADD_SUCCESS, ANSWER_ADD_FAIL, ANSWER_ADD_RESET,
    LAST_ANSWER_REQUEST, LAST_ANSWER_SUCCESS, LAST_ANSWER_FAIL, LAST_ANSWER_UPDATE,
    ANSWER_NF_ADD_REQUEST, ANSWER_NF_ADD_SUCCESS, ANSWER_NF_ADD_FAIL, ANSWER_NF_ADD_RESET,
    SPRINTS_USER_REQUEST, SPRINTS_USER_SUCCESS, SPRINTS_USER_FAIL, SPRINTS_USER_UPDATE, 
    SPRINTS_TOPIC_USER_REQUEST, SPRINTS_TOPIC_USER_SUCCESS, SPRINTS_TOPIC_USER_FAIL,
    SPRINT_REQUEST, SPRINT_SUCCESS, SPRINT_FAIL, SPRINT_UPDATE,
    VIDEO_REQUEST, VIDEO_SUCCESS, VIDEO_FAIL, VIDEO_UPDATE, 
    GRADE_REQUEST, GRADE_SUCCESS, GRADE_FAIL, GRADE_UPDATE, 
    SCREEN_GRADES_SPRINTS_REQUEST, SCREEN_GRADES_SPRINTS_SUCCESS, SCREEN_GRADES_SPRINTS_FAIL, 
    SCREEN_TOPICS_SPRINTS_REQUEST, SCREEN_TOPICS_SPRINTS_SUCCESS, SCREEN_TOPICS_SPRINTS_FAIL,
    SCREEN_ALL_TOPICS_SPRINTS_REQUEST, SCREEN_ALL_TOPICS_SPRINTS_SUCCESS, SCREEN_ALL_TOPICS_SPRINTS_FAIL, 
    PI1_REQUEST, PI1_SUCCESS, PI1_FAIL,
    STAT_REQUEST, STAT_SUCCESS, STAT_FAIL, 
    STAT_EASY_REQUEST, STAT_EASY_SUCCESS, STAT_EASY_FAIL, 
    // TOPICS_MY_PROGRESS_REQUEST, TOPICS_MY_PROGRESS_SUCCESS, TOPICS_MY_PROGRESS_FAIL, 

    PW_CHANGE_REQUEST_REQUEST, PW_CHANGE_REQUEST_SUCCESS, PW_CHANGE_REQUEST_FAIL, PW_CHANGE_REQUEST_RESET,
    NEW_PW_REQUEST, NEW_PW_SUCCESS, NEW_PW_FAIL,

    PROCESS_GRADE_REQUEST, PROCESS_GRADE_SUCCESS, PROCESS_GRADE_FAIL, PROCESS_GRADE_RESET,

    USER_ACTIVATE_REQUEST, USER_ACTIVATE_SUCCESS, USER_ACTIVATE_FAIL, USER_ACTIVATE_RESET,

} from '../constants/mathConstants'

export const grades_r = (state = { grades_grades: [] }, action) => {
    switch (action.type) {
        case GRADES_REQUEST:
            return { loading_grades: true, grades_grades: [] }

        case GRADES_SUCCESS:
            return { loading_grades: false, grades_grades: action.payload.grades, 
                grades_page: action.payload.page,
                grades_pages: action.payload.pages
             }

        case GRADES_FAIL:
            return { loading_grades: false, error_grades: action.payload }

        default:
            return state
    }
}

export const topics_r = (state = { topics_topics: [], grade: [] }, action) => {
    switch (action.type) {
        case TOPICS_REQUEST:
            return { loading_topics: true, topics_topics: [], grade: [] }

        case TOPICS_SUCCESS:
            return { loading_topics: false, topics_topics: action.payload.topics, grade: action.payload.grade,  
                topics_page: action.payload.page,
                topics_pages: action.payload.pages
             }

        case TOPICS_FAIL:
            return { loading_topics: false, error_topics: action.payload }

        default:
            return state
    }
}

export const topicsGrade_r = (state = { topics_grade: [], grade: [] }, action) => {
    switch (action.type) {
        case TOPICS_GRADE_REQUEST:
            return { loading_topics_grade: true, topics_grade: [], grade: [] }

        case TOPICS_GRADE_SUCCESS:
            return { loading_topics_grade: false, topics_grade: action.payload.topics, grade: action.payload.grade,  
                topics_grade_page: action.payload.page,
                topics_grade_pages: action.payload.pages
             }

        case TOPICS_GRADE_FAIL:
            return { loading_topics_grade: false, error_topics_grade: action.payload }

        default:
            return state
    }
}

export const topic_r = (state = { topic_topic: [], grade: [], topic_notes: [], prerequisites: [] }, action) => {
    switch (action.type) {
        case TOPIC_REQUEST:
            return { loading_topic: true, topic_topic: [], grade: [], topic_notes: [], prerequisites: [] }

        case TOPIC_SUCCESS:
            return { loading_topic: false, 
                topic_topic: action.payload.topic, 
                topic_notes: action.payload.notes,
                prerequisites: action.payload.prerequisites, 
                topic_sprints_number: action.payload.topic_sprints_number,
                topic_sum_time: action.payload.topic_sum_time,
                topic_score: action.payload.topic_score,
                topic_correct_percent: action.payload.topic_correct_percent,
                topic_percentile: action.payload.topic_percentile,
                topic_count_50: action.payload.topic_count_50,
                topic_count_45: action.payload.topic_count_45,
                topic_count_40: action.payload.topic_count_40,
                topic_count_35: action.payload.topic_count_35,
                topic_count_25: action.payload.topic_count_25,
                topic_count_15: action.payload.topic_count_15,
                topic_count_0: action.payload.topic_count_0,
                // topic_page: action.payload.page,
                // topic_pages: action.payload.pages
             }

        case TOPIC_FAIL:
            return { loading_topic: false, error_topic: action.payload }

        default:
            return state
    }
}

export const logMathAdd_r = (state = {}, action) => {
    switch (action.type) {
        case LOG_MATH_ADD_REQUEST:
            return { loading_log_math_add: true }

        case LOG_MATH_ADD_SUCCESS:
            return { loading_log_math_add: false, success_log_math_add: true, }

        case LOG_MATH_ADD_FAIL:
            return { loading_log_math_add: false, error_log_math_add: action.payload }

        case LOG_MATH_ADD_RESET:
            return {}

        default:
            return state
    }
}


export const sprintAdd_r = (state = { new_sprint: {} }, action) => {
    switch (action.type) {
        case SPRINT_ADD_REQUEST:
            return { loading_sprint_add: true }

        case SPRINT_ADD_SUCCESS:
            return { loading_sprint_add: false, success_sprint_add: true, new_sprint: action.payload }

        case SPRINT_ADD_FAIL:
            return { loading_sprint_add: false, error_sprint_add: action.payload }

        case SPRINT_ADD_RESET:
            return {}

        default:
            return state
    }
}

export const lastSprint_r = (state = { last_sprint: { topic: { grade: []}, answers: [], createdByUser: [], question: { options: [] }, sprint: {topic: { grade: []}} } }, action) => {
    switch (action.type) {
        case LAST_SPRINT_REQUEST:
            return { loading_last_sprint: true,  last_sprint: { topic: { grade: []}, answers: [], createdByUser: [], question: { options: [] }, sprint: {topic: { grade: []}} } }

        case LAST_SPRINT_SUCCESS:
            return { loading_last_sprint: false,  last_sprint: action.payload, 
                // last_sprint_page: action.payload.page,
                // last_sprint_pages: action.payload.pages
             }

        case LAST_SPRINT_FAIL:
            return { loading_last_sprint: false, error_last_sprint: action.payload }

        default:
            return state
    }
}



export const randomQuestion_r = (state = { random_question: [], options: [] }, action) => {
    switch (action.type) {
        case RANDOM_QUESTION_REQUEST:
            return { loading_random_question: true, random_question: [], options: [] }

        case RANDOM_QUESTION_SUCCESS:
            return { loading_random_question: false,  random_question: action.payload.question, options: action.payload.options,  
                // RANDOM_QUESTION_page: action.payload.page,
                // RANDOM_QUESTION_pages: action.payload.pages
             }

        case RANDOM_QUESTION_FAIL:
            return { loading_random_question: false, error_random_question: action.payload }

        default:
            return state
    }
}


// should be deleted
export const answerAdd_r = (state = {}, action) => {
    switch (action.type) {
        case ANSWER_ADD_REQUEST:
            return { success_answer_add: false, loading_answer_add: true }

        case ANSWER_ADD_SUCCESS:
            return { loading_answer_add: false, success_answer_add: true, }

        case ANSWER_ADD_FAIL:
            return { loading_answer_add: false, error_answer_add: action.payload }

        case ANSWER_ADD_RESET:
            return {}

        default:
            return state
    }
}

export const lastAnswer_r = (state = { last_answer: { question: { options: [] } } }, action) => {
    switch (action.type) {
        case LAST_ANSWER_REQUEST:
            return { loading_last_answer: true,  last_answer: { question: { options: [] } } }

        case LAST_ANSWER_SUCCESS:
            return { loading_last_answer: false,  last_answer: action.payload, 
                // last_answer_page: action.payload.page,
                // last_answer_pages: action.payload.pages
             }

        case LAST_ANSWER_FAIL:
            return { loading_last_answer: false, error_last_answer: action.payload }

        default:
            return state
    }
}


export const answerNotFirstAdd_r = (state = {}, action) => {
    switch (action.type) {
        case ANSWER_NF_ADD_REQUEST:
            return { loading_answer_add: true }

        case ANSWER_NF_ADD_SUCCESS:
            return { loading_answer_add: false, success_answer_add: true, }

        case ANSWER_NF_ADD_FAIL:
            return { loading_answer_add: false, error_answer_add: action.payload }

        case ANSWER_NF_ADD_RESET:
            return {}

        default:
            return state
    }
}


export const sprintsUser_r = (state = { sprints_user: [] }, action) => {
    switch (action.type) {
        case SPRINTS_USER_REQUEST:
            return { loading_sprints_user: true, sprints_user: [] }

        case SPRINTS_USER_SUCCESS:
            return {
                loading_sprints_user: false,
                sprints_user: action.payload.sprints,
                sprints_user_page: action.payload.page,
                sprints_user_pages: action.payload.pages
            }

        case SPRINTS_USER_FAIL:
            return { loading_sprints_user: false, error_sprints_user: action.payload }

        default:
            return state
    }
}


export const sprintsTopicUser_r = (state = { sprints_topic_user: [], scores: [], avg_scores: [], dates: [], }, action) => {
    switch (action.type) {
        case SPRINTS_TOPIC_USER_REQUEST:
            return { loading_sprints_topic_user: true, 
                sprints_topic_user: [],
                scores: [], 
                dates: [], 
                avg_scores: [], 
            }

        case SPRINTS_TOPIC_USER_SUCCESS:
            return {
                loading_sprints_topic_user: false,
                sprints_topic_user: action.payload.sprints_topic,
                sprints_topic_user_page: action.payload.page,
                sprints_topic_user_pages: action.payload.pages, 
                scores: action.payload.scores, 
                dates: action.payload.dates, 
                avg_scores: action.payload.avg_scores,
            }

        case SPRINTS_TOPIC_USER_FAIL:
            return { loading_sprints_topic_user: false, error_sprints_topic_user: action.payload }

        default:
            return state
    }
}


export const sprint_r = (state = { sprint_sprint: { topic: { grade: []}, answers: [], createdByUser: [], question: { options: [] }, sprint: {topic: { grade: []}} } }, action) => {
    switch (action.type) {
        case SPRINT_REQUEST:
            return { loading_sprint_sprint: true,  sprint_sprint: { topic: { grade: []}, answers: [], createdByUser: [], question: { options: [] }, sprint: {topic: { grade: []}} } }

        case SPRINT_SUCCESS:
            return { loading_sprint_sprint: false,  sprint_sprint: action.payload, 
             }

        case SPRINT_FAIL:
            return { loading_sprint_sprint: false, error_sprint_sprint: action.payload }

        default:
            return state
    }
}

export const video_r = (state = { video_video: [] }, action) => {
    switch (action.type) {
        case VIDEO_REQUEST:
            return { loading_video: true, video_video: [] }

        case VIDEO_SUCCESS:
            return {
                loading_video: false,
                video_video: action.payload.videos,
            }

        case VIDEO_FAIL:
            return { loading_video: false, error_video: action.payload }

        default:
            return state
    }
}

export const grade_r = (state = { grade_grade: []}, action) => {
    switch (action.type) {
        case GRADE_REQUEST:
            return { loading_grade: true, grade_grade: []}

        case GRADE_SUCCESS:
            return { loading_grade: false, grade_grade: action.payload, 
                // grade_page: action.payload.page,
                // grade_pages: action.payload.pages
             }

        case GRADE_FAIL:
            return { loading_grade: false, error_grade: action.payload }

        default:
            return state
    }
}


export const screen_grades_sprints_r = (state = { screen_grades_sprints: [] }, action) => {
    switch (action.type) {
        case SCREEN_GRADES_SPRINTS_REQUEST:
            return { loading_screen_grades_sprints: true, screen_grades_sprints: [] }

        case SCREEN_GRADES_SPRINTS_SUCCESS:
            return {
                loading_screen_grades_sprints: false,
                screen_grades_sprints: action.payload,
                // screen_grades_sprints_page: action.payload.page,
                // screen_grades_sprints_pages: action.payload.pages
            }

        case SCREEN_GRADES_SPRINTS_FAIL:
            return { loading_screen_grades_sprints: false, error_screen_grades_sprints: action.payload }

        default:
            return state
    }
}


export const screen_topics_sprints_r = (state = { screen_topics_sprints: [], grade: [] }, action) => {
    switch (action.type) {
        case SCREEN_TOPICS_SPRINTS_REQUEST:
            return { loading_screen_topics_sprints: true, screen_topics_sprints: [], grade: [] }

        case SCREEN_TOPICS_SPRINTS_SUCCESS:
            return {
                loading_screen_topics_sprints: false,
                screen_topics_sprints: action.payload.user_topic,
                grade: action.payload.grade,
                // screen_topics_sprints_page: action.payload.page,
                // screen_topics_sprints_pages: action.payload.pages
            }

        case SCREEN_TOPICS_SPRINTS_FAIL:
            return { loading_screen_topics_sprints: false, error_screen_topics_sprints: action.payload }

        default:
            return state
    }
}

export const screen_all_topics_sprints_r = (state = { screen_all_topics_sprints: [], topics_list: [] }, action) => {
    switch (action.type) {
        case SCREEN_ALL_TOPICS_SPRINTS_REQUEST:
            return { loading_screen_all_topics_sprints: true, screen_topics_sprints: [], topics_list: [] }

        case SCREEN_ALL_TOPICS_SPRINTS_SUCCESS:
            return {
                loading_screen_all_topics_sprints: false,
                screen_all_topics_sprints: action.payload.user_all_topics,
                topics_list: action.payload.topics_list,
                // grade: action.payload.grade,
                // screen_all_topics_sprints_page: action.payload.page,
                // screen_all_topics_sprints_pages: action.payload.pages
            }

        case SCREEN_ALL_TOPICS_SPRINTS_FAIL:
            return { loading_screen_all_topics_sprints: false, error_screen_all_topics_sprints: action.payload }

        default:
            return state
    }
}


export const pi1_r = (state = { pi1_pi1: [] }, action) => {
    switch (action.type) {
        case PI1_REQUEST:
            return { loading_pi1: true, pi1_pi1: [] }

        case PI1_SUCCESS:
            return { loading_pi1: false, pi1_pi1: action.payload.pi1, 
                // pi1_page: action.payload.page,
                // pi1_pages: action.payload.pages
             }

        case PI1_FAIL:
            return { loading_pi1: false, error_pi1: action.payload }

        default:
            return state
    }
}

export const pw_change_request_r = (state = {}, action) => {
    switch (action.type) {
        case PW_CHANGE_REQUEST_REQUEST:
            return { loading_pw_change_request: true }

        case PW_CHANGE_REQUEST_SUCCESS:
            return { loading_pw_change_request: false, success_pw_change_request: true, }

        case PW_CHANGE_REQUEST_FAIL:
            return { loading_pw_change_request: false, error_pw_change_request: action.payload }

        case PW_CHANGE_REQUEST_RESET:
            return {}

        default:
            return state
    }
}

export const new_pw_r = (state = { text: {} }, action) => {
    switch (action.type) {
        case NEW_PW_REQUEST:
            return { loading: true }

        case NEW_PW_SUCCESS:
            return { loading: false, text: action.payload.text }

        case NEW_PW_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}



export const stat_r = (state = { }, action) => {
    switch (action.type) {
        case STAT_REQUEST:
            return { loading_stat: true,  }

        case STAT_SUCCESS:
            return {
                loading_stat: false,

                w_sprints_number: action.payload.w_sprints_number,
                w_topics_number: action.payload.w_topics_number,
                w_grades_number: action.payload.w_grades_number,
                w_sum_time: action.payload.w_sum_time,
                w_number_of_5: action.payload.w_number_of_5,
                w_score: action.payload.w_score,
                w_correct_percent: action.payload.w_correct_percent,
                w_percentile: action.payload.w_percentile,

                w_count_50: action.payload.w_count_50,
                w_count_45: action.payload.w_count_45,
                w_count_40: action.payload.w_count_40,
                w_count_35: action.payload.w_count_35,
                w_count_25: action.payload.w_count_25,
                w_count_15: action.payload.w_count_15,
                w_count_0: action.payload.w_count_0,

                today_sprints_number: action.payload.today_sprints_number,
                today_topics_number: action.payload.today_topics_number,
                today_grades_number: action.payload.today_grades_number,
                today_sum_time: action.payload.today_sum_time,
                today_number_of_5: action.payload.today_number_of_5,
                today_score: action.payload.today_score,
                today_correct_percent: action.payload.today_correct_percent,
                today_percentile: action.payload.today_percentile,

                today_count_50: action.payload.today_count_50,
                today_count_45: action.payload.today_count_45,
                today_count_40: action.payload.today_count_40,
                today_count_35: action.payload.today_count_35,
                today_count_25: action.payload.today_count_25,
                today_count_15: action.payload.today_count_15,
                today_count_0: action.payload.today_count_0,

                week_sprints_number: action.payload.week_sprints_number,
                week_topics_number: action.payload.week_topics_number,
                week_grades_number: action.payload.week_grades_number,
                week_sum_time: action.payload.week_sum_time,
                week_number_of_5: action.payload.week_number_of_5,
                week_score: action.payload.week_score,
                week_correct_percent: action.payload.week_correct_percent,
                week_percentile: action.payload.week_percentile,

                week_count_50: action.payload.week_count_50,
                week_count_45: action.payload.week_count_45,
                week_count_40: action.payload.week_count_40,
                week_count_35: action.payload.week_count_35,
                week_count_25: action.payload.week_count_25,
                week_count_15: action.payload.week_count_15,
                week_count_0: action.payload.week_count_0,

            }

        case STAT_FAIL:
            return { loading_stat: false, error_stat: action.payload }

        default:
            return state
    }
}


export const process_grade_r = (state = {}, action) => {
    switch (action.type) {
        case PROCESS_GRADE_REQUEST:
            return { loading_answer_add: true }

        case PROCESS_GRADE_SUCCESS:
            return { loading_answer_add: false, success_answer_add: true, }

        case PROCESS_GRADE_FAIL:
            return { loading_answer_add: false, error_answer_add: action.payload }

        case PROCESS_GRADE_RESET:
            return {}

        default:
            return state
    }
}



export const stat_easy_r = (state = { }, action) => {
    switch (action.type) {
        case STAT_EASY_REQUEST:
            return { loading_stat: true,  }

        case STAT_EASY_SUCCESS:
            return {
                loading_stat: false,
                stat_easy_sprints: action.payload.stat_easy_sprints,
                stat_easy_fives: action.payload.stat_easy_fives,
                stat_easy_topics: action.payload.stat_easy_topics,
                stat_easy_grades: action.payload.stat_easy_grades,
                stat_easy_mastery: action.payload.stat_easy_mastery,
                stat_easy_sprints_today: action.payload.stat_easy_sprints_today,
            }

        case STAT_EASY_FAIL:
            return { loading_stat: false, error_stat: action.payload }

        default:
            return state
    }
}


export const user_activate_r = (state = {}, action) => {
    switch (action.type) {
        case USER_ACTIVATE_REQUEST:
            return { loading_user_activate: true }

        case USER_ACTIVATE_SUCCESS:
            return { loading_user_activate: false, success_user_activate: true, }

        case USER_ACTIVATE_FAIL:
            return { loading_user_activate: false, error_user_activate: action.payload }

        case USER_ACTIVATE_RESET:
            return {}

        default:
            return state
    }
}

