import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"
import mymap from '../../1/mymap.jpg'



function N8_Instructions_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    const goBack = () => { history.goBack() }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

    <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
        <i className="fas fa-chevron-left"/> Назад
    </Button>

    <div style={{ marginTop: 20, marginBottom: 0, marginLeft: 15, marginRight: 15, fontSize: 24 }}>


    <h3>	Как пользоваться и с чего начать?	</h3>
    <div style={{ fontSize: 18 }}>
    <div>	 	</div>
    <div>	 	</div>
    <br/>


    <strong>	1. Проверка знаний тем прошлых классов	</strong>
    <div>	 	</div>
    <div style={{ marginTop:15 }}>	В первую очередь важно определить уровень знаний тем прошлых классов. 	</div>
    <div style={{ marginTop:15 }}>	Для этого, пожалуйста, пройдите в предыдущие классы и пройдите тесты в каждой теме.	</div>
    <div style={{ marginTop:15 }}>	Проверка знаний тем прошлых классов позволит выявить пробелы.	</div>
    <div style={{ marginTop:15 }}>	Результаты тестов также автоматически сформируют индивидуальную карту знаний школьника (которую можно найти в разделе «Моя карта» на сайте). Информация в индивидуальной карте знаний школьника позволяет наглядно и удобно визуализировать знания школьника в математике в разрезе классов и тем. 	</div>
    <div style={{ marginTop:15 }}>	Для учителей и родителей такая информированность о карте знаний школьника полезна и может учитываться ими при дальнейшем обучении.	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	Рис. Пример карты знаний пользователя: 	</div>
    <Col xxs={12} xs={12} sm={12} md={6} lg={6} xl={6}>
        <div class="shadow p-2 mb-5 bg-white rounded">
            <Card class="shadow p-2 mb-5 bg-white rounded" style={{ border: 'none' }}>
                <Card.Img src={mymap} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
            </Card>
        </div>
    </Col>

    <div style={{ marginTop:15 }}>	 	</div>

    <br/>
    <strong>	2. Устранение пробелов в знании тем прошлых классов	</strong>
    <div style={{ marginTop:15 }}>	На странице темы имеется несколько полезных вкладок.	</div>
    <div style={{ marginTop:15 }}>	Для устранения пробелов, во вкладке Видео вы можете ознакомиться с качественными обучающими видео материалами, отобранными из открытых источников. В видео материалах объясняются темы и приводятся примеры решения задач.	</div>
    <div style={{ marginTop:15 }}>	Вкладка шпаргалка содержит формулы, которые необходимо знать и использовать. Также вкладка шпаргалка может давать общую описательную информацию по теме.	</div>
    <div style={{ marginTop:15 }}>	В любой момент времени вы можете проходить неограниченное количество тестов для того, чтобы замерить уровень знаний и повысить навыки за счет практики. 	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	Материалы могут изучаться деньги многократно для полного понимания темы.	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	В подавляющем большинстве случаев вышеописанного достаточно, чтобы изучить тему из школьной программы математики.	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	В отдельных случаях, если школьник не получает желаемого результата необходимо обратиться к «взрослым» (учителям, родителям, репетиторам) за дополнительным разъяснением темы.	</div>
    <div style={{ marginTop:15 }}>	После получения разъяснений, школьник вновь сможет вернуться к решению тестов на практике и замеру результатов. 	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <br/>
    <strong>	3. Изучение новых тем	</strong>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	Важно, чтобы школьники доводили уровень навыков до мастерства. Мастерство предполагает наличие большой практики. 	</div>
    <div style={{ marginTop:15 }}>	Поэтому рекомендуется, чтобы школьник по каждой новой теме получал несколько раз оценку «Отлично». 	</div>
    <div style={{ marginTop:15 }}>	При наличии 10 оценок «Отлично» по теме – считается, что школьник имеет Мастерство по теме.	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <br/>
    <strong>	4. Новые блоки с тестами по темам	</strong>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	Там где необходимо, после прохождения первичных тестов школьниками, будут появляться дополнительные разделы с более сложными тестовыми задачами. 	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	 	</div>
    <br/>
    <strong>	5. Процентили	</strong>
    <div style={{ marginTop:15 }}>	 	</div>
    <div style={{ marginTop:15 }}>	В результатах тестов помимо оценки указывается значение процентиля по тесту.	</div>
    <div style={{ marginTop:15 }}>	Процентиль показывает процент тестов, по которым оценка оказалась хуже. 	</div>
    <div style={{ marginTop:15 }}>	Данный показатель позволяет оценить получил ли пользователь средний результат, хуже среднего, существенно лучше среднего и так далее.	</div>
    <div style={{ marginTop:15 }}>	При расчете процентиля учитывается как правильность ответов, так и скорость решения задач.	</div>
    
    </div>

</div>
</div>

<div style={{ textAlign: 'center', lineHeight: 1.2, fontSize: 36, padding: 0, marginTop: 40, marginBottom: 20, marginLeft: 30, marginRight: 20 }} >
</div>
<h3>Советы для родителей – как еще улучшить знания по математике?
</h3>

        <Row>

<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '', paddinRight: 0, borderColor: 'black' }}>

    <div style={{ fontSize: 18, padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 40 }} >
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        <strong>1. Давайте детям смотреть качественные материалы с объяснением тем</strong>
        </div>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        Мектеп онлайн – отличный канал на ютьюб с качественными объяснениями практически всех тем средних и старших классов (на каз. и рус. языках). Канал позволяет детям самостоятельно изучать темы. 
        </div>
    </div>
    </Card>
    </div>
</Col>

<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '', paddinRight: 0, borderColor: 'black' }}>

    <div style={{ fontSize: 18, padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 40 }} >
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        <strong>2. Давайте детям сложные задачи для самостоятельного решения</strong>
        </div>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        Если школьник не смог решить какую-либо сложную задачу, будьте готовы решить задачу самостоятельно (либо найти стороннее решение) и объяснить ребенку решение. Сложные задачи без решений могут быть найдены в большом количестве для нужного класса по запросу в поисковике «Образцы заданий для поступления в физико-математическую школу». Альтернативно вы можете найти видео в интернете с объяснениями решений сложных задач. Описанное позволит найти пробелы в решении сложных задач и научиться решать их. 
        </div>
    </div>
    </Card>
    </div>
</Col>

</Row>


</Container>
</GeistProvider>
</Container>
    )
}

export default N8_Instructions_Screen
