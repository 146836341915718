import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"



function N3_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    const goBack = () => { history.goBack() }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

    <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
        <i className="fas fa-chevron-left"/> Назад
    </Button>

    <div style={{ marginTop: 20, marginBottom: 0, fontSize: 24 }}>
        <strong  >Уровень мастерства </strong>
    </div>
    <div style={{ marginTop: 0, marginBottom: 10, fontSize: 16, color: 'grey' }}>
        <strong  >по теме</strong>
    </div>
    <div style={{ display: 'flex' }} className='p-3'>
        <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
            <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
        </div>
        <div>
            "Уровень мастерства" показывает количество полученных вами "пятёрок" в тестах темы. <br/>  
            Чем больше пятёрок вы имеете по теме, тем выше ваш уровень мастерства.<br/><br/>
            Наличие десяти "пятёрок" по теме означает, что пользователь усвоил материал и является "Мастером темы". <br/><br/>
            Для примера, значение показателя "3 / 10" означает, что пользователь получил в тесте оценку "5" три раза. <br />
            Показатель позволяет понять степень усвоения темы пользователем. 
        </div>
    </div>
</div>

</Container>
</GeistProvider>
</Container>
    )
}

export default N3_Screen
