import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
import Axios from 'axios'
// import logoImage from '../../alemplus_logo5.jpg'
// import logoImage2 from '../../alemplus_logo6.jpg'
// import flower from '../../flower.jpg'
import salman from '../../salmankhan6.jpg'
import OECD from '../../OECD.jpg'
import m1 from '../../m1.jpg'
import n11 from '../../n11.jpg'
import n12 from '../../n12.jpg'
import sprint from '../../sprint.jpg'
import results from '../../results.jpg'
import text from '../../text.jpg'
import video from '../../video.jpg'
import topic_results from '../../topic_results.jpg'
import topics from '../../topics.jpg'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer, Modal, useModal } from "@geist-ui/react"
import ReactPlayer from "react-player"
import {ToastContainer, toast, Zoom, Bounce, Flip, Slide} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import s01 from '../../1/s01.jpg'
import s08 from '../../1/s08.jpg'
import s11 from '../../1/s11.jpg'
import s12 from '../../1/s12.jpg'
import b426 from '../../1/426.jpg'
import b423 from '../../1/423.jpg'
import b5708 from '../../1/5708.jpg'
import b512 from '../../1/512.jpg'
import YouTube from 'react-youtube'
import xlsx_import from '../../1/import.xlsx'
toast.configure()


function MathMain_Screen({ history }) {

    const notify = () => {
        toast.info(
            "Приветствуем! 🙋",
          { 
            position: "bottom-right",
            autoClose: 2000, 
            delay: 0, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 1,
            theme: "colored",
            transition: Slide
            //  autoClose: false
          }
        )
        toast.info(
            main_page_toast1
            ,
          { 
            position: "bottom-right",
            autoClose: 6000, 
            delay: 0, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 2,
            theme: "colored",
            transition: Slide
            //  autoClose: false
          }
        )
    }

    const main_page_toast = ["Здесь вы узнаете: Как возможно улучшить математические навыки! 👌", "Прогресс в математике ✌ по цене 3-х стаканчиков кофе 🥤🥤🥤", "Найдите пробелы в знаниях прошлых тем 👌 и устраните их ✌"]
    const main_page_toast1 = main_page_toast[Math.floor(Math.random()*2.99)]
    // console.log(main_page_toast1)
    // console.log(Math.floor(Math.random()*2.99))
    

    // moment.updateLocale('ru', localization)

    let keyword = history.location.search

    const dispatch = useDispatch()

    const [value, setValue] = useState('1')
    const switchHandler1 = () => setValue('1')
    const switchHandler2 = () => setValue('2')
    const switchHandler3 = () => setValue('3')
    const changeHandler = val => setValue(val)

    const [value2, setValue2] = useState('1')
    const switchHandler1001 = () => setValue2('1')
    const switchHandler1002 = () => setValue2('2')
    const switchHandler1003 = () => setValue2('3')
    const changeHandler2 = val2 => setValue2(val2)

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    console.log(page)
    console.log(history)
    console.log(window.location.href)
    console.log(window.location.pathname)
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => { 
        if (false) {
            dispatch(notify) 
        }
    }, [])

    const { visible, setVisible, bindings } = useModal()
    // const { visible2, setVisible2, bindings2 } = useModal()
    
    const opts = {
        height: screen_height/2,
        width: screen_width/2,
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      }

      const opts2 = {
        height: 270,
        width: 480,
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>


        <Row>

{/* <Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block m-0 p-0"> */}

{/* R1 C1 */}
<Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12} className="m-0 p-0">
    <div style={{ padding: 0, marginTop: 40, marginBottom: 40, marginLeft: 10, marginRight: 10 }}>
<Card shadow style={{ borderRadius: 10, backgroundColor: '', border: 'none' }}>

<div style={{ textAlign: 'center', lineHeight: 1.2, fontSize: 36, color: 'black', fontWeight: '', padding: 0, marginTop: 30, marginBottom: 0, marginLeft: 30, marginRight: 0 }}>
<span>
    <span><strong>Matenatika Plus</strong><br/><p style={{ textDecoration: 'underline' }}></p>Бесплатная система для улучшения знаний школьников по математике
    </span>
</span>
</div>
    </Card>
    </div>
</Col>
</Row>

<Row>

<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '', paddinRight: 0, borderColor: 'black' }}>
    <div style={{ fontSize: 18, padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 30, marginRight: 30 }} >

    <div style={{ lineHeight: 1.2, fontSize: 24, color: 'black', fontWeight: 'bold', padding: 0, marginTop: 10, marginBottom: 0, marginLeft: 0, marginRight: 0 }}>
<span>
    <span>Чем удобна система для родителей?</span>
</span>
</div>

        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 20 }}>
            Система содержит задачи по математике, решая которые школьники улучшают свои навыки. При этом:
        </div>

<table style={{ marginBottom: 20, marginTop: 20, marginLeft: 20, marginRight: 20, backgroundColor: '#f5f5f5' }}>
    <tbody>
        <td>
            <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                <span style={{ color: 'red', fontWeight: 'bold' }}>Родителю</span> не нужно искать задачки по математике для детей в интернете. 
            </div>
        </td>
        <td>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                В <span style={{ color: '#2a9df4', fontWeight: 'bold' }}>системе</span> задачи уже имеются. Задачи поделены по классам и темам.  
            </div>
        </td>
    </tbody>
    <tbody>
        <td>
            <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                <span style={{ color: 'red', fontWeight: 'bold' }}>Родителям</span> не нужно проверять правильность решения задач детьми. 
            </div>
        </td>
        <td>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, marginLeft: 10, marginRight: 10 }}>
            Правильность решения задач определяет <span style={{ color: '#2a9df4', fontWeight: 'bold' }}>система</span>. 
            </div>
        </td>
    </tbody>
    <tbody>
        <td>
            <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                <span style={{ color: 'red', fontWeight: 'bold' }}>Родителям</span> не нужно оценивать объем проделанной ребенком работы.
            </div>
        </td>
        <td>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, marginLeft: 10, marginRight: 10 }}>
            В <span style={{ color: '#2a9df4', fontWeight: 'bold' }}>системе</span>  имеются отчеты позволяющие за 1 минуту понять всю необходимую информацию. 
            </div>
        </td>
    </tbody>

</table>
<div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 15, marginTop: 25, fontWeight: 'bold', fontSize: 24 }}>
            Итог:
        </div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-1' >
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="far fa-check-square" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Время родителей не тратится
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-1' >
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-line" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Дети самостоятельно решают задачи
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-1' >
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-bar" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Объем занятий и оценки отображаются в отчетах
    </div>
</div>



    </div>
    </Card>
    </div>
</Col>

{/* 1. Start */}
<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
<Card shadow style={{ borderRadius: 10, backgroundColor: '#ECDBC3', border: 'none' }}>

<div style={{ lineHeight: 1.2, fontSize: 24, color: 'black', fontWeight: 'bold', padding: 0, marginTop: 30, marginBottom: 0, marginLeft: 30, marginRight: 0 }}>
<span>
    <span>1. Причины неуспеваемости детей по математике</span>
</span>
</div>
<div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'black', marginLeft: 40, marginRight: 20, marginBottom: 10, marginTop: 20 }}>
Согласно опыту и исследованиям известного международного образовательного проекта "Khan Academy" основные причины неуспеваемости детей по математике заключаются в следующем:
</div>


<div><Row>

    <Col xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} >

    <div style={{ padding: 0, marginTop: 10, marginBottom: 10, marginLeft: 20, marginRight: 0 }} >

<div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'black', marginLeft: 20, marginRight: 20 }}>
<ul>
    <li>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10 }}>
        Темпы усвоения тем детьми являются разными. Соответственно, не все дети успевают усвоить материал в отведенные школой сроки. Для усвоения тем им требуется либо больше времени, либо дополнительные качественные объяснения. 
        </div>
    </li>
</ul>
</div>
</div>
</Col>

    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} >
        <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, paddingRight: 15 }}>
            <Card.Img src={b5708} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>
</Row>

<div style={{ padding: 0, marginTop: 0, marginBottom: 20, marginLeft: 20, marginRight: 0 }} >

<div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'black', marginLeft: 20, marginRight: 20 }}>

<ul>
    <li>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 40, marginTop: 0 }}>
        Пробелы в знании прошлых базовых тем не позволяют усваивать новые темы. Например, чтобы понять тему «Отрицательные степени», ученику необходимо иметь полное понимание прошлых тем «Степени» и «Отрицательные числа». 
        </div>
    </li>
</ul>
</div>
</div>

</div>

    </Card>
    </div>
</Col>
{/* 1. Finish */}

{/* 1. Start */}
<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
<Card shadow style={{ borderRadius: 10, backgroundColor: '#ECDBC3', border: 'none' }}>

<div style={{ lineHeight: 1.2, fontSize: 24, color: 'black', fontWeight: 'bold', padding: 0, marginTop: 30, marginBottom: 0, marginLeft: 30, marginRight: 0 }}>
<span>
    <span>1. Причины неуспеваемости детей по математике</span>
</span>
</div>
<div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'black', marginLeft: 40, marginRight: 20, marginBottom: 10, marginTop: 20 }}>
Согласно опыту и исследованиям известного международного образовательного проекта "Khan Academy" основные причины неуспеваемости детей по математике заключаются в следующем:
</div>


<div><Row>

    <Col xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} >

    <div style={{ padding: 0, marginTop: 10, marginBottom: 10, marginLeft: 20, marginRight: 0 }} >

<div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'black', marginLeft: 20, marginRight: 20 }}>
<ul>
    <li>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10 }}>
        Темпы усвоения тем детьми являются разными. Соответственно, не все дети успевают усвоить материал в отведенные школой сроки. 
        </div>
    </li>
</ul>
</div>
</div>
</Col>

    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} >
        <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, paddingRight: 15 }}>
            <Card.Img src={b5708} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>
</Row>

<div style={{ padding: 0, marginTop: 0, marginBottom: 20, marginLeft: 20, marginRight: 0 }} >

<div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'black', marginLeft: 20, marginRight: 20 }}>

<div style={{ lineHeight: 1.2, fontWeight: '', marginLeft: 32, marginBottom: 20, marginTop: 0 }}>
    Для усвоения тем им требуется либо больше времени, либо дополнительные качественные объяснения. 
</div>

<ul>
    <li>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 40, marginTop: 0 }}>
        Пробелы в знании прошлых базовых тем не позволяют усваивать новые темы. Например, чтобы понять тему «Отрицательные степени», ученику необходимо иметь полное понимание прошлых тем «Степени» и «Отрицательные числа». 
        </div>
    </li>
</ul>
</div>
</div>

</div>

    </Card>
    </div>
</Col>
{/* 1. Finish */}

{/* 2. Fin Analysis */}
<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '#1494C2', border: 'none' }}>

    <div style={{ lineHeight: 1.2, fontSize: 24, color: 'white', fontWeight: 'bold', padding: 0, marginTop: 30, marginBottom: 0, marginLeft: 30, marginRight: 0 }}>
        <span>
            <span>2. Как улучшить знания детей по математике?</span>
        </span>
    </div>
    <Row>
                <Col xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} >
                    <div style={{ padding: 0, marginTop: 20, marginBottom: 0, marginLeft: 20, marginRight: 20 }} >
                        <div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'white', marginLeft: 20}}>
                            <span>
                                <span>
                                    <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 0, marginTop: 20 }}>
                                    Отмеченное выше означает, что почти каждый школьник может улучшить знания по математике и полностью усвоить школьный материал при следующих условиях: 
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>
                </Col>
                <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} >
                    <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: -10, marginTop: 0, paddingRight: 10 }}>
                        <Card.Img src={b423} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
                    </div>
                </Col>
</Row>
<div style={{ padding: 0, marginTop: 20, marginBottom: 0, marginLeft: 20, marginRight: 0 }} >
    <div style={{ fontSize: 18, lineHeight: 1.2, fontWeight: '', color: 'white', marginLeft: 20, marginRight: 20 }}>
        <ol>
            <li>
                <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 5 }}>
                Со школьником занимаются в индивидуальном темпе и даются качественные объяснения в индивидуально необходимом объеме, при которых школьник полностью усваивает каждую тему. 
                </div>
            </li>                
            <li>
                <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 40, marginTop: 20 }}>
                Пробелы школьника в знании прошлых базовых тем (в том числе и из более ранних классов) определяются и полностью исключаются. Устранение пробелов обеспечивает успешное усвоение тем школьником в настоящем и будущем. 
                </div>
            </li>                
        </ol>
    </div>
</div>

<div style={{ marginLeft: 40, marginRight: 20 }}>

</div>
    </Card>
    </div>
</Col>

{/* 3. BP */}
<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '#A0E2FF', paddinRight: 0, border: 'none' }}>

    <div style={{ lineHeight: 1.2, fontSize: 24, color: 'black', fontWeight: 'bold', padding: 0, marginTop: 30, marginBottom: 0, marginLeft: 30, marginRight: 0 }}>
        <span><span>3. Система поможет</span></span>
    </div>
    <div style={{ lineHeight: 1.2, padding: 0, fontSize: 18, marginTop: 20, marginBottom: 0, marginLeft: 40, marginRight: 20, paddingRight: 20 }} >
    Система позволяет решить обе задачи, то есть:
    </div>

{/*     <Row className="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block "> */}
    <Row>
    <Row>
        <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} >
            <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 30, paddingLeft: 20 }}>
                <Card.Img src={b512} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
            </div>
        </Col>
        <Col xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} >
            <div style={{ padding: 0, fontSize: 18, marginTop: 20, marginBottom: 20, marginLeft: 0, marginRight: 20, paddingRight: 20 }} >
                <ul>
                    <li>
                        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
                        Найти пробелы в пройденных базовых темах, в которых у школьника отсутствует полное понимание.
                        </div>
                    </li>
                    <li>
                    <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 40, marginTop: 10 }}>
                    Улучшить знания по темам (в которых имеются пробелы, либо по новым темам) за счет решения тестов.
                    </div>
                </li>
                </ul>
            </div>
        </Col>
    </Row>
</Row>

<div style={{ marginLeft: 30, marginRight: 20 }}>

</div>
</Card>
    </div>
</Col>


{/* 4. PD */}
<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} >
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 0, marginRight: 0 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '#75CAC7', paddinRight: 0, border: 'none' }}>

    <div style={{ lineHeight: 1.2, fontSize: 24, color: 'black', fontWeight: 'bold', padding: 0, marginTop: 30, marginBottom: 0, marginLeft: 30, marginRight: 20 }}>
        <span><span>4. Контроль со стороны родителей</span></span> 
    </div>
    <Row>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} >
                <div style={{ marginTop: 10, marginBottom: 0, marginLeft: 30, marginRight: 0 }} >
                    <Image src={b426} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
        </Col>
        <Col xxs={9} xs={9} sm={9} md={9} lg={9} xl={9} >
        <div style={{ lineHeight: 1.2, padding: 0, fontSize: 18, marginTop: 30, marginBottom: 0, marginLeft: 10, marginRight: 20, paddingRight: 20 }} >
        Система позволяет родителям легко контролировать объем занятий детей в системе благодаря отчетам. 
    </div>
    <div style={{ lineHeight: 1.2, padding: 0, fontSize: 18, marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 20, paddingRight: 20 }} >
        Отчеты представлены на следующих страницах:  
    </div>

</Col>
</Row>

<div style={{ fontSize: 18, padding: 0, marginTop: 10, marginBottom: 20, marginLeft: 50, marginRight: 50 }} >
<div >
  <Tabs value={value} onChange={changeHandler}>      
  <Tabs.Item label="Профайл" value="1"  >
    <Row>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10 }}>
              На странице <strong>«Профайл»</strong> отображается: количество пройденных тестов сегодня, средние оценки по тестам, процент правильно решенных задач, потраченное время. Аналогичная информация отображается за последнюю неделю и за всё время действия аккаунта. 
                        </div>
        </Col>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <Image src={s01} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
        </Col>
    </Row>
  </Tabs.Item>
  <Tabs.Item label="Усвоение тем" value="2"  >
  <Row>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10 }}>
                        На странице <strong>«Усвоение тем»</strong> наглядно продемонстрирована картина знания тем учеником: по каким темам были пройдены тесты, количество пройденных тестов по каждой теме, средний балл по теме, по каким темам достигнуто мастерство, либо имеются пробелы. 
                        </div>
        </Col>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <Image src={s11} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
        </Col>
    </Row>
  </Tabs.Item>
  <Tabs.Item label="Прогресс" value="3"  >
  <Row>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
            Страница <strong>«Прогресс»</strong> 
        </Col>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <Image src={s08} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
        </Col>
    </Row>
  </Tabs.Item>
  <Tabs.Item label="Пройденные тесты" value="4"  >
  <Row>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10 }}>
                    На странице <strong>«Пройденные тесты»</strong> представлены все пройденные тесты, в т.ч. вопросы, ответы, баллы, время прохождения, даты. 
                    </div>
        </Col>
        <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <Image src={s12} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
        </Col>
    </Row>
  </Tabs.Item>
  </Tabs>
</div>
</div>

    </Card>
    </div>
</Col>

</Row>

{/* БЛОК ЦИФРЫ */}
<div style={{ borderRadius: 0, backgroundColor: '#e5e5e5', border: 'none', marginTop: 70, marginBottom: 70, marginLeft: 0, marginRight: 0, paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20 }}>
<Code style={{ color: 'black', textAlign: 'center', lineHeight: 1.2, fontSize: 24, padding: 0, marginTop: 10, marginBottom: 30, marginLeft: 30, marginRight: 20 }} >
<p><strong>В системе:</strong><br/><br/>свыше <strong>[100 тыс]</strong> тестовых задач по <strong>[157]</strong> школьным темам</p>
</Code>
</div>
{/* БЛОК ЦИФРЫ - конец */}


{/* БЛОК 2 */}
<div style={{ marginBottom: 50, marginTop: 10, marginLeft: 20, marginRight: 20 }}>

<div style={{ textAlign: 'center', lineHeight: 1.2, fontSize: 36, padding: 0, marginTop: 40, marginBottom: 30, marginLeft: 30, marginRight: 20 }} >
<strong>Что получают пользователи сайта?</strong>
        </div>

<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-graduation-cap" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Узнайте <strong>оценку знаний</strong> ребёнка по каждой пройденной теме (в т.ч. из прошлых классов)
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="far fa-check-square" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Найдите <strong>пробелы</strong> в пройденных темах и <strong>устраните</strong> их
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-calculator" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Повышайте навыки за счет <strong>практики</strong> и получайте автоматизированные <strong>оценки</strong>
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-line" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Повысьте <strong>эффективность</strong> изучения <strong>новых тем</strong> вашим ребёнком
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-book" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Ребёнок знакомится с качественными обучающими <strong>видео материалами</strong> (из открытых источников) и с основными выводами по темам из <strong>«шпаргалок»</strong>
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-trophy" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        «Что может быть измерено – будет сделано». <strong>Контролируйте процесс</strong> обучения ребёнка для <strong>достижения результатов</strong>
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Получайте <strong>статистику по занятиям ребёнка</strong> – например: оценка знаний по каждой теме, занимался ли ребёнок сегодня, какие оценки получены, насколько улучшаются результаты?
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-bar" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Узнавайте, <strong>как соотносятся результаты</strong> вашего ребёнка с результатами других пользователей
    </div>
</div>
<div style={{ display: 'flex', lineHeight: 1.2 }} className='p-2'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-check-square" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Дети занимаются самостоятельно!</strong> Родители не отвлекаются на обучение, тратя всего <strong>1 минуту в день на контроль</strong> прогресса при помощи аналитики
    </div>
</div>
    </div>
{/* БЛОК 2 - конец */}

{/* БЛОК 3 - Видео 

<div className='py-5 mb-5' style={{ backgroundColor: "#e6f1fd", marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20, textAlign: 'center' }}>
    <div className='p-0 mb-2' style={{ fontSize: 30, lineHeight : 1.1, color: 'black', textAlign: 'center' }}>
        <strong>Видео</strong>
    </div>
<p style={{ marginLeft: 0, marginTop: 20,  }}>
    <span>Узнайте за 5 минут: <br/><strong>Как улучшить математические навыки благодаря Matematika Plus</strong></span>
</p>
  <div className='p-0 m-0'>
      <div className='p-0 m-0'>
          <ReactPlayer 
              url='https://vimeo.com/600168490' 
              width='320px'
              height='240px'
              style={{ margin: 'auto' }}
          />
      </div>
  </div>
</div>
*/}


{/* БЛОК 8 */}

<div style={{ borderRadius: 0, backgroundColor: '#e5e5e5', border: 'none', marginTop: 40, marginBottom: 30, marginLeft: 0, marginRight: 0, paddingTop: 20, paddingBottom: 0, paddingLeft: 20, paddingRight: 20 }}>

<div style={{ textAlign: 'center', lineHeight: 1.2, fontSize: 36, padding: 0, marginTop: 10, marginBottom: 30, marginLeft: 30, marginRight: 20 }} >
<strong>Важность математики</strong>
        </div>

<div style={{ textAlign: '', lineHeight: 1.2, fontSize: 18, padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 30, marginRight: 20 }} >
<p>Математика поистине является царицей наук и лежит в основе всех интеллектуальных предметов и наук – физика, программирование, инженерия, финансы, прочее. Недаром говорят, что любая наука сложна на столько, на сколько в ней присутствует математика. 
</p>
        </div>

<div className='pt-5 pb-0 mt-5 mb-0' style={{ backgroundColor: '', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
<Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
<figure class="text-end">
<blockquote class="blockquote">
    <p class="mb-0" style={{ lineHeight : 1.1, fontSize: '16px', marginTop: '0px'}}>
        <i className="fas fa-quote-left" style={{ color: 'grey', marginRight: 10,  }}> </i>
            <strong>Различия в заработной плате</strong>, связанные с различиями в математических навыках, <strong>очевидны</strong>. <br/><br/>
            Согласно исследованиям - увеличение на одно стандартное отклонение в оценке<strong> математической грамотности</strong> приводит к увеличению <strong>заработной платы</strong> в большинстве стран на <strong>12–15%</strong>
        <i className="fas fa-quote-right" style={{ color: 'grey', marginLeft: 10,  }}> </i>
    </p>
</blockquote>
<figcaption class="blockquote-footer" style={{ lineHeight : 1.1, fontSize: '', marginTop: '30px'}}>
Отчет OECD, <cite title="Source Title">"Numeracy Practices And Numeracy Skills" (OECD Working Paper No. 177)</cite>
</figcaption>
</figure>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
    <div class="shadow p-2 mb-5 bg-white rounded">
        <Card  style={{ border: 'none' }}>
            <Card.Img src={OECD} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
        </Card>
    </div>
</Col>
</Row>
</div>
</div>
{/* БЛОК 8 - конец */}

{/*
Ожидаемый результат для страны
Улучшение знаний детей по математике повысит человеческий капитал страны, особенно в таких областях как точные науки, информационные технологии, инженерия. 
*/}

<div style={{ textAlign: 'center', lineHeight: 1.2, fontSize: 36, padding: 0, marginTop: 40, marginBottom: 20, marginLeft: 30, marginRight: 20 }} >
<strong>Советы для родителей – как еще улучшить знания по математике?
</strong>
        </div>

        <Row>

<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '', paddinRight: 0, borderColor: 'black' }}>

    <div style={{ fontSize: 18, padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 40 }} >
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        <strong>1. Давайте детям смотреть качественные материалы с объяснением тем</strong>
        </div>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        Мектеп онлайн – отличный канал на ютьюб с качественными объяснениями практически всех тем средних и старших классов (на каз. и рус. языках). Канал позволяет детям самостоятельно изучать темы. 
        </div>
    </div>
    </Card>
    </div>
</Col>

<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6} className="m-0 p-0">
<div style={{ padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
    <Card shadow style={{ borderRadius: 10, backgroundColor: '', paddinRight: 0, borderColor: 'black' }}>

    <div style={{ fontSize: 18, padding: 0, marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 40 }} >
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        <strong>2. Давайте детям сложные задачи для самостоятельного решения</strong>
        </div>
        <div style={{ lineHeight: 1.2, fontWeight: '', marginBottom: 20, marginTop: 20 }}>
        Если школьник не смог решить какую-либо сложную задачу, будьте готовы решить задачу самостоятельно (либо найти стороннее решение) и объяснить ребенку решение. Сложные задачи без решений могут быть найдены в большом количестве для нужного класса по запросу в поисковике «Образцы заданий для поступления в физико-математическую школу». Альтернативно вы можете найти видео в интернете с объяснениями решений сложных задач. Описанное позволит найти пробелы в решении сложных задач и научиться решать их. 
        </div>
    </div>
    </Card>
    </div>
</Col>

</Row>


{/* Salman Khan 8 */}
<div className='py-3 my-5' style={{ backgroundColor: '', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
    <Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
    <figure class="text-end">
    <blockquote class="blockquote">
      <p class="mb-0" style={{ lineHeight : 1.1, fontSize: '16px', marginTop: '0px'}}>
        <i className="fas fa-quote-left" style={{ color: 'grey', marginRight: 10,  }}> </i>
            <strong>99% детей</strong> способны <strong>полностью усвоить</strong> школьный материал <strong>по математике</strong> – <br/>за счёт устранения пробелов и обучения в своём темпе 
        <i className="fas fa-quote-right" style={{ color: 'grey', marginLeft: 10,  }}> </i>
      </p>
    </blockquote>
    <figcaption class="blockquote-footer" style={{ lineHeight : 1.1, fontSize: '', marginTop: '30px'}}>
      Salman Khan, <cite title="Source Title">Основатель Khan Academy</cite>
    </figcaption>
  </figure>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
    <div class="shadow p-2 mb-5 bg-white rounded">
        <Card class="shadow p-2 mb-5 bg-white rounded" style={{ border: 'none' }}>
            <Card.Img src={salman} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
        </Card>
    </div>
</Col>
    </Row>
    </div>
{/* БЛОК 8 - конец */}


{!userInfo && (
    <div className="p-0 mx-0 my-5">
    <Row>
    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} ></Col>
    <Col>
<div className="p-0 mb-3 mt-5">
<Link to='/login' className="p-0 m-0" style={{ textDecoration: 'none' }}>
<span style={{ lineHeight: 1.2, fontSize: 18, color: 'black', fontWeight: '', margin: 0, padding: 0, textDecoration: 'none' }}>                                            
    <i className="fas fa-sign-in-alt" style={{ color: '#2a9df4', marginRight: 5 }}></i> Войти
</span>
</Link>
</div>

<div className="p-0 mt-3 mb-5">
<Link to='/register' className="p-0 m-0" style={{ textDecoration: 'none' }}>
<span style={{ lineHeight: 1.2, fontSize: 18, color: 'black', fontWeight: '', margin: 0, padding: 0, textDecoration: 'none' }}>
<i className="fas fa-user" style={{ color: '#2a9df4', marginRight: 5 }}></i> Зарегистрироваться
</span>
</Link>
</div>

{/*
<div className="p-0 mt-3 mb-5">
<Link to='/contact' className="p-0 m-0" style={{ textDecoration: 'none' }}>
<span style={{ lineHeight: 1.2, fontSize: 18, color: 'black', fontWeight: '', margin: 0, padding: 0, textDecoration: 'none' }}>
<i className="fas fa-paper-plane" style={{ color: '#2a9df4', marginRight: 5 }}></i> Написать нам
</span>
</Link>
</div>
*/}

</Col>
</Row>
</div>
)}


{/* БЛОК ИМПОРТ */}
<div style={{ borderRadius: 0, backgroundColor: '#e5e5e5', border: 'none', marginTop: 70, marginBottom: 70, marginLeft: 0, marginRight: 0, paddingTop: 30, paddingBottom: 30, paddingLeft: 40, paddingRight: 40 }}>

<div style={{ color: 'black', textAlign: '', lineHeight: 1.2, fontSize: 24, padding: 0, marginTop: 10, marginBottom: 0, marginLeft: 15, marginRight: 20 }} >
<p><strong>Внесите свою лепту в улучшение математических навыков наших школьников -</strong></p>
</div>
<div style={{ color: 'black', textAlign: '', lineHeight: 1.2, fontSize: 24, padding: 0, marginTop: 0, marginBottom: 30, marginLeft: 40, marginRight: 20 }} >
<p>
<strong>Добавьте свои задачки в систему!</strong></p>
</div>


<div style={{ color: 'black', textAlign: '', lineHeight: 1.2, fontSize: 18, padding: 0, marginTop: 10, marginBottom: 10, marginLeft: 60, marginRight: 20 }} >
Для добавления задач в систему: <span> </span>
    <a href={xlsx_import} download="import.xlsx"> 
        <span style={{ textAlign: 'left', lineHeight: 1.2, margin: 0, padding: 0, color: '', marginBottom: 10, marginTop: 10, textDecoration: 'underline' }}>
            <i className='far fa-' style={{ marginRight: 0 }}></i><span style={{ marginLeft: 0 }}>Скачайте файл эксель по данной ссылке</span>
        </span>
    </a>
, внесите в него задачи и отправьте файл на емейл (указанный в файле)
</div>

<div style={{ color: 'black', textAlign: '', lineHeight: 1.2, fontSize: 18, padding: 0, marginTop: 25, marginBottom: 10, marginLeft: 60, marginRight: 20 }} >
Ваши вопросы будут добавлены в систему и будут принимать участие в тренировке школьников!
</div>

</div>
{/* БЛОК ИМПОРТ - конец */}


<Row className="m-0 p-0">
    <div className="m-0 p-0" style={{ backgroundColor: 'light' }}>
    <hr/>
        <div class="btn-group py-0 my-0" role="group" aria-label="Basic example" style={{ width:'100%' }} >

            <Link to={`/n/ca`} style={{ marginLeft: 5, textDecoration: '' }}>
                <i className="fas fa-question-" style={{ color: 'grey', marginLeft: 2 }} > </i> 
                <span  style={{ marginTop: 10, marginBottom: 10, fontSize: 12, lineHeight : 1, color: "black", width: '100%', fontWeight: 'bold' }}> Политика конфиденциальности</span>
            </Link>

            <Link to={`/n/offer`} style={{ marginLeft: 5, textDecoration: '' }}>
                <i className="fas fa-question-" style={{ color: 'grey', marginLeft: 2 }} > </i> 
                <span  style={{ marginTop: 10, marginBottom: 10, fontSize: 12, lineHeight : 1, color: "black", width: '100%', fontWeight: 'bold' }}> Условия пользования сайтом</span>
            </Link>

        </div>
    </div>
</Row>

</GeistProvider>        </Container>
    )
}

export default MathMain_Screen
