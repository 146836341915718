export const GRADES_REQUEST = 'GRADES_REQUEST'
export const GRADES_SUCCESS = 'GRADES_SUCCESS'
export const GRADES_FAIL = 'GRADES_FAIL'
export const GRADES_UPDATE = 'GRADES_UPDATE'

export const TOPICS_REQUEST = 'TOPICS_REQUEST'
export const TOPICS_SUCCESS = 'TOPICS_SUCCESS'
export const TOPICS_FAIL = 'TOPICS_FAIL'
export const TOPICS_UPDATE = 'TOPICS_UPDATE'

export const TOPICS_GRADE_REQUEST = 'TOPICS_GRADE_REQUEST'
export const TOPICS_GRADE_SUCCESS = 'TOPICS_GRADE_SUCCESS'
export const TOPICS_GRADE_FAIL = 'TOPICS_GRADE_FAIL'
export const TOPICS_GRADE_UPDATE = 'TOPICS_GRADE_UPDATE'

export const TOPIC_REQUEST = 'TOPIC_REQUEST'
export const TOPIC_SUCCESS = 'TOPIC_SUCCESS'
export const TOPIC_FAIL = 'TOPIC_FAIL'
export const TOPIC_UPDATE = 'TOPIC_UPDATE'

export const LOG_MATH_ADD_REQUEST = 'LOG_MATH_ADD_REQUEST'
export const LOG_MATH_ADD_SUCCESS = 'LOG_MATH_ADD_SUCCESS'
export const LOG_MATH_ADD_FAIL = 'LOG_MATH_ADD_FAIL'
export const LOG_MATH_ADD_RESET = 'LOG_MATH_ADD_RESET'

export const SPRINT_ADD_REQUEST = 'SPRINT_ADD_REQUEST'
export const SPRINT_ADD_SUCCESS = 'SPRINT_ADD_SUCCESS'
export const SPRINT_ADD_FAIL = 'SPRINT_ADD_FAIL'
export const SPRINT_ADD_RESET = 'SPRINT_ADD_RESET'

export const LAST_SPRINT_REQUEST = 'LAST_SPRINT_REQUEST'
export const LAST_SPRINT_SUCCESS = 'LAST_SPRINT_SUCCESS'
export const LAST_SPRINT_FAIL = 'LAST_SPRINT_FAIL'
export const LAST_SPRINT_UPDATE = 'LAST_SPRINT_UPDATE'

export const RANDOM_QUESTION_REQUEST = 'RANDOM_QUESTION_REQUEST'
export const RANDOM_QUESTION_SUCCESS = 'RANDOM_QUESTION_SUCCESS'
export const RANDOM_QUESTION_FAIL = 'RANDOM_QUESTION_FAIL'
export const RANDOM_QUESTION_UPDATE = 'RANDOM_QUESTION_UPDATE'

export const ANSWER_ADD_REQUEST = 'ANSWER_ADD_REQUEST'
export const ANSWER_ADD_SUCCESS = 'ANSWER_ADD_SUCCESS'
export const ANSWER_ADD_FAIL = 'ANSWER_ADD_FAIL'
export const ANSWER_ADD_RESET = 'ANSWER_ADD_RESET'

export const LAST_ANSWER_REQUEST = 'LAST_ANSWER_REQUEST'
export const LAST_ANSWER_SUCCESS = 'LAST_ANSWER_SUCCESS'
export const LAST_ANSWER_FAIL = 'LAST_ANSWER_FAIL'
export const LAST_ANSWER_UPDATE = 'LAST_ANSWER_UPDATE'

export const ANSWER_NF_ADD_REQUEST = 'ANSWER_NF_ADD_REQUEST'
export const ANSWER_NF_ADD_SUCCESS = 'ANSWER_NF_ADD_SUCCESS'
export const ANSWER_NF_ADD_FAIL = 'ANSWER_NF_ADD_FAIL'
export const ANSWER_NF_ADD_RESET = 'ANSWER_NF_ADD_RESET'

export const SPRINTS_USER_REQUEST = 'SPRINTS_USER_REQUEST'
export const SPRINTS_USER_SUCCESS = 'SPRINTS_USER_SUCCESS'
export const SPRINTS_USER_FAIL = 'SPRINTS_USER_FAIL'
export const SPRINTS_USER_UPDATE = 'SPRINTS_USER_UPDATE'

export const SPRINTS_TOPIC_USER_REQUEST = 'SPRINTS_TOPIC_USER_REQUEST'
export const SPRINTS_TOPIC_USER_SUCCESS = 'SPRINTS_TOPIC_USER_SUCCESS'
export const SPRINTS_TOPIC_USER_FAIL = 'SPRINTS_TOPIC_USER_FAIL'
export const SPRINTS_TOPIC_USER_UPDATE = 'SPRINTS_TOPIC_USER_UPDATE'

export const SPRINT_REQUEST = 'SPRINT_REQUEST'
export const SPRINT_SUCCESS = 'SPRINT_SUCCESS'
export const SPRINT_FAIL = 'SPRINT_FAIL'
export const SPRINT_UPDATE = 'SPRINT_UPDATE'

export const VIDEO_REQUEST = 'VIDEO_REQUEST'
export const VIDEO_SUCCESS = 'VIDEO_SUCCESS'
export const VIDEO_FAIL = 'VIDEO_FAIL'
export const VIDEO_UPDATE = 'VIDEO_UPDATE'

export const GRADE_REQUEST = 'GRADE_REQUEST'
export const GRADE_SUCCESS = 'GRADE_SUCCESS'
export const GRADE_FAIL = 'GRADE_FAIL'
export const GRADE_UPDATE = 'GRADE_UPDATE'

export const SCREEN_GRADES_SPRINTS_REQUEST = 'SCREEN_GRADES_SPRINTS_REQUEST'
export const SCREEN_GRADES_SPRINTS_SUCCESS = 'SCREEN_GRADES_SPRINTS_SUCCESS'
export const SCREEN_GRADES_SPRINTS_FAIL = 'SCREEN_GRADES_SPRINTS_FAIL'
export const SCREEN_GRADES_SPRINTS_UPDATE = 'SCREEN_GRADES_SPRINTS_UPDATE'

export const SCREEN_TOPICS_SPRINTS_REQUEST = 'SCREEN_TOPICS_SPRINTS_REQUEST'
export const SCREEN_TOPICS_SPRINTS_SUCCESS = 'SCREEN_TOPICS_SPRINTS_SUCCESS'
export const SCREEN_TOPICS_SPRINTS_FAIL = 'SCREEN_TOPICS_SPRINTS_FAIL'
export const SCREEN_TOPICS_SPRINTS_UPDATE = 'SCREEN_TOPICS_SPRINTS_UPDATE'

export const SCREEN_ALL_TOPICS_SPRINTS_REQUEST = 'SCREEN_ALL_TOPICS_SPRINTS_REQUEST'
export const SCREEN_ALL_TOPICS_SPRINTS_SUCCESS = 'SCREEN_ALL_TOPICS_SPRINTS_SUCCESS'
export const SCREEN_ALL_TOPICS_SPRINTS_FAIL = 'SCREEN_ALL_TOPICS_SPRINTS_FAIL'
export const SCREEN_ALL_TOPICS_SPRINTS_UPDATE = 'SCREEN_ALL_TOPICS_SPRINTS_UPDATE'

export const PI1_REQUEST = 'PI1_REQUEST'
export const PI1_SUCCESS = 'PI1_SUCCESS'
export const PI1_FAIL = 'PI1_FAIL'
export const PI1_UPDATE = 'PI1_UPDATE'

export const PW_CHANGE_REQUEST_REQUEST = 'PW_CHANGE_REQUEST_REQUEST'
export const PW_CHANGE_REQUEST_SUCCESS = 'PW_CHANGE_REQUEST_SUCCESS'
export const PW_CHANGE_REQUEST_FAIL = 'PW_CHANGE_REQUEST_FAIL'
export const PW_CHANGE_REQUEST_RESET = 'PW_CHANGE_REQUEST_RESET'

export const NEW_PW_REQUEST = 'NEW_PW_REQUEST'
export const NEW_PW_SUCCESS = 'NEW_PW_SUCCESS'
export const NEW_PW_FAIL = 'NEW_PW_FAIL'

export const STAT_REQUEST = 'STAT_REQUEST'
export const STAT_SUCCESS = 'STAT_SUCCESS'
export const STAT_FAIL = 'STAT_FAIL'
export const STAT_UPDATE = 'STAT_UPDATE'

export const PROCESS_GRADE_REQUEST = 'PROCESS_GRADE_REQUEST'
export const PROCESS_GRADE_SUCCESS = 'PROCESS_GRADE_SUCCESS'
export const PROCESS_GRADE_FAIL = 'PROCESS_GRADE_FAIL'
export const PROCESS_GRADE_RESET = 'PROCESS_GRADE_RESET'

export const STAT_EASY_REQUEST = 'STAT_EASY_REQUEST'
export const STAT_EASY_SUCCESS = 'STAT_EASY_SUCCESS'
export const STAT_EASY_FAIL = 'STAT_EASY_FAIL'
export const STAT_EASY_UPDATE = 'STAT_EASY_UPDATE'

// export const TOPICS_MY_PROGRESS_REQUEST = 'TOPICS_MY_PROGRESS_REQUEST'
// export const TOPICS_MY_PROGRESS_SUCCESS = 'TOPICS_MY_PROGRESS_SUCCESS'
// export const TOPICS_MY_PROGRESS_FAIL = 'TOPICS_MY_PROGRESS_FAIL'
// export const TOPICS_MY_PROGRESS_UPDATE = 'TOPICS_MY_PROGRESS_UPDATE'

export const USER_ACTIVATE_REQUEST = 'USER_ACTIVATE_REQUEST'
export const USER_ACTIVATE_SUCCESS = 'USER_ACTIVATE_SUCCESS'
export const USER_ACTIVATE_FAIL = 'USER_ACTIVATE_FAIL'
export const USER_ACTIVATE_RESET = 'USER_ACTIVATE_RESET'

