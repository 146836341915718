import { logout } from '../../actions/userActions'
import React, { useState, useEffect, Component, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Table, Image, ProgressBar } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Link, useParams, Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { logMathAdd_a, topics_a, sprintAdd_a, lastSprint_a, topic_a, answerAdd_a, sprintsTopicUser_a, video_a } from '../../actions/mathActions'
import { GeistProvider, CssBaseline } from "@geist-ui/react"
import { Card, Text, Tabs, Spinner, Progress, Modal, useModal } from "@geist-ui/react"
// import HorizontalScroll from 'react-scroll-horizontal'
// import ScrollBox from '../../components/ScrollBox'
import PropTypes from 'prop-types'
import useScrollBox from '../../components/useScrollBox'
import '../../components/scrollBox.css'
import { Line, defaults } from 'react-chartjs-2'
import Moment from 'react-moment'
// import moment from 'moment'
import localization from 'moment/locale/ru'
import moment from 'moment-timezone'
import { getUserDetails } from '../../actions/userActions'
// import {Chart, linear, CategoryScale} from 'chart.js' 
// Chart.register(CategoryScale)
import Chart from 'chart.js/auto'
import { SPRINT_ADD_RESET } from '../../constants/mathConstants'

function Topic_Screen({ match, history }) {

    moment.updateLocale('ru', localization)

    // moment.utcOffset(360)
    moment().tz('UTC').format()

    let keyword = history.location.search.slice(-1)

    // if(typeof keyword !== 'number') {
    //     keyword = '3'
    // }

    // console.log(keyword)

    const scrollWrapperRef = useRef()
    const { isDragging } = useScrollBox(scrollWrapperRef)
    // let { slug } = useParams()
    // console.log(match)
    // let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const topics_ = useSelector(state => state.topics_r)
    const { error_topics, loading_topics, topics_topics, topics_page, topics_pages } = topics_

    const topic_ = useSelector(state => state.topic_r)
    const { error_topic, loading_topic, topic_topic, topic_notes, topic_page, topic_pages, prerequisites, topic_sprints_number, topic_sum_time, topic_score, topic_correct_percent, topic_percentile, topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0 } = topic_

    const sprints_topic_user_ = useSelector(state => state.sprintsTopicUser_r)
    const { error_sprints_topic_user, loading_sprints_topic_user, sprints_topic_user, sprints_topic_user_page, sprints_topic_user_pages, scores, avg_scores, dates } = sprints_topic_user_

    // const lastSprint_ = useSelector(state => state.lastSprint_r)
    // const { error_last_sprint, loading_last_sprint, last_sprint } = lastSprint_

    const sprintAdd_ = useSelector(state => state.sprintAdd_r)
    const { success_sprint_add, loading_sprint_add, new_sprint } = sprintAdd_

    const video_ = useSelector(state => state.video_r)
    const { error_video, loading_video, video_video } = video_

    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails

    // let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        // alert(report_number)
        if (success_sprint_add) {
            dispatch({ type: SPRINT_ADD_RESET })
            history.push(`/s/${new_sprint._id}/`)
        } else {
            dispatch(topic_a(match.params.id))
            dispatch(sprintsTopicUser_a(match.params.id))
            dispatch(video_a(match.params.id))
            localStorage.setItem('backbtn', JSON.stringify("true"))
        }
        if (!user.profile) { 
            dispatch(getUserDetails('profile')) }
        if (false) {
            history.push('/login') }
    }, [success_sprint_add, match.params.id])
    // }, [success_sprint_add])

    // useEffect(() => {
    //     localStorage.setItem('topic', JSON.stringify(match.params.id))
        // if (topic_topic) {
        //     localStorage.setItem('grade', JSON.stringify(topic_topic.grade.grade))
        // }
        // if (topic_topic) {
        //     localStorage.setItem('topic_name', JSON.stringify(topic_topic.topic))
        // }
    // }, [topic_topic])

    // useEffect(() => {
    //     dispatch(sprintAdd_a(match.params.id))        
    //     localStorage.setItem('orderquestion', JSON.stringify(0))
    // }, [success_sprint_add])

    // useEffect(() => {
    //     dispatch(answerAdd_a(match.params.id))
    // }, [])

    // defaults.global.tooltips.enabled = false
    // defaults.global.legend.position = 'bottom'

    let id_id = Number(match.params.id)
    // alert(id_id)

    const create_sprint_handler = () => {
        // alert('hi')
        // alert(id_id)
        dispatch(sprintAdd_a(match.params.id))
        // alert(report_number)
    }

    const goBack = () => { history.goBack() }

    const { visible, setVisible, bindings } = useModal()

    return (
        <Container fluid={true} className="m-1 p-0">
        <GeistProvider>

                {/* Breadcrumb */} <ol class="breadcrumb">

    <li class="breadcrumb-item"><Link onClick={goBack} style={{ color: "#2a9df4" }}><i className="fas fa-chevron-left" style={{ marginRight: 3 }}></i>Назад</Link></li>
    <li class="breadcrumb-item"><Link to={`/d/`} style={{ color: "#2a9df4" }}>Все классы</Link></li>
    { (topic_topic.grade) ? (
        <li class="breadcrumb-item"><Link to={`/g/${topic_topic.grade._id}`} style={{ color: "#2a9df4" }}>
            {topic_topic.grade.grade} класс
        </Link></li>    ):(<p> </p>) }
{/*
    { (topic_topic) ? (
        <li class="breadcrumb-item"><Link to={`/to/${topic_topic._id}`} style={{ color: "#2a9df4" }}>
            {topic_topic.topic}
        </Link></li>    ):(<p> </p>) }
*/}
</ol> {/* breadcrumb-end */}

{( topic_topic._id != match.params.id || loading_topic || loading_sprints_topic_user) ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
    : error_topic ? <Message variant='danger'>{error_topic}</Message>
        : <div>
<h3 className="m-0 mt-5 mb-5 p-0">{topic_topic.topic}</h3>


{ prerequisites.length>0 &&
<div style={{ backgroundColor: '#eeeeee', borderRadius: 5 }} className="py-2 px-3 mb-5 mx-3">
    <div style={{ fontSize: 12, lineHeight : 1.2, marginTop: 7 }}>
        Предварительно требуются знания в темах:
    </div>
    <div  style={{ marginLeft: 15, fontSize: 12, lineHeight : 1.2, marginTop: '', marginBottom: '' }}>
        {prerequisites.map(x => (
            <div>
                <Link to={`/to/${x.topics._id}/?t=3`}>
                    {x.topics && 
                        <div style={{ marginTop: 7, marginBottom: 7 }}>
                            {x.topics.topic} ({x.topics.grade.grade} класс) 
                        </div>
                    }
                </Link>
            </div>
        ))}
    </div>
</div>
}



{/* Tabs - начало */}
<Tabs initialValue={keyword} >

  <Tabs.Item label={<span style={{ textAlign: 'center' }}><i className="far fa-caret-square-right" style={{ color: '#2a9df4' }} >  </i> <br/> <span style={{ fontSize: 10 }}>Видео</span></span> } value="1">
  <div>
  {/* Видео карта - начало */}
<div className="m-0 p-0 my-4">
<Col>

<div className="my-4 p-0">
<div ><div>

<Row>
{/* VIDEO CARDS - START */}
        {video_video.map(x => (
<Col xxs={12} xs={12} sm={6} md={4} lg={4} xl={4} className="m-0 p-0">
            <div key={x._id} className="my-3 mx-3 p-0" style={{ boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 20 }} xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card ><div style={{ margin: '-20px', padding: '20px', textAlign: 'center' }}>
<iframe src={x.url} frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video1' style={{ width: '250px'}} />
<Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}><div className="m-0 mt-2 p-0" style={{ fontSize: 14, lineHeight : 1.2, color: 'black', textAlign: 'left' }}>
{x.description}</div></Col></div></Card> </div>
</Col>
))}
{/* VIDEO CARDS - END */}
</Row>

{/* VIDEO-1 SCROLL START 
<div className="scroll-box"><div className="scroll-box__wrapper" ref={scrollWrapperRef}><div className="scroll-box__container" role="list" style={{ pointerEvents: isDragging ? 'none' : undefined }}>
        <div style={{ display: 'flex', width: '100%', hight: '100%' }} >
        {video_video.map(x => (
            <div key={x._id} className="my-4 mx-2 p-0" xxs={12} xs={12} sm={12} md={12} lg={12} xl={12} 
style={{ flexShrink: 0, width: '304px', height: '100%', borderRadius: '10px', 
margin: '10px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
backgroundPosition: 'center' }}>
            <Card ><div style={{ margin: '-20px', padding: '0px' }}>
<iframe src={x.url} frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video1' />
<Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}><div className="m-0 my-2 p-2" style={{ fontSize: 14, lineHeight : 1.2, color: 'black' }}>
{x.description}</div></Col></div></Card> </div>))}</div>
</div></div></div>
VIDEO-1 END */}

{/*<span><Link to={`/to_v/${match.params.id}`} className='mx-0'><button type="button" class="btn btn-outline-primary btn-sm p-1 px-2 m-2"> <i className="fas fa-"></i>Перейти к видео</button></Link>
</span>*/}
</div></div></div></Col></div>
{/* Видео карта - конец */}
</div></Tabs.Item>

<Tabs.Item label={<span style={{ textAlign: 'center' }}><i className="fas fa-running" style={{ color: '#2a9df4' }} >  </i> <br/> <span style={{ fontSize: 10 }}>Тесты</span></span> } value="3">
<div>
{/*Результаты - начало*/}
{topic_topic.testDisabled && 
    <div  className='m-0 my-3' >
    
            <div class="alert alert-dismissible alert-primary" style={{ margin: '30px', marginTop: '50px' }}>
                Тема является описательной и ознакомительной. Поэтому для данной темы тест не предусматривается 
            <i className="fas fa-check" style={{ color: '#2a9df4', marginLeft: '5px' }}> </i></div>
    
    </div>}    

{(sprints_topic_user.length==0 && !topic_topic.testDisabled) && 
    <div  className='m-0 mb-3' >
    <div class="alert alert-dismissible alert-primary" style={{ margin: '30px', marginTop: '50px' }}>
        Начните практиковаться и пройдите тест сейчас!
    <i className="fas fa-check" style={{ color: '#2a9df4', marginLeft: '5px' }}> </i></div>
<div style={{ textAlign: 'center', margin: 'auto' }}>

{true && <div>
    {true && <div>
        {(true)
            ?
            <div  className='m-0 my-3' style={{ textAlign: 'center', margin: 'auto' }} >
                <Link onClick={create_sprint_handler} className='mx-0'><button type="button" class="btn btn-primary btn-sm p-1 px-2 m-3" disabled={(loading_sprint_add)}> <i className="fas fa-"></i>Пройти тест</button></Link>
            </div>
            :
            <div  className='m-0 my-3' style={{ textAlign: 'center', margin: 'auto' }} >
                <Link onClick={() => setVisible(true)} className='mx-0'><button type="button" class="btn btn-primary btn-sm p-1 px-2 m-3" disabled={(loading_sprint_add)}> <i className="fas fa-"></i>Пройти тест</button></Link>
            </div>
        }
    </div> }
</div>}

</div>
    </div>}

<div style={{ textAlign: 'center', marginTop: 30 }}>
    <Modal {...bindings}>
        <Modal.Subtitle><strong>Доступ</strong></Modal.Subtitle>
            <Modal.Content>
                <p>
                    Для получения доступа к платному контенту, пожалуйста, пройдите по 
                    <span> </span>
                    <Link to={`/n/n6`} className='mx-0'>
                        <span style={{ color: 'blue' }}>этой ссылке</span>
                    </Link>
                </p>
            </Modal.Content>
        <Modal.Action passive onClick={() => setVisible(false)}><strong>Закрыть</strong></Modal.Action>
    </Modal>
</div>

{(sprints_topic_user.length>0 && !topic_topic.testDisabled) && 
<Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{true && <div>
    {true && <div>
        {(true)
            ?
            <div  className='m-0 my-3' style={{ textAlign: 'center', margin: 'auto' }} >
                <Link onClick={create_sprint_handler} className='mx-0'><button type="button" class="btn btn-primary btn-sm p-1 px-2 m-3" disabled={(loading_sprint_add)}> <i className="fas fa-"></i>Пройти новый тест</button></Link>
            </div>
            :
            <div  className='m-0 my-3' style={{ textAlign: 'center', margin: 'auto' }} >
                <Link onClick={() => setVisible(true)} className='mx-0'><button type="button" class="btn btn-primary btn-sm p-1 px-2 m-3" disabled={(loading_sprint_add)}> <i className="fas fa-"></i>Пройти новый тест</button></Link>
            </div>
        }
    </div> }
</div>}


<h4>Пройденные тесты по теме:</h4>


{ sprints_topic_user &&
    <div>
        { sprints_topic_user.length == 0 ?
        <div class="alert alert-dismissible alert-primary" style={{ margin: '30px', marginTop: '50px' }}>
            Вы пока не прошли ни одного теста. <br/>После прохождения тестов, вы сможете найти их на этой странице 
            <i className="fas fa-check" style={{ color: '#2a9df4', marginLeft: '5px' }}> </i>
            
        </div> : 
            <div style={{ fontSize: 14, lineHeight : 1.2, color: '#a6a6a6', textAlign: 'left', marginBottom: '10px' }}>
Нажмите на нужный тест, чтобы посмотреть детали</div>
    } </div> }



<Row>
{/* TEST CARDS - START */}
        {sprints_topic_user.map(x => (
            <Col xxs={12} xs={12} sm={12} md={6} lg={6} xl={4} className="m-0 p-0">
            <Link to={`/result/${x._id}`} style={{ textDecoration: 'none' }} className="m-0 p-0">
                        <div key={x._id} className="my-4 mx-4 p-0" style={{ borderRadius: 20, border: 'none' }} xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card style={{ boxShadow: "0 0 9px 3px #cec7c759", backgroundColor: '', border: 'none' }} ><div style={{ margin: '-20px', padding: '10px', paddingBottom: '7px', textAlign: 'left' }}>
<Row className="my-0 py-0">
    <Col style={{ textAlign: "left" }} xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
    <i className="fas fa-star" style={{ color: '#2a9df4', marginLeft: '20px' }}> </i><strong> {(x.score*1).toFixed(1)} </strong></Col>

    <Col style={{ textAlign: "left" }} xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} className="m-0 p-0">
    <i className="fas fa-check" style={{ color: '#2a9df4', marginLeft: '0px' }}> </i><span> Правильно: {(x.nca/x.nq*100).toFixed(0)}% </span><span style={{ fontSize: 10, marginLeft: '3px', color: 'grey' }}> [{x.nca} из {x.nq}]</span></Col>
</Row>
    
    <div style={{ display: '', width: '100%', hight: '100%', marginBottom: '10px', marginTop: '10px' }} >
        <Progress type="secondary" style={{ color: '#2a9df4', marginTop: '30px', paddingTop: '30px' }} value={(x.nca/x.nq*100)} /> 
    </div>
        
<Row className="m-0 p-0">
    <Col style={{ textAlign: "left", }} xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
        <i className="fas fa-stopwatch" style={{ color: '#2a9df4', marginLeft: '10px' }}> </i><span > {(x.time/1000).toFixed(1)} сек</span>
    </Col>
    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
        <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '1px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
            <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                {x.percentile}% 
                <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
            </Link>
        </div>
    </Col>
    <Col style={{ textAlign: "right", lineHeight : 1 }} xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
        <span style={{ fontSize: 12, marginRight: '10px', fontStyle: 'italic' }}> <Moment fromNow>{x.createdAt}</Moment></span>
    </Col>
</Row >
    
            </div></Card> </div>
            </Link></Col>
))}
{/* TEST CARDS - END */}
</Row>

{/*
<Table hover responsive className='table-sm'>
<thead>
    <tr>
        <th style={{ textAlign: "center", }}>Процент</th>
        <th style={{ textAlign: "center", }}>Правильно</th>
        <th style={{ textAlign: "center", }}>Время</th>
        <th style={{ textAlign: "center", }}>Дата</th>
        <th></th>        
    </tr>
</thead>

<tbody>
    {sprints_topic_user.map(x => (
        <tr key={x._id}>
            <td style={{ textAlign: "center", }}><span>{(x.nca/x.nq*100).toFixed(0)} %</span></td>
            <td style={{ textAlign: "center", }}><span>{x.nca} из {x.nq}</span></td>
            <td style={{ textAlign: "right", }}><span >{x.time/1000} сек</span></td>
            <td style={{ textAlign: "center", }}><span>{x.createdAt.substring(0, 10)}</span></td>
            <td style={{ textAlign: "center", }}><LinkContainer to={`/result/${x._id}`}>
                <Button variant='outline-primary' className='btn-sm'>
                    Посмотреть
                </Button>
            </LinkContainer></td>
        </tr>
    ))}
</tbody>
</Table>
*/}

</Container>
    }
{/*Результаты - конец*/}
</div></Tabs.Item>

{/*Конспекты - начало
<Tabs.Item label={<span style={{ textAlign: 'center' }}><i className="fas fa-book" style={{ color: '#2a9df4' }} >  </i><br/> <span style={{ fontSize: 10 }}>Шпаргалка</span></span> } value="2">
<div>
{ (topic_notes) ? (
<div>
<div className="my-2"> </div>
{topic_notes.map((x) => (
<Col className="m-0 p-0" key={x._id}>
    <div className="m-0 p-0">
        { x.title && <div className="m-0 mt-4 mb-2 p-0">
            <strong>{x.title}</strong>
        </div> }
        { x.text && <div className="m-0 my-2">
            {x.text}
        </div> }
        { x.image && <Col className="m-0 my-2 p-2" xxs={12} xs={12} sm={9} md={6} lg={4} xl={4}>
            <Image src={x.image} alt={x.image} fluid className="m-0 p-0"/>
        </Col> }
    </div>
</Col>
))}

</div>
):(<p> </p>) }
</div>
</Tabs.Item>
{/*Конспекты - конец*/}

{/*График - начало*/}
<Tabs.Item label={<span style={{ textAlign: 'center' }}><i className="fas fa-chart-line" style={{ color: '#2a9df4' }} >  </i> <br/> <span style={{ fontSize: 10 }}>Прогресс</span></span> } value="4">
    <div style={{ marginLeft: 10, marginRight: 10, marginTop: 35, marginBottom: 35 }}>
    <div style={{ textAlign: 'center' }}><span>Результаты последних {scores.length} тестов</span></div>
      <Line
        data={{
          labels: dates,
          datasets: [
            {
              label: 'Оценки',
              data: scores,
              backgroundColor: 'grey',
              borderColor: 'grey',
              borderWidth: 0.5,
              lineTension: 0.5,
            },
            {
                label: 'Скользящая средняя [3]',
                data: avg_scores, 
                backgroundColor: '#2a9df4',
                borderColor: '#2a9df4',
                borderWidth: 2,
                lineTension: 0.5,
              },
            // {
            //   label: 'Quantity',
            //   data: [47, 52, 67, 58, 9, 50],
            //   backgroundColor: 'orange',
            //   borderColor: 'red',
            // },
          ],
        }}
        height={300}
        width={600}
        options={{
          maintainAspectRatio: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],

            x: {  // <-- object '{' now, instead of array '[{' before in v2.x.x
                ticks: {
                  display: false
                }
              }

          },
          legend: {
            labels: {
              fontSize: 25,
            },
          },
        }}
      />
    </div>
    </Tabs.Item>
    
{/*График - конец*/}

{/*Оценки - начало*/}
<Tabs.Item label={<span style={{ textAlign: 'center' }}><i className="far fa-star" style={{ color: '#2a9df4' }} >  </i> <br/> <span style={{ fontSize: 10 }}>Оценки</span></span> } value="5">
    <div style={{ marginLeft: 10, marginRight: 10, marginTop: 35, marginBottom: 35 }}>

{/* ALL CARD - START */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={10} md={8} lg={6} xl={5}>
<Card className='my-3 mx-0 p-1' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px" }}>
    <strong style={{ fontSize: 20, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>По теме пройдено:</strong> </div>

<div className='mx-3'>
    <div><i className="fas fa-running" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{topic_sprints_number}</strong> тестов <span style={{ fontSize: 12, color: 'grey' }}></span></div>
    <div><i className="far fa-clock" style={{ color: '#2a9df4', marginBottom: 8 }}> </i> <strong>{(topic_sum_time*1).toFixed(0)}</strong> минут <span style={{ fontSize: 12, color: 'grey' }}> [общее время]</span></div>
</div>
<hr/>
<div>

    <Row>
        <Col>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}><i style={{color: '#2a9df4'}} className="far fa-star"></i> {(topic_score*1).toFixed(2)} <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> средняя оценка </span></div>
        </Col>
        <Col xxs={3} xs={3} sm={3} md={3} lg={3} xl={3} className="m-0 p-0">
            <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '7px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
                <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                    {(topic_percentile*1).toFixed(0)}%
                    <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
                </Link>
            </div>
        </Col>
    </Row>

    <div style={{marginLeft: 20}} >
        <Row>
            <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} style={{fontSize: 13}}>
                <div><i style={{color: ''}} className="far fa-"></i> 5.00</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.50-4.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 4.00-4.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 3.50-3.99</div>
                <div><i style={{color: ''}} className="far fa-"></i> 2.50-3.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> 1.50-2.49</div>
                <div><i style={{color: ''}} className="far fa-"></i> до 1.50</div>
            </Col>
            <Col xxs={7} xs={7} sm={7} md={7} lg={7} xl={7}>
                <div class="progress" style={{ marginTop: 6, maginBottom: 29 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: topic_count_50 / Math.max(topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: topic_count_45 / Math.max(topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: topic_count_40 / Math.max(topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: topic_count_35 / Math.max(topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: topic_count_25 / Math.max(topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: topic_count_15 / Math.max(topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
                <div class="progress" style={{ marginTop: 11.2, maginBottom: 0 }}>
                    <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: topic_count_0 / Math.max(topic_count_50, topic_count_45, topic_count_40, topic_count_35, topic_count_25, topic_count_15, topic_count_0) * 150, backgroundColor: '#2a9df4' }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
                </div>
            </Col>
        </Row>
    </div>

    <Row>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15 }}>
                {(topic_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> прав. ответы </span>
            </div>
        </Col>
        <Col>
            <div style={{ fontWeight: 'bold', marginTop: 15, textAlign: 'right' }}>
                {(100 - topic_correct_percent*1).toFixed(0)}% <span style={{ fontSize: 14, color: 'grey', fontWeight: 'normal' }}> неправил. </span>
            </div>
        </Col>
    </Row>

    <ProgressBar>
        <ProgressBar variant="#93e9be" style={{ backgroundColor: '#93e9be' }} now={topic_correct_percent} key={1} />
        <ProgressBar variant="#ff7070" style={{ backgroundColor: '#ffa1a1' }} now={100-topic_correct_percent} key={3} />
    </ProgressBar>

</div>

</Card>
</Col>
{/* ALL CARD - END */}

    </div>
{/*Оценки - конец*/}


</Tabs.Item>

</Tabs>





</div> }
</GeistProvider>        </Container>
    )
}

Topic_Screen.propTypes = {
    children: PropTypes.node.isRequired,
  }
  
export default Topic_Screen
