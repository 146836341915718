import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Table, Modal } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import Menu from '../components/Menu'
import FormContainer from '../components/FormContainer'
import { logout } from '../actions/userActions'
import { logMathAdd_a } from '../actions/mathActions'

function UserProfile_PasswordChange_Screen({ history }) {

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [birth_year, setBirth_year] = useState('')
    const [usergrade, setUsergrade] = useState('')
    const [city, setCity] = useState('')
    const [school, setSchool] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfileReducer)
    const { success } = userUpdateProfile

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!user || !user.name || success || userInfo._id !== user._id) {
            // if (false) {
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails('profile'))

            } else {
                setName(user.profile.user_name)
                setSurname(user.profile.user_surname)
                setBirth_year(user.profile.user_birth_year)
                setUsergrade(user.profile.user_grade)
                setCity(user.profile.user_city)
                setSchool(user.profile.user_school)
                setEmail(user.email)
            }
        }
    }, [dispatch, history, userInfo, user, success])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password != confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'surname': surname, 
                'birth_year': birth_year,
                'usergrade': usergrade,
                'city': city,
                'school': school,
                'email': email,
                'password': password,
            }))
            setMessage('')
            // handleShow()
            dispatch(logout())
            history.push('/')
            
        }

    }
    return (
        <div>
        <FormContainer>
        <Row>
            <Col>
            {/* <Col md={3}> */}
                <h2>Сменить пароль</h2>

                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>

                    <Form.Group controlId='password' className='my-3'>
                        <Form.Label>Введите новый пароль</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder=''
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='passwordConfirm' className='my-3'>
                        <Form.Label>Подтвердите новый пароль</Form.Label>
                        <Form.Control

                            type='password'
                            placeholder=''
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Button type='submit' variant='primary' className='my-1'>
                        Изменить пароль
                    </Button>

                </Form>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Изменения пароля</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Пароль успешно изменён! 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Закрыть
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Col>

            {/* <Col md={9}>
                <h2>История заказов</h2>
                {loadingOrders ? (
                    <Loader />
                ) : errorOrders ? (
                    <Message variant='danger'>{errorOrders}</Message>
                ) : (
                            <Table striped responsive className='table-sm'>
                                <thead>
                                    <tr>
                                        <th className="text-center">ID</th>
                                        <th className="text-center">Дата</th>
                                        <th className="text-center">Итого</th>
                                        <th className="text-center">Статус оплаты</th>
                                        <th className="text-center">Статус доставки</th>
                                        <th className="text-center"></th>
                                        <th className="text-center"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {orders.map(order => (
                                        <tr key={order._id}>
                                            <td className="text-right">{order._id}</td>
                                            <td className="text-center">{order.createdAt.substring(0, 10)}</td>
                                            <td className="text-right">${(order.totalPrice*1).toFixed(0)}</td>
                                            <td className="text-center">{order.isPaid2 ? order.paidAt2.substring(0, 10) : (
                                                <i className='fas fa-times' style={{ color: 'red' }}></i>
                                            )}</td>
                                            <td className="text-center">{order.isDelivered ? order.deliveredAt.substring(0, 10) : (
                                                <i className='fas fa-times' style={{ color: 'red' }}></i>
                                            )}</td>
                                            <td>
                                                <LinkContainer to={`/order/${order._id}`}>
                                                    <Button className='btn-sm'>Детали</Button>
                                                </LinkContainer>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
            </Col> */}
        </Row>
        </FormContainer>
        </div>
    )
}

export default UserProfile_PasswordChange_Screen