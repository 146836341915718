import { Container } from 'react-bootstrap'
import { HashRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import AccountLogin_Screen from './screens/AccountLogin_Screen'
import AccountRegister_Screen from './screens/AccountRegister_Screen'

import AccountPwRequest_Screen from './screens/AccountPwRequest_Screen'
import AccountNewPw_Screen from './screens/AccountNewPw_Screen'
import AccountPwRequest_Done_Screen from './screens/AccountPwRequest_Done_Screen'

import UserProfile_Screen from './screens/UserProfile_Screen'
import UserProfile_Edit_Screen from './screens/UserProfile_Edit_Screen'
import UserProfile_PasswordChange_Screen from './screens/UserProfile_PasswordChange_Screen'
import UserProfile_Image_Screen from './screens/UserProfile_Image_Screen'

import AdminUsers_Screen from './screens/AdminUsers_Screen'
import AdminUserEdit_Screen from './screens/AdminUserEdit_Screen'
import MathMain_Screen from './screens/math/MathMain_Screen'
import Dashboard_Screen from './screens/math/Dashboard_Screen'
import Grade_Screen from './screens/math/Grade_Screen'
import Topic_Screen from './screens/math/Topic_Screen'
import Sprint_Result_Screen from './screens/math/Sprint_Result_Screen'
import Sprint_Screen from './screens/math/Sprint_Screen'
import Video_Screen from './screens/math/Video_Screen'
import Results_Screen from './screens/math/Results_Screen'
import N1_Screen from './screens/math/N1_Screen'
import N2_Screen from './screens/math/N2_Screen'
import N3_Screen from './screens/math/N3_Screen'
import N4_CA_Screen from './screens/math/N4_CA_Screen'
import N5_Offer_Screen from './screens/math/N5_Offer_Screen'
import Kommentarii2_Screen from './screens/math/Kommentarii2_Screen'
import N6_Payment_Screen from './screens/math/N6_Payment_Screen'
import N7_Payment_Screen from './screens/math/N7_Payment_Screen'
import Map_Screen from './screens/math/Map_Screen'
import Content_Screen from './screens/math/Content_Screen'
import MyProgress_Screen from './screens/math/MyProgress_Screen'
import N8_Instructions_Screen from './screens/math/N8_Instructions_Screen'
import N9_Contacts_Screen from './screens/math/N9_Contacts_Screen'



function App() {
  return (
    // внутри router предположительно компоненты могут меняться
    <Router>
      <Header />
      <main className="py-3 m-0">
        <Container >

          <Route path='/login' component={AccountLogin_Screen} />
          <Route path='/register' component={AccountRegister_Screen} />

          <Route path='/pw_reset' component={AccountPwRequest_Screen} exact />
          <Route path='/pw' component={AccountNewPw_Screen} />
          <Route path='/pw_rr' component={AccountPwRequest_Done_Screen} exact />

          <Route path='/profile' component={UserProfile_Screen} />
          <Route path='/profile_edit' component={UserProfile_Edit_Screen} />
          <Route path='/password_change' component={UserProfile_PasswordChange_Screen} />
          <Route path='/image' component={UserProfile_Image_Screen} />
          
          
  {/* ? - means that area after '?' can be blank */}

          <Route path='/admin/userlist' component={AdminUsers_Screen} />
          <Route path='/admin/user/:id/edit' component={AdminUserEdit_Screen} />

{/* Math */}
          <Route path='/' component={MathMain_Screen} exact />
          <Route path='/d' component={Dashboard_Screen} exact />
          <Route path='/g/:id' component={Grade_Screen} exact />
          <Route path='/to/:id' component={Topic_Screen}  />
          <Route path='/s/:id' component={Sprint_Screen} exact />
          <Route path='/result/:id' component={Sprint_Result_Screen} exact />
          <Route path='/to_v/:id' component={Video_Screen} exact />
          <Route path='/r' component={Results_Screen} exact />
          <Route path='/n/n1' component={N1_Screen} exact />
          <Route path='/n/n2' component={N2_Screen} exact />
          <Route path='/n/n3' component={N3_Screen} exact />
          <Route path='/n/ca' component={N4_CA_Screen} exact />
          <Route path='/n/offer' component={N5_Offer_Screen} exact />          
          <Route path='/kommentarii2' component={Kommentarii2_Screen} exact />
          <Route path='/n/n6' component={N6_Payment_Screen} exact />
          <Route path='/n/n7' component={N7_Payment_Screen} exact />
          <Route path='/contacts' component={N9_Contacts_Screen} exact />
          <Route path='/map' component={Map_Screen} exact />
          <Route path='/db' component={Content_Screen} exact />
          <Route path='/mp' component={MyProgress_Screen} exact />
          <Route path='/i' component={N8_Instructions_Screen} exact />

        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
