import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import Menu from '../components/Menu'
import { new_pw_a, logMathAdd_a } from '../actions/mathActions'

function AccountNewPw_Screen({ location, history }) {

    // const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    // const [password, setPassword] = useState('')
    // const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    var link_number = history.location.pathname.split('pw/')[1]
    // link_number = link_number.toString()
    // console.log(link_number)

    const userRegister = useSelector(state => state.userRegister)
    const { userInfo } = userRegister

    const new_pw_ = useSelector(state => state.new_pw_r)
    const { error, loading, text } = new_pw_

    useEffect(() => {
        dispatch(new_pw_a(link_number))
    }, [])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(new_pw_a(link_number))
    }
    
    // const goBack = () => { history.goBack() }

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    return (
        <div>
        <FormContainer>
{/*
        <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
            <i className="fas fa-chevron-left"/> Назад
        </Button>
*/}
            <h3 style={{ marginTop: '30px', fontWeight: 'bold' }}>Восстановление пароля</h3>

            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

{text == 'ok' && 
    <Message variant='success'>
        Новый пароль отправлен на Ваш емейл
    </Message>
}

{text == 'no_user' && 
    <Message variant='danger'>
        Пользователя с таким именем пользователя (емейлом) не существует
    </Message>
}

{text == 'no_link' && 
    <Message variant='danger'>
        Ссылка на восстановление пароля истекла
    </Message>
}

        </FormContainer>
        </div>
    )
}

export default AccountNewPw_Screen
