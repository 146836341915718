import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap'
// import Product from '../components/Product'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
import Axios from 'axios'
import topics from '../../topics.jpg'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"



function Map_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    const goBack = () => { history.goBack() }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

{/* Breadcrumb */} 
<ol class="breadcrumb" style={{ backgroundColor: '', padding: 0, margin: '5px' }} >
<li class="breadcrumb-item"><Link onClick={goBack} style={{ color: "#2a9df4" }}><i className="fas fa-chevron-left" style={{ marginRight: 3 }}></i>Назад</Link></li>
<li class="breadcrumb-item"><Link to={`/db/`} style={{ color: "#2a9df4" }}>Центр</Link></li>
</ol> {/* breadcrumb-end */}

    <div style={{ marginTop: 20, marginBottom: 30, fontSize: 24 }}>
        <strong  >Программа</strong>
    </div>
    <Row style={{ textAlign: 'center' }} className='d-xs-block d-sm-none d-md-none d-lg-none d-xl-none m-0 p-0'>
        <Col xxs={2} xs={2} sm={2} md={2} lg={2} xl={2} className="m-0 p-0">
            <i className="fas fa-arrow-left" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
        </Col>
        <Col xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} className="m-0 p-0" style={{ fontSize: 14 }}>
            Скрольте таблицу влево и вправо, чтобы просмотреть информацию по всем классам 
        </Col>
        <Col xxs={2} xs={2} sm={2} md={2} lg={2} xl={2} className="m-0 p-0">
            <i className="fas fa-arrow-right" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
        </Col>
    </Row>
    <div>
    
    
        <Table responsive hover style={{ width: '1280px', marginTop: 30 }} >
            <thead>
                <tr>
                <th style={{ width: '12%' }} >Направление</th>
                {Array.from({ length: 11 }).map((_, index) => (
                    <th key={index} style={{ width: '8%' }} >{index+1} класс</th>
                ))}
                </tr>
            </thead>

            
            <tbody style={{ fontSize: 12, lineHeight: 1.1, width: 100 }}>
                <tr>
                <td>1. Сложение и вычитание</td>
                <td>Базовые темы</td>
                <td></td>
                <td>Трехзначные</td>
                <td></td>
                <td></td>
                <td>Отрицательные числа</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>

                <tr>
                <td>2. Умножение и деление</td>
                <td></td>
                <td>Базовые темы</td>
                <td>Двузначные<br/><br/>трехзначные</td>
                <td></td>
                <td></td>
                <td>Отрицательные числа</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>3. Уравнения</td>
                <td></td>
                <td>Простейшие</td>
                <td></td>
                <td>В несколько действий</td>
                <td></td>
                <td>С отрицательными числами и дробями<br/><br/>Система с 2-мя переменными</td>
                <td></td>
                <td>Квадратные уравнения: неполные, дискриминант, теорема Виета</td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>4. Неравенства</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Простейшие</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Система неравенств<br/><br/>Квадратные неравенства</td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>5. Преобразования и различные свойства</td>
                <td></td>
                <td>Переместительное свойство умножения</td>
                <td>Распределительное свойство умножения</td>
                <td>Распределительное свойство умножения<br/><br/>Сочетательное свойство умножения</td>
                <td>Приведение подобных слагаемых</td>
                <td>Раскрытие скобок и приведение подобных слагаемых</td>
                <td>[ Формулы сокращенного умножения ]
                <br/><br/>Многочлены: раскрытие скобок, выведение общего множителя за скобки
                </td>
                <td>[ Корень: из/под ]<br/><br/>Разложение трехчлена на множители</td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>6. Дроби</td>
                <td></td>
                <td></td>
                <td></td>
                <td>Доли<br/><br/>Введение в дроби</td>
                <td>Делители, делимость, кратное<br/><br/>Сокращение<br/><br/>Сложение и вычетание<br/><br/>Умножение и деление<br/><br/>Десятичная дробь</td>
                <td>Отношение двух чисел<br/><br/>Процентное соотношение<br/><br/>Пропорция</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>7. Степени и корни</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Введение</td>
                <td></td>
                <td>Свойства степеней<br/><br/>
                Отрициательная степень</td>
                <td>Корень (введение, ввод в / вывод из под корня)</td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
                <tr>
                <td>8. Функция</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Линейная ф.<br/><br/>
                Анализ лин. ф.<br/><br/>
                Введение в функции x², x³, 1/x</td>
                <td>Квадратичная ф. (продолжение)<br/><br/> 
                Квадратные неравенства</td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
            </tbody>
            </Table>
    
    
    </div>
</div>


</Container>
</GeistProvider>
</Container>
    )
}

export default Map_Screen
