import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Card, Image } from 'react-bootstrap'
import { Link, useParams, Route } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { logMathAdd_a, sprint_a, topics_a, sprintAdd_a, topic_a, lastSprint_a, randomQuestion_a, lastAnswer_a, answerNotFirstAdd_a } from '../../actions/mathActions'
import { GeistProvider, CssBaseline, Progress, Spinner } from "@geist-ui/react"



function Sprint_Screen({ match, history }) {

    // let { slug } = useParams()
    // console.log(match)
    // let keyword = history.location.search

    const [time, setTime] = React.useState(0)
    const [timerOn, setTimerOn] = React.useState(true)

    const [showloader, setShowloader]   = useState(false)

    const dispatch = useDispatch()

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const sprint_ = useSelector(state => state.sprint_r)
    const { error_sprint_sprint, loading_sprint_sprint, sprint_sprint } = sprint_

    const randomQuestion_ = useSelector(state => state.randomQuestion_r)
    const { error_random_question, loading_random_question, random_question, options } = randomQuestion_

    const lastAnswer_ = useSelector(state => state.lastAnswer_r)
    const { error_last_answer, loading_last_answer, last_answer } = lastAnswer_

    const answerNotFirstAdd_ = useSelector(state => state.answerNotFirstAdd_r)
    const { success_answer_add } = answerNotFirstAdd_

    const topic_ = useSelector(state => state.topic_r)
    const { error_topic, loading_topic, topic_topic, topic_notes, topic_page, topic_pages, prerequisites } = topic_

    // let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    // !!!
    useEffect(() => {
        // if (!localStorage.getItem('backbtn')) {
        if (JSON.parse(localStorage.getItem('backbtn'))==="false") {
            history.push(`/to/${sprint_sprint.topic._id}/?t=3`)
        }
        // if (random_question) {
            // if ( success_answer_add || random_question) {
        dispatch(sprint_a(match.params.id))
            // }
        // }
    }, [success_answer_add])

    useEffect(() => {
        // if (!sprint_sprint || !sprint_sprint.nga || sprint_sprint.nga === 0) {
            dispatch(randomQuestion_a(match.params.id))
        // }
    }, [])

    // useEffect(() => {
    //     dispatch(sprint_a(match.params.id))
    // }, [success_answer_add])
    // useEffect(() => {
    //     dispatch(answerNotFirstAdd_a(JSON.parse(localStorage.getItem('topic'))))
    // }, [])

    useEffect(() => {
        let interval = null
    
        if (timerOn) {
          interval = setInterval(() => {
            setTime((prevTime) => prevTime + 10);
          }, 10)
        } else if (!timerOn) {
          clearInterval(interval);
        }
    
        return () => clearInterval(interval)
      }, [timerOn])

    const choiceHandler = (option_, interval) => {
        localStorage.setItem('orderquestion', JSON.stringify(sprint_sprint.nga+1))
        var success_answer_add = false
        dispatch(answerNotFirstAdd_a({
            sprint: sprint_sprint._id,
            option: option_,
            question: random_question._id,
            time: time,
        }))


        setTimeout(() => {
            dispatch(randomQuestion_a(match.params.id))
         }, 100)

        setTime(0)
        if (sprint_sprint.nga + 1 >= Math.max(1, sprint_sprint.nq)) {

            setShowloader(true)
            setTimeout(() => {
                history.push(`/result/${match.params.id}`)
             }, 500)
            
        // } else if (sprint_sprint.nga == 0) {
        //     history.push(`/s/${sprint_sprint._id}`)
        } else {
            setShowloader(false)
        }
    }

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const goBack = () => { history.goBack() }

    // sprint_sprint.nga + 1 > sprint_sprint.nq

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <div class="progress">
            <div class="progress-bar progress-bar-animated" role="progressbar"  style={{width: width*0.95*sprint_sprint.nga/Math.max(1, sprint_sprint.nq) }} aria-valuenow='5' aria-valuemin="0" aria-valuemax="10"></div>
        </div>
{/*   <div id="display">
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</span>
        <span>{("0" + ((time / 10) % 100)).slice(-2)}</span>
      </div>    */}

        {( showloader || loading_random_question || loading_sprint_sprint || sprint_sprint.nga + 1 > Math.max(1, sprint_sprint.nq) ) ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
            : error_random_question ? 
            <div style={{ marginTop: 20 }}>
                <Message variant='success' style={{ marginTop: 20 }}>Тестовые вопросы по теме будут добавлены в скором времени</Message>
                <Message variant='danger' style={{ marginTop: 20 }}>{error_random_question}</Message>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ marginTop: 20 }}>
                        <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
                            <i className="fas fa-chevron-left"/> Вернуться на предыдущую страницу
                        </Button>
                    </div>
                </div>
            </div>
                : <div>

{/*
{sprint_sprint && 
<div style={{ color: 'grey' }}>Номер спринта (будет скрыто): {sprint_sprint._id} <br/></div> }
{(random_question) && 
    <div style={{ color: 'grey' }}>ID вопроса (будет скрыто): {random_question._id} <br/></div>}
{sprint_sprint && 
        <div style={{ color: 'grey' }}>ТОПИК (будет скрыто): {sprint_sprint.topic._id} <br/><br/></div> }
*/}

<div className="m-0 mb-4 p-0" style={{ textAlign: 'center', marginBottom: '10px' }} ><strong>Вопрос: {Math.min(sprint_sprint.nga + 1, Math.max(1, sprint_sprint.nq))} </strong><span style={{ color: 'grey', fontSize: '11px' }}>[из {Math.max(1, sprint_sprint.nq)}]</span></div>

{(random_question) && <div>
    {(random_question.prebody) && 
        <div className='p-0 m-0 ml-1'>
            <span className="p-0 m-1 ml-2">
                {random_question.prebody}
            </span> 
        </div>
    }

    <div className='p-0 m-0 ml-1'>
        <span className="p-0 m-1 ml-2">
            {random_question.body} 
        </span>
    </div>

    {(random_question.afterbody) && 
        <div className='p-0 m-0 ml-1'>
            <span className="p-0 m-1 ml-2">
                {random_question.afterbody} 
            </span>
        </div>
    }

    {(random_question.image1) && 
        <div className='p-0 m-0 ml-1'>
            <span className="p-0 m-1 ml-2">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Image src={random_question.image1} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '', borderRadius: 10 }} />
                </div>
            </span>
        </div>
    }

    {(random_question.image2) && 
        <div className='p-0 m-0 ml-1'>
            <span className="p-0 m-1 ml-2">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Image src={random_question.image2} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '', borderRadius: 10 }} />
                </div>
            </span>
        </div>
    }

    {(random_question.image3) && 
        <div className='p-0 m-0 ml-1'>
            <span className="p-0 m-1 ml-2">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Image src={random_question.image3} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '', borderRadius: 10 }} />
                </div>
            </span>
        </div>
    }

</div> }

<div style={{ marginTop: 30 }}>
    <strong>Выберите правильный ответ: </strong> 
    {options ? 
        <div>
            {options.map(x => (
            <span onClick={() => choiceHandler(x.option)}><button type="button" class="btn btn-outline-primary btn-sm py-1 px-2 m-2 mt-3 ml-2">{x.option}</button></span>
            ))}
        </div>
        : <span></span>
    }
</div>



    <div style={{ backgroundColor: '#eeeeee', borderRadius: 5, marginTop: 70 }} className="py-2 px-3 mb-5 mx-3">
        <div style={{ fontSize: 12, lineHeight : 1.2, marginTop: 7 }}>
            Основная тема: {topic_topic.topic}
        </div>

        { prerequisites.length>0 && <div>
        <div style={{ fontSize: 12, lineHeight : 1.2, marginTop: 7 }}>
            Другие базовые затрагиваемые темы:
        </div>
        <div style={{ marginLeft: 15, fontSize: 12, lineHeight : 1.2, marginTop: '', marginBottom: '' }}>
            {prerequisites.map(x => (
                <div>
                    <Link to={`/to/${x.topics._id}/?t=3`}>
                        {x.topics && 
                            <div style={{ marginTop: 7, marginBottom: 7 }}>
                                {x.topics.topic} ({x.topics.grade.grade} класс) 
                            </div>
                        }
                    </Link>
                </div>
            ))}
        </div>
        </div> }
    </div>

    

</div> }
</GeistProvider>        </Container>
    )
}

export default Sprint_Screen
