import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap'
// import Product from '../components/Product'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
import Axios from 'axios'
import topics from '../../topics.jpg'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a, stat_easy_a } from '../../actions/mathActions'
import { getUserDetails } from '../../actions/userActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"
import {ToastContainer, toast, Zoom, Bounce, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import p101_grades from '../../1/p101_grades.jpg'
// import p102_profile from '../../1/p102_profile.jpg'
// import p103_math_program from '../../1/p103_math_program.jpg'
// import p104_my_map from '../../1/p104_my_map.jpg'
// import p105_results from '../../1/p105_results.jpg'
// import p106_info from '../../1/p106_info.jpg'
import b678678 from '../../1/678678.jpg'
import b4662 from '../../1/4662.jpg'
import b5708 from '../../1/5708.jpg'
import b5798 from '../../1/5798.jpg'
import b5462 from '../../1/5462.jpg'
import b546 from '../../1/546.jpg'

toast.configure()

function Content_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()

    const stat_easy_ = useSelector(state => state.stat_easy_r)
    const { stat_easy_sprints, stat_easy_fives, stat_easy_topics, stat_easy_grades, stat_easy_mastery, stat_easy_sprints_today } = stat_easy_

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        dispatch(getUserDetails('profile'))
    }, [])

    const goBack = () => { history.goBack() }

    useEffect(() => {
        if (!stat_easy_sprints || stat_easy_sprints_today===0) {
            dispatch(stat_easy_a())
        }
    }, [dispatch])

    const notify2 = () => { toast.info(
        <div>
        <>Добро пожаловать!</>
        <div>
            Рекомендуем пройти тесты по всем темам предыдущих классов, чтобы вы смогли определить пробелы ✌  
        </div>
        <hr/>
        <div style={{ fontSize: 14 }}>
            Данное уведомление носит информативный характер и прекратит появляться после прохождения вами тестов в 2-х разных темах  
        </div>
        </div>
        , { position: "bottom-right", autoClose: 30000, delay: 500, 
        hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true,
        progress: undefined, toastId: 1, theme: "colored", transition: Slide
        //  autoClose: false
      } ) }

    useEffect(() => { 
        if (stat_easy_topics<2) { dispatch(notify2) } }, [])

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>
    <div style={{ marginTop: 20, marginBottom: 10, fontSize: 24 }}>
        <  >Центр</>
    </div>

        <div>
    
<Row>

{/* CARD 1 - Start */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={6} md={4} lg={4} xl={3}>
    <Link to={`/d/`} style={{ textDecoration: 'none' }} className="m-0 p-0">
        <div className="m-3 p-0" style={{ boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10, border: 'none' }}>
            <Card shadow style={{ backgroundColor: '#F1DFC7', borderRadius: 10, border: 'none' }} >
                <div style={{ margin: '0px', padding: '10px', border: 'none' }}>

<Row>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
        <div style={{ textAlign: 'right', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, paddingRight: 15 }}>
            <Card.Img src={b678678} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>

<Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
<div className="m-0 mt-4 p-0 mb-1" style={{ fontSize: 14, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", fontWeight: "bold"}}>
    <div style={{ marginTop: 30 }}>
        Выбрать класс и тему <>для изучения</>
    </div>
</div>
</Col>
</Row>

</div></Card></div></Link></Col>
{/* CARD 1 - Finish */}

{/* CARD 2 */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={6} md={4} lg={4} xl={3}>
    <Link to={`/profile/`} style={{ textDecoration: 'none' }} className="m-0 p-0">
        <div className="m-3 p-0" style={{ boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10, border: 'none' }}>
            <Card shadow style={{ backgroundColor: '#D7F5FF', borderRadius: 10, border: 'none' }} >
                <div style={{ margin: '0px', padding: '10px', border: 'none' }}>

<Row>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
        <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 5, marginTop: 0, paddingRight: 15 }}>
            <Card.Img src={b4662} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>

<Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
<div className="m-0 mt-4 p-0 mb-1" style={{ fontSize: 14, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", fontWeight: "bold"}}>
    <div style={{ marginTop: 40 }}>
        Мой профайл
    </div>
</div>
</Col>
</Row>

</div></Card></div></Link></Col>
{/* CARD 2 */}

{/* CARD 3 - Start */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={6} md={4} lg={4} xl={3}>
    <Link to={`/map/`} style={{ textDecoration: 'none' }} className="m-0 p-0">
        <div className="m-3 p-0" style={{ boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10, border: 'none' }}>
            <Card shadow style={{ backgroundColor: '#EBDBC2', borderRadius: 10, border: 'none' }} >
                <div style={{ margin: '0px', padding: '10px', border: 'none' }}>

<Row>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
        <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 5, marginTop: 5, paddingRight: 0 }}>
            <Card.Img src={b5708} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>

<Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
<div className="m-0 mt-4 p-0 mb-1" style={{ fontSize: 14, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", fontWeight: "bold"}}>
    <div style={{ marginTop: 30 }}>
        Программа по математике
    </div>
</div>
</Col>
</Row>

</div></Card></div></Link></Col>
{/* CARD 3 - Finish */}

{/* CARD 4 - Start */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={6} md={4} lg={4} xl={3}>
    <Link to={`/mp/`} style={{ textDecoration: 'none' }} className="m-0 p-0">
        <div className="m-3 p-0" style={{ boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10, border: 'none' }}>
            <Card shadow style={{ backgroundColor: '#EBDBC2', borderRadius: 10, border: 'none' }} >
                <div style={{ margin: '0px', padding: '10px', border: 'none' }}>

<Row>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
        <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 10, marginTop: 10, paddingRight: 10 }}>
            <Card.Img src={b5798} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>

<Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
<div className="m-0 mt-4 p-0 mb-1" style={{ fontSize: 14, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", fontWeight: "bold"}}>
    <div style={{ marginTop: 30, marginRight: 20 }}>
        Усвоение тем мною
    </div>
</div>
</Col>
</Row>

</div></Card></div></Link></Col>
{/* CARD 4 - Finish */}

{/* CARD 5 - Start */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={6} md={4} lg={4} xl={3} style={{ color: 'white' }}>
    <Link to={`/r/`} style={{ textDecoration: 'none' }} className="m-0 p-0">
        <div className="m-3 p-0" style={{ boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10, border: 'none' }}>
            <Card shadow style={{ backgroundColor: '#0C2A44', borderRadius: 10, border: 'none' }} >
                <div style={{ margin: '0px', padding: '10px', border: 'none' }}>

<Row>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
        <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 0, marginTop: 0, paddingRight: 15 }}>
            <Card.Img src={b546} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>

<Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
<div className="m-0 mt-4 p-0 mb-1" style={{ fontSize: 14, lineHeight : 1, color: "white", textAlign: "left", paddingTop: "0px", fontWeight: "bold"}}>
    <div style={{ marginTop: 40 }}>
        Результаты моих прошлых тестов
    </div>
</div>
</Col>
</Row>

</div></Card></div></Link></Col>
{/* CARD 5 - Finish */}

{/* CARD 6 - Start */}
<Col className="m-0 p-0" xxs={12} xs={12} sm={6} md={4} lg={4} xl={3}>
    <Link to={`/i/`} style={{ textDecoration: 'none' }} className="m-0 p-0">
        <div className="m-3 p-0" style={{ boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10, border: 'none' }}>
            <Card shadow style={{ backgroundColor: '#DDE7FF', borderRadius: 10, border: 'none' }} >
                <div style={{ margin: '0px', padding: '10px', border: 'none' }}>

<Row>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
        <div style={{ textAlign: 'center', fontSize: 18, lineHeight: 1.2, fontWeight: '', marginBottom: 0, marginTop: -5, paddingRight: 15 }}>
            <Card.Img src={b5462} width="100%" height="100%" className="m-0 p-0" style={{ borderRadius: 10 }} /> 
        </div>
    </Col>

<Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
<div className="m-0 mt-4 p-0 mb-1" style={{ fontSize: 14, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", fontWeight: "bold"}}>
    <div style={{ marginTop: 30 }}>
        Важно: Как пользоваться и с чего начать?
    </div>
</div>
</Col>
</Row>

</div></Card></div></Link></Col>
{/* CARD 6 - Finish */}

</Row>
        
    </div>
</div>

</Container>
</GeistProvider>
</Container>
    )
}

export default Content_Screen
