import axios from 'axios'   // for api calls 
import {
    GRADES_REQUEST, GRADES_SUCCESS, GRADES_FAIL, 
    TOPICS_REQUEST, TOPICS_SUCCESS, TOPICS_FAIL, 
    TOPICS_GRADE_REQUEST, TOPICS_GRADE_SUCCESS, TOPICS_GRADE_FAIL, 
    TOPIC_REQUEST, TOPIC_SUCCESS, TOPIC_FAIL, 
    LOG_MATH_ADD_REQUEST, LOG_MATH_ADD_SUCCESS, LOG_MATH_ADD_FAIL, 
    SPRINT_ADD_REQUEST, SPRINT_ADD_SUCCESS, SPRINT_ADD_FAIL, 
    LAST_SPRINT_REQUEST, LAST_SPRINT_SUCCESS, LAST_SPRINT_FAIL, 
    RANDOM_QUESTION_REQUEST, RANDOM_QUESTION_SUCCESS, RANDOM_QUESTION_FAIL, 
    ANSWER_ADD_REQUEST, ANSWER_ADD_SUCCESS, ANSWER_ADD_FAIL, 
    LAST_ANSWER_REQUEST, LAST_ANSWER_SUCCESS, LAST_ANSWER_FAIL, 
    ANSWER_NF_ADD_REQUEST, ANSWER_NF_ADD_SUCCESS, ANSWER_NF_ADD_FAIL, 
    SPRINTS_USER_REQUEST, SPRINTS_USER_SUCCESS, SPRINTS_USER_FAIL, 
    SPRINTS_TOPIC_USER_REQUEST, SPRINTS_TOPIC_USER_SUCCESS, SPRINTS_TOPIC_USER_FAIL, 
    SPRINT_REQUEST, SPRINT_SUCCESS, SPRINT_FAIL, 
    VIDEO_REQUEST, VIDEO_SUCCESS, VIDEO_FAIL,
    GRADE_REQUEST, GRADE_SUCCESS, GRADE_FAIL, 
    SCREEN_GRADES_SPRINTS_REQUEST, SCREEN_GRADES_SPRINTS_SUCCESS, SCREEN_GRADES_SPRINTS_FAIL, 
    SCREEN_TOPICS_SPRINTS_REQUEST, SCREEN_TOPICS_SPRINTS_SUCCESS, SCREEN_TOPICS_SPRINTS_FAIL, 
    SCREEN_ALL_TOPICS_SPRINTS_REQUEST, SCREEN_ALL_TOPICS_SPRINTS_SUCCESS, SCREEN_ALL_TOPICS_SPRINTS_FAIL, 
    PI1_REQUEST, PI1_SUCCESS, PI1_FAIL,
    STAT_REQUEST, STAT_SUCCESS, STAT_FAIL, 
    STAT_EASY_REQUEST, STAT_EASY_SUCCESS, STAT_EASY_FAIL, 
    // TOPICS_MY_PROGRESS_REQUEST, TOPICS_MY_PROGRESS_SUCCESS, TOPICS_MY_PROGRESS_FAIL, 
    USER_ACTIVATE_REQUEST, USER_ACTIVATE_SUCCESS, USER_ACTIVATE_FAIL, 

    PW_CHANGE_REQUEST_REQUEST, PW_CHANGE_REQUEST_SUCCESS, PW_CHANGE_REQUEST_FAIL, 
    NEW_PW_REQUEST, NEW_PW_SUCCESS, NEW_PW_FAIL,

    PROCESS_GRADE_REQUEST, PROCESS_GRADE_SUCCESS, PROCESS_GRADE_FAIL, 

    
} from '../constants/mathConstants'

export const grades_a = () => async (dispatch) => {
    try {
        dispatch({ type: GRADES_REQUEST })

        const { data } = await axios.get(
            `/api/math/grades`,
            )
        dispatch({
            type: GRADES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GRADES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const topics_a = () => async (dispatch) => {
    try {
        dispatch({ type: TOPICS_REQUEST })

        const { data } = await axios.get(`/api/math/topics_all/`)

        dispatch({
            type: TOPICS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TOPICS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const topicsGrade_a = (id_) => async (dispatch) => {
    try {
        dispatch({ type: TOPICS_GRADE_REQUEST })

        const { data } = await axios.get(
            `/api/math/topics/${id_}/`,
            )
        dispatch({
            type: TOPICS_GRADE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TOPICS_GRADE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const topic_a = (id_) => async (dispatch, getState) => {
    try {
        dispatch({ type: TOPIC_REQUEST })
        
        const {
            userLogin: { userInfo },
        } = getState()
        
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/math/topic/${id_}/`,
            config
            )
        dispatch({
            type: TOPIC_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TOPIC_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const logMathAdd_a = (anylog) => async (dispatch) => {
    try {
        dispatch({
            type: LOG_MATH_ADD_REQUEST
        })

        // const {
        //     userLogin: { userInfo },
        // } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
        //         Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/math/log_math_add/`,
            anylog,
            config
        )

        dispatch({
            type: LOG_MATH_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: LOG_MATH_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const sprintAdd_a = (id, anysprint) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SPRINT_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/math/sprint_add/${id}/`,
            anysprint,
            config
        )

        dispatch({
            type: SPRINT_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SPRINT_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const lastSprint_a = () => async (dispatch, getState) => {
    try {
        dispatch({ type: LAST_SPRINT_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()
        
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/math/lastsprint/`, config
            )
        dispatch({
            type: LAST_SPRINT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LAST_SPRINT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const randomQuestion_a = (keyword='') => async (dispatch) => {
    try {
        dispatch({ type: RANDOM_QUESTION_REQUEST })

        const { data } = await axios.get(
            `/api/math/randomquestion/${keyword}`, 
            )
        dispatch({
            type: RANDOM_QUESTION_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RANDOM_QUESTION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const answerAdd_a = (id, anyanswer) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ANSWER_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/math/answer_add/${id}/`,
            anyanswer,
            config
        )

        dispatch({
            type: ANSWER_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ANSWER_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const lastAnswer_a = () => async (dispatch, getState) => {
    try {
        dispatch({ type: LAST_ANSWER_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()
        
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/math/lastanswer/`, config
            )
        dispatch({
            type: LAST_ANSWER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LAST_ANSWER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const answerNotFirstAdd_a = (anyanswer) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ANSWER_NF_ADD_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/math/answer_nf_add/`,
            anyanswer,
            config
        )

        dispatch({
            type: ANSWER_NF_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ANSWER_NF_ADD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const sprintsUser_a = (keyword='') => async (dispatch, getState) => {
    try {
    dispatch({ type: SPRINTS_USER_REQUEST })

    const {
        userLogin: { userInfo },
    } = getState()
    
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    
    const { data } = await axios.get(
        `/api/math/r${keyword}/`,
        // {},
        config
    )

    dispatch({
        type: SPRINTS_USER_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: SPRINTS_USER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const sprintsTopicUser_a = (id) => async (dispatch, getState) => {
    try {
    dispatch({ type: SPRINTS_TOPIC_USER_REQUEST })

    const {
        userLogin: { userInfo },
    } = getState()
    
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    
    const { data } = await axios.get(
        `/api/math/to/${id}/`,
        // {},
        config
    )

    dispatch({
        type: SPRINTS_TOPIC_USER_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: SPRINTS_TOPIC_USER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const sprint_a = (id_) => async (dispatch, getState) => {
    try {
        dispatch({ type: SPRINT_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()
        
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/api/math/s/${id_}/`, config
            )
        dispatch({
            type: SPRINT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SPRINT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const video_a = (id) => async (dispatch, getState) => {
    try {
    dispatch({ type: VIDEO_REQUEST })

    // const {
    //     userLogin: { userInfo },
    // } = getState()
    
    // const config = {
    //     headers: {
    //         'Content-type': 'application/json',
    //         Authorization: `Bearer ${userInfo.token}`
    //     }
    // }
    
    const { data } = await axios.get(
        `/api/math/to_v/${id}/`,
        // {},
        // config
    )

    dispatch({
        type: VIDEO_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: VIDEO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const grade_a = (id_) => async (dispatch) => {
    try {
        dispatch({ type: GRADE_REQUEST })

        const { data } = await axios.get(
            `/api/math/grade/${id_}/`,
            )
        dispatch({
            type: GRADE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GRADE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const screen_grades_sprints_a = () => async (dispatch, getState) => {
    try {
    dispatch({ type: SCREEN_GRADES_SPRINTS_REQUEST })

    const {
        userLogin: { userInfo },
    } = getState()
    
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    
    const { data } = await axios.get(
        `/api/math/screen_grades_sprints/`,
        // {},
        config
    )

    dispatch({
        type: SCREEN_GRADES_SPRINTS_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: SCREEN_GRADES_SPRINTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const screen_topics_sprints_a = (id_) => async (dispatch, getState) => {
    try {
    dispatch({ type: SCREEN_TOPICS_SPRINTS_REQUEST })

    const {
        userLogin: { userInfo },
    } = getState()
    
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    
    const { data } = await axios.get(
        `/api/math/screen_topics_sprints/${id_}/`,
        // {},
        config
    )

    dispatch({
        type: SCREEN_TOPICS_SPRINTS_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: SCREEN_TOPICS_SPRINTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const screen_all_topics_sprints_a = () => async (dispatch, getState) => {
    try {
    dispatch({ type: SCREEN_ALL_TOPICS_SPRINTS_REQUEST })

    const {
        userLogin: { userInfo },
    } = getState()
    
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    
    const { data } = await axios.get(
        `/api/math/screen_all_topics_sprints/`,
        // {},
        config
    )

    dispatch({
        type: SCREEN_ALL_TOPICS_SPRINTS_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: SCREEN_ALL_TOPICS_SPRINTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}




export const pi1_a = (anylog1) => async (dispatch) => {
    try {
        dispatch({ type: PI1_REQUEST })

        const { data } = await axios.post(
            `https://api.paybox.money/init_payment.php`,
            anylog1,
        )

        dispatch({
            type: PI1_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PI1_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const pw_change_request_a = (anylog) => async (dispatch) => {
    try {
        dispatch({
            type: PW_CHANGE_REQUEST_REQUEST
        })

        // const {
        //     userLogin: { userInfo },
        // } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
        //         Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/math/pw_change_request/`,
            anylog,
            config
        )

        dispatch({
            type: PW_CHANGE_REQUEST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PW_CHANGE_REQUEST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const new_pw_a = (request_number) => async (dispatch) => {
    try {
        dispatch({
            type: NEW_PW_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            `/api/math/pw/`,
            request_number,
            config
        )

        dispatch({
            type: NEW_PW_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_PW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const stat_a = () => async (dispatch, getState) => {
    try {
    dispatch({ type: STAT_REQUEST })

    const {
        userLogin: { userInfo },
    } = getState()
    
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    
    const { data } = await axios.get(
        `/api/math/stat/`,
        // {},
        config
    )

    dispatch({
        type: STAT_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: STAT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const process_grade_a = (anyanswer) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROCESS_GRADE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/math/process_grade/`,
            anyanswer,
            config
        )

        dispatch({
            type: PROCESS_GRADE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PROCESS_GRADE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const stat_easy_a = () => async (dispatch, getState) => {
    try {
    dispatch({ type: STAT_EASY_REQUEST })

    const {
        userLogin: { userInfo },
    } = getState()
    
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }
    
    const { data } = await axios.get(
        `/api/math/stat_easy/`,
        // {},
        config
    )

    dispatch({
        type: STAT_EASY_SUCCESS,
        payload: data
    })
    } catch (error) {
        dispatch({
            type: STAT_EASY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const user_activate_a = (anyanswer) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_ACTIVATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/math/user_activate/`,
            anyanswer,
            config
        )

        dispatch({
            type: USER_ACTIVATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: USER_ACTIVATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
