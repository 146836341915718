import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Nav, Row, Button, Navbar, NavDropdown, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {useHistory} from "react-router-dom"
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'


function Navigation({ _step1, _step2, _step3, _step4, match }) {

    const topic_ = useSelector(state => state.topic_r)
    const { error_topic, loading_topic, topic_topic, topic_page, topic_pages } = topic_

    let history=useHistory(); 

    const goBack = () => {
        history.goBack()
    }
    console.log(history)

    const goSearch = () => {
        history.push('/search')
    }

    const userDetails = useSelector(state => state.userDetails)
    const { user_from_reducer } = userDetails
    const dispatch = useDispatch()
    const logoutHandler = () => {
        dispatch(logout())
    }

    return (
<Row>
<div className="ml-1"><Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-1">
        <i className="fas fa-chevron-left"/> Назад
</Button></div>

<div>
<ol class="breadcrumb">
    { _step1 ? (
        <li class="breadcrumb-item"><Link to={`/d/`}>Классы</Link></li>
    ):(<p> </p>) }

    { (_step2 && topic_topic.grade) ? (
        <li class="breadcrumb-item"><Link to={`/g/${topic_topic.grade._id}`}>
            {topic_topic.grade.grade} класс
        </Link></li>
    ):(<p> </p>) }

    { (_step3 && topic_topic) ? (
        <li class="breadcrumb-item"><Link to={`/to/${topic_topic._id}/?t=3`}>            
            {topic_topic.topic}
        </Link></li>
    ):(<p> </p>) }

    { _step4 ? (
        <li class="breadcrumb-item"><Link to={`/products/s3/${1}`}>            
4
        </Link></li>
    ):(<p> </p>) }
    
{/* <li class="breadcrumb-item active">sdelat</li> */}
    
</ol>
</div>
        </Row>
        
    )
}

export default Navigation

