import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { logMathAdd_a, screen_all_topics_sprints_a, topics_a, stat_easy_a, grade_a, grades_a } from '../../actions/mathActions'
import { GeistProvider, CssBaseline, Modal, useModal } from "@geist-ui/react"
import { Card, Text, Spinner, Progress } from "@geist-ui/react"
import Moment from 'react-moment'
import moment from 'moment'
import localization from 'moment/locale/ru'
import { ToastContainer, toast, Zoom, Bounce, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

toast.configure()

function MyProgress_Screen({ history, match }) {

    const dispatch = useDispatch()

    const stat_easy_ = useSelector(state => state.stat_easy_r)
    const { stat_easy_sprints, stat_easy_fives, stat_easy_topics, stat_easy_grades, stat_easy_mastery, stat_easy_sprints_today } = stat_easy_

    const notify = () => { toast.success(
        <div>Выберите нужную тему</div>
        ,
      { 
        position: "bottom-right",
        autoClose: 4000, 
        delay: 1000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 21,
        theme: "colored",
        transition: Slide
        //  autoClose: false
      } ) }

    useEffect(() => { 
        if (stat_easy_sprints_today==0 && false ) {
            dispatch(notify)
        }
    }, [])

    useEffect(() => {
        if (!stat_easy_sprints || stat_easy_sprints_today==0) {
            dispatch(stat_easy_a())
        }
    }, [dispatch])

    useEffect(() => {
        if (!grades_grades || grades_grades.length == 0  ) {
            dispatch(grades_a())
        }
    }, [])


    // let keyword = history.location.search
    // const [keyword_from_search, setKeyword_from_search] = useState('')

    moment.updateLocale('ru', localization)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const screen_all_topics_sprints_ = useSelector(state => state.screen_all_topics_sprints_r)
    const { error_screen_all_topics_sprints, loading_screen_all_topics_sprints, screen_all_topics_sprints, topics_list } = screen_all_topics_sprints_

    const answerNotFirstAdd_ = useSelector(state => state.answerNotFirstAdd_r)
    const { success_answer_add } = answerNotFirstAdd_

    const topics_ = useSelector(state => state.topics_r)
    const { error_topics, loading_topics, topics_topics } = topics_

    const grades_ = useSelector(state => state.grades_r)
    const { error_grades, loading_grades, grades_grades } = grades_

    // let history_from_search = useHistory()
    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        dispatch(screen_all_topics_sprints_a())  // надо сделать без матч, а на все
        dispatch(topics_a())
    }, [dispatch, match.params.id])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const goBack = () => { history.goBack() }

    const { visible, setVisible, bindings } = useModal()

    return (
        <Container className="m-0 p-0">
        <GeistProvider>
{/*
        <CssBaseline />
*/}

{/* Breadcrumb */} 
<ol class="breadcrumb" style={{ backgroundColor: '', padding: 0, margin: '5px' }} >
<li class="breadcrumb-item"><Link onClick={goBack} style={{ color: "#2a9df4" }}><i className="fas fa-chevron-left" style={{ marginRight: 3 }}></i>Назад</Link></li>
<li class="breadcrumb-item"><Link to={`/db/`} style={{ color: "#2a9df4" }}>Центр</Link></li>
</ol> {/* breadcrumb-end */}


<h4 style={{ fontWeight: 'bold', marginTop: 30, marginBottom: 30, marginLeft: 10 }}>Мой прогресс по темам</h4>

<div style={{ marginBottom: 30, marginLeft: 12, fontSize: 12 }}>
    <div style={{ marginTop: 15, marginBottom: 5 }}>
        Карточки тем (ниже) имеют цвета. Цвета означют следующее:
    </div>
    <div>
        <div style={{ marginBottom: 1 }}>
            <span style={{ border: '1px solid grey', backgroundColor: 'white', color: 'white', marginRight: 3 }}>
                <span>-...</span>
            </span>
            <span>
                - тесты по теме не проходились
            </span>
        </div>
    </div>
    <div>
        <div>
            <span style={{ backgroundColor: '#ffa5a1', color: '#ffa5a1', marginRight: 3 }}>
                <span>.....</span>
            </span>
            <span>
                - средняя оценка по тестам темы меньше 4
            </span>
        </div>
    </div>
    <div>
        <div>
            <span style={{ backgroundColor: '#d8d8d8', color: '#d8d8d8', marginRight: 3 }}>
                <span>.....</span>
            </span>
            <span>
                - пройдено менее 3 тестов по теме
            </span>
        </div>
    </div>
    <div>
        <div>
            <span style={{ backgroundColor: '#ffd858', color: '#ffd858', marginRight: 3 }}>
                <span>.....</span>
            </span>
            <span>
                - средняя оценка по тестам темы между 4 и 4.5 (включительно)
            </span>
        </div>
    </div>
    <div>
        <div>
            <span style={{ backgroundColor: '#99edc3', color: '#99edc3', marginRight: 3 }}>
                <span>.....</span>
            </span>
            <span>
                - средняя оценка  по тестам темы больше 4.5
            </span>
        </div>
    </div>
</div>


        {loading_screen_all_topics_sprints || loading_topics ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
            : error_screen_all_topics_sprints ? <Message variant='danger'>{error_screen_all_topics_sprints}</Message>
                : <div>

<Container className="mx-0 my-0 p-0" style={{ backgroundColor: 'white' }}>

{/* FREE USER - S   to={`/to/${x._id}/?t=3`}       */}

{grades_grades.filter((grades_grades) => grades_grades.grade < 12).map(xx => (
<Row className="m-0 p-1">
<div style={{ fontWeight: 'bold' }}>{xx.grade} класс</div>

{topics_topics.filter((topics_topics) => topics_topics.grade._id === xx._id).map( (x, i) => (
    <Col className="m-0 p-0" key={x._id} xxs={4} xs={4} sm={4} md={2} lg={2} xl={2}>
    <Link onClick={() => setVisible(true)} style={{ textDecoration: 'none' }} className="m-0 p-0">
    <div className="m-1 p-0" style={{ borderRadius: 10 }}>

    {
        topics_list.includes(x._id) ? 
        <div>
        
        { screen_all_topics_sprints.filter((screen_all_topics_sprints) => screen_all_topics_sprints.topic_object == x._id).map ( z => (
            <div key={z._id}>
            <Card shadow style={{ boxShadow: "0 0 0px 0px #cec7c759", backgroundColor: 
            z.w_score < 4 ? '#ffa5a1' :
            z.w_sprints < 3 ? '#d8d8d8' :
            z.w_score <= 4.5 ? '#ffd858' :
            '#99edc3' }}>
            <div style={{ margin: '-20px', padding: '0px' }}>
            
            <div className="m-1 p-0" style={{ fontSize: 10, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
            <div><span>{i+1}.             
            { x.topic.length > 25 ?
                x.topic.substring(0, 25) :
                x.topic
            }
            { x.topic.length > 25 ?
                <span>...</span>
                :
                <span></span>
            }
            </span></div></div>
            
            <div>
            <hr style={{ color: "grey" }} className="m-0 p-0" />
            
            <div className="m-0 p-1" style={{ fontSize: 8, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
            
            <div className='p-0 m-0'>
                <span className="m-0 p-0">
                    <span>
                        <i className="far fa-star" style={{ color: '#2a9df4', marginLeft: 3, marginRight: 1 }}></i>
                        <span></span>
                        <span>
                            {(z.w_score*1).toFixed(1)}
                        </span>
                    </span> 
                </span>
            
                <span className="m-0 p-0">
                    <span >
                        <span style={{ color: '#2a9df4', marginLeft: 3, marginRight: 1, fontWeight: 'bold' }}>П</span>{(z.w_percentile*1).toFixed(0)}%
                    </span>
                </span>
            
                <span style={{ marginTop: 10, fontSize: '', lineHeight : 1, color: "#000000" }}>
                    <i className="fas fa-running" style={{ color: '#2a9df4', marginLeft: 3, marginRight: 1 }}></i>
                    <span></span>{z.w_sprints} 
                </span>
            
                <span style={{ marginTop: 10, fontSize: '', lineHeight : 1, color: "#000000" }}>
                    <i className="fas fa-stopwatch" style={{ color: '#2a9df4', marginLeft: 3, marginRight: 1 }}></i>
                    <span></span>{(z.w_time/1000).toFixed(1)}
                </span>
            
                <span>
                        <span style={{ color: '#2a9df4', marginLeft: 3, marginRight: 1, fontWeight: 'bold' }}>М</span>{z.w_number_of_5}
                </span>

                {/*
                <span style={{ marginTop: 12, marginLeft: -12 }}>
                    <Progress type="secondary" style={{ color: '#2a9df4', marginTop: '30px', paddingTop: '30px' }} value={z.w_number_of_5 * 100 / 10}  />
                </span>
                */}
                        
            </div>
            
                </div> </div></div></Card></div>
            ))}
        
        </div>
        :
        <div>
            <Card shadow style={{ boxShadow: "0 0 0px 0px #cec7c759", backgroundColor: 'white', border: '1px solid grey' }}><div style={{ margin: '-20px', padding: '0px' }}>

                <div className="m-1 p-0" style={{ fontSize: 10, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", }}>
                <div><span>{i+1}.<span> </span> 
                
                { x.topic.length > 25 ?
                    x.topic.substring(0, 25) :
                    x.topic
                }
                { x.topic.length > 25 ?
                    <span>...</span>
                    :
                    <span></span>
                }
                
                </span></div></div>

                <div>
                <hr style={{ color: "grey" }} className="m-0 p-0" />

                <div className="m-0 p-1" style={{ fontSize: 8, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", }}>

                <div className='p-0 m-0'>
                    <span className="m-0 p-0">
                        <span>
                            <i className="far fa-star" style={{ color: '#2a9df4', marginLeft: 0, marginRight: 1 }}> </i>
                            <span></span> 
                            <span>0</span>
                        </span> 
                    </span>

                    <span >
                        <span >
                            <span style={{ color: '#2a9df4', fontWeight: 'bold', marginLeft: 3, marginRight: 1 }}>П</span>0%
                        </span>
                    </span>

                    <span style={{ marginTop: 10, fontSize: '', lineHeight : 1, color: "#000000" }}>
                        <i className="fas fa-running" style={{ color: '#2a9df4', marginLeft: 3, marginRight: 1 }}> </i>
                        <span></span>0
                    </span>

                    <span style={{ marginTop: 10, fontSize: '', lineHeight : 1, color: "#000000" }}>
                        <i className="fas fa-stopwatch" style={{ color: '#2a9df4', marginLeft: 3, marginRight: 1 }}> </i>
                        <span></span>0
                    </span>

                    <span>
                        <span style={{ color: '#2a9df4', fontWeight: 'bold', marginLeft: 3, marginRight: 1 }}>М</span>0
                    </span>

                </div>

            </div> </div></div></Card>

        </div>
    }

</div></Link>
    </Col>
))}

{/* Modal - Start */}
<div style={{ textAlign: 'center', marginTop: 30 }}>
    <Modal {...bindings}>
        <Modal.Subtitle><strong>Доступ</strong></Modal.Subtitle>
            <Modal.Content>
                <p>
                    Для получения доступа к платному контенту, пожалуйста, пройдите по 
                    <span> </span>
                    <Link to={`/n/n6`} className='mx-0'>
                        <span style={{ color: 'blue' }}>этой ссылке</span>
                    </Link>
                </p>
            </Modal.Content>
        <Modal.Action passive onClick={() => setVisible(false)}><strong>Закрыть</strong></Modal.Action>
    </Modal>
</div>
{/* Modal - End */}

</Row>))}



{/* FREE USER - E */}

</Container>
</div> }
</GeistProvider>
        </Container>
    )
}

export default MyProgress_Screen
