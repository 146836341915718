import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { logMathAdd_a, screen_topics_sprints_a, topicsGrade_a, stat_easy_a } from '../../actions/mathActions'
import { GeistProvider, CssBaseline, Modal, useModal } from "@geist-ui/react"
import { Card, Text, Spinner, Progress } from "@geist-ui/react"
import Moment from 'react-moment'
import moment from 'moment'
import localization from 'moment/locale/ru'
import { ToastContainer, toast, Zoom, Bounce, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getUserDetails } from '../../actions/userActions'

toast.configure()

function Grade_Screen({ history, match }) {

    const dispatch = useDispatch()

    const stat_easy_ = useSelector(state => state.stat_easy_r)
    const { stat_easy_sprints, stat_easy_fives, stat_easy_topics, stat_easy_grades, stat_easy_mastery, stat_easy_sprints_today } = stat_easy_

    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails

    const notify = () => { toast.success(
        <div>Выберите нужную тему</div>
        ,
      { 
        position: "bottom-right",
        autoClose: 4000, 
        delay: 1000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 21,
        theme: "colored",
        transition: Slide
        //  autoClose: false
      } ) }

    useEffect(() => { 
        if (stat_easy_sprints_today==0) {
            dispatch(notify)
        }
    }, [])

    useEffect(() => {
        if (!stat_easy_sprints || stat_easy_sprints_today==0) {
            dispatch(stat_easy_a())
        }
    }, [dispatch])

    // useEffect(() => {
    //         dispatch(getUserDetails('profile'))
    // }, [])

    // let keyword = history.location.search
    // const [keyword_from_search, setKeyword_from_search] = useState('')

    moment.updateLocale('ru', localization)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const screen_topics_sprints_ = useSelector(state => state.screen_topics_sprints_r)
    const { error_screen_topics_sprints, loading_screen_topics_sprints, screen_topics_sprints, grade } = screen_topics_sprints_

    const answerNotFirstAdd_ = useSelector(state => state.answerNotFirstAdd_r)
    const { success_answer_add } = answerNotFirstAdd_

    const topics_ = useSelector(state => state.topicsGrade_r)
    const { error_topics_grade , loading_topics_grade , topics_grade } = topics_

    // let history_from_search = useHistory()
    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        dispatch(screen_topics_sprints_a(match.params.id))
        if (grade.grade != match.params.id) {
            dispatch(topicsGrade_a(match.params.id))
        }
    // }, [success_answer_add, dispatch, match.params.id])
    }, [dispatch, match.params.id])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const goBack = () => { history.goBack() }

    const { visible, setVisible, bindings } = useModal()

    // alert(grade.grade)
    // alert(match.params.id)

    return (
        <Container className="m-0 p-0">
        <GeistProvider>
{/*
        <CssBaseline />
*/}


{/* Breadcrumb */} 
<ol class="breadcrumb" style={{ backgroundColor: '', padding: 0, margin: '5px' }} >
<li class="breadcrumb-item"><Link onClick={goBack} style={{ color: "#2a9df4" }}><i className="fas fa-chevron-left" style={{ marginRight: 3 }}></i>Назад</Link></li>
<li class="breadcrumb-item"><Link to={`/d/`} style={{ color: "#2a9df4" }}>Все классы</Link></li>
{ (grade) ? (
    <li class="breadcrumb-item"><Link to={`/g/${grade._id}`} style={{ color: "#2a9df4" }} >
        {grade.grade} класс
    </Link></li>    ):(<p> </p>) }
</ol> {/* breadcrumb-end */}


        { (grade.grade != match.params.id && grade.grade != undefined) || loading_topics_grade ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
            : error_screen_topics_sprints ? <Message variant='danger'>{error_screen_topics_sprints}</Message>
                : <div>


<Container className="mx-0 my-0 p-0" style={{ backgroundColor: 'white' }}>

<div className="m-1 mt-4 p-0">Темы:</div>



{/* PAID USER - S */}
{true && <div>
    {true && <div>
        {(true)
            ?

<Row>
{topics_grade.map( (x, i) => (
    <Col className="m-0 p-0" key={x._id} xxs={12} xs={12} sm={12} md={6} lg={4} xl={4}>
    <Link to={`/to/${x._id}/?t=3`} style={{ textDecoration: 'none' }} className="m-0 p-0">
    <div className="m-4 p-0" style={{ borderRadius: 20 }}>
<Card shadow style={{ boxShadow: "0 0 10px 5px #cec7c759", backgroundColor: '#f1f8ff' }}><div style={{ margin: '-20px', padding: '0px' }}>

<div className="m-3 p-0" style={{ fontSize: 16, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
<strong><span>{i+1}. {x.topic}</span></strong></div>

<div className="m-2 my-3 p-0" style={{ fontSize: 14, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
    <Link to={`/to/${x._id}/?t=3`} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Тест</Link>
    <Link to={`/to/${x._id}/?t=1`} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Видео</Link>
    <Link to={`/to/${x._id}/?t=3`} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Результаты</Link>
    <Link to={`/to/${x._id}/?t=4`} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Прогресс</Link>
</div>


{ screen_topics_sprints.filter((screen_topics_sprints) => screen_topics_sprints.topic_object == x._id).map ( z => (
<div key={z._id}>
<span>
<hr style={{ color: "grey" }} className="m-0 p-0" />
<div className="m-2 mx-3 p-0">
<strong style={{ fontSize: 14, lineHeight : 1, color: "#000000" }}>
Ваши результаты:</strong>
<Col style={{ paddingLeft: 12 }}>

<Row className='p-0 m-0'>
    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} >
        <div style={{ display: '', width: '100%', hight: '100%' }} >
            <div style={{ marginTop: 9, marginLeft: -12, fontSize: 14, lineHeight : 1, color: "#000000", width: '100%' }}>
            <i className="far fa-star" style={{ color: '#2a9df4' }}> </i>
            <span> </span> <strong>{(z.w_score*1).toFixed(1)}</strong></div> 
        </div>
    </Col>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '5px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
            <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                {(z.w_percentile*1).toFixed(0)}%
                <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
            </Link>
        </div>
    </Col>
</Row>

    <div style={{ marginTop: 10, fontSize: 14, lineHeight : 1, color: "#000000" }}>
        <i className="fas fa-running" style={{ color: '#2a9df4' }}> </i>
        <span> </span><strong> {z.w_sprints}</strong> тестов 
    </div>

    <div style={{ marginTop: 10, fontSize: 14, lineHeight : 1, color: "#000000" }}>
        <i className="fas fa-stopwatch" style={{ color: '#2a9df4' }}> </i>
        <span> </span> Среднее время: <strong>{(z.w_time/1000).toFixed(1)}</strong> сек
    </div>


    <div style={{ display: '' }}>
    <div style={{ marginTop: 10, fontSize: 14, lineHeight : 1, color: "#000000" }}>

{/*
    <i className="fas fa-star" style={{ color: '#2a9df4', fontSize: '7px' }}> </i>
        <span style={{ color: "#2a9df4", fontWeight: 'bold' }}>5 </span>
*/}
<div style={{ marginLeft: -12, marginTop: 16 }}>
        <strong>Уровень мастерства: </strong><strong style={{ marginLeft: 3 }}> {z.w_number_of_5} / 10</strong> 

        <Link to={`/n/n3`} style={{ marginLeft: 3, textDecoration: 'none' }}>
            <span  style={{ marginTop: 10, marginBottom: 10, fontSize: 12, lineHeight : 1, color: "grey", width: '100%', fontWeight: 'bold' }}> Что это</span>
            <i className="far fa-question-circle" style={{ color: 'grey', marginLeft: 2 }} > </i> 
        </Link>
</div>


    </div>
        <Col>
            <div style={{ marginTop: 12, marginLeft: -12 }}>
                <Progress type="secondary" style={{ color: '#2a9df4', marginTop: '30px', paddingTop: '30px' }} value={z.w_number_of_5 * 100 / 10}  />
            </div>
        </Col>
    </div>

    <div style={{ lineHeight : 1, textAlign: 'right', marginBottom: '10px', marginTop: '10px' }}>
        <span style={{ textAlign: 'right', fontSize: 12, marginRight: '10px', fontStyle: 'italic' }}>Последний тест: <Moment fromNow>{z.updatedAt}</Moment></span>
    </div>


    </Col> </div> </span>
    </div>
))}

</div></Card></div></Link>
    </Col>
))}
{/* PAID USER - E */}
</Row>

:

<div>
{/* FREE USER - S */}
<Row>
{topics_grade.map( (x, i) => (
    <Col className="m-0 p-0" key={x._id} xxs={12} xs={12} sm={12} md={6} lg={4} xl={4}>
    <Link onClick={() => setVisible(true)} style={{ textDecoration: 'none' }} className="m-0 p-0">
    <div className="m-4 p-0" style={{ borderRadius: 20 }}>
<Card shadow style={{ boxShadow: "0 0 10px 5px #cec7c759", backgroundColor: '#f1f8ff' }}><div style={{ margin: '-20px', padding: '0px' }}>

<div className="m-3 p-0" style={{ fontSize: 16, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
<strong><span>{i+1}. {x.topic}</span></strong></div>

<div className="m-2 my-3 p-0" style={{ fontSize: 14, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
    <Link onClick={() => setVisible(true)} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Тест</Link>
    <Link onClick={() => setVisible(true)} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Видео</Link>
    <Link onClick={() => setVisible(true)} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Результаты</Link>
    <Link onClick={() => setVisible(true)} style={{ color: "#2a9df4" }} className="m-0 mx-2 p-0">Прогресс</Link>
</div>


{ screen_topics_sprints.filter((screen_topics_sprints) => screen_topics_sprints.topic_object == x._id).map ( z => (
<div key={z._id}>
<span>
<hr style={{ color: "grey" }} className="m-0 p-0" />
<div className="m-2 mx-3 p-0">
<strong style={{ fontSize: 14, lineHeight : 1, color: "#000000" }}>
Ваши результаты:</strong>
<Col style={{ paddingLeft: 12 }}>

<Row className='p-0 m-0'>
    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} >
        <div style={{ display: '', width: '100%', hight: '100%' }} >
            <div style={{ marginTop: 9, marginLeft: -12, fontSize: 14, lineHeight : 1, color: "#000000", width: '100%' }}>
            <i className="far fa-star" style={{ color: '#2a9df4' }}> </i>
            <span> </span> <strong>{(z.w_score*1).toFixed(1)}</strong></div> 
        </div>
    </Col>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '5px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
            <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                {(z.w_percentile*1).toFixed(0)}%
                <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
            </Link>
        </div>
    </Col>
</Row>

    <div style={{ marginTop: 10, fontSize: 14, lineHeight : 1, color: "#000000" }}>
        <i className="fas fa-running" style={{ color: '#2a9df4' }}> </i>
        <span> </span><strong> {z.w_sprints}</strong> тестов 
    </div>

    <div style={{ marginTop: 10, fontSize: 14, lineHeight : 1, color: "#000000" }}>
        <i className="fas fa-stopwatch" style={{ color: '#2a9df4' }}> </i>
        <span> </span> Среднее время: <strong>{(z.w_time/1000).toFixed(1)}</strong> сек
    </div>


    <div style={{ display: '' }}>
    <div style={{ marginTop: 10, fontSize: 14, lineHeight : 1, color: "#000000" }}>

{/*
    <i className="fas fa-star" style={{ color: '#2a9df4', fontSize: '7px' }}> </i>
        <span style={{ color: "#2a9df4", fontWeight: 'bold' }}>5 </span>
*/}
<div style={{ marginLeft: -12, marginTop: 16 }}>
        <strong>Уровень мастерства: </strong><strong style={{ marginLeft: 3 }}> {z.w_number_of_5} / 10</strong> 

        <Link to={`/n/n3`} style={{ marginLeft: 3, textDecoration: 'none' }}>
            <span  style={{ marginTop: 10, marginBottom: 10, fontSize: 12, lineHeight : 1, color: "grey", width: '100%', fontWeight: 'bold' }}> Что это</span>
            <i className="far fa-question-circle" style={{ color: 'grey', marginLeft: 2 }} > </i> 
        </Link>
</div>


    </div>
        <Col>
            <div style={{ marginTop: 12, marginLeft: -12 }}>
                <Progress type="secondary" style={{ color: '#2a9df4', marginTop: '30px', paddingTop: '30px' }} value={z.w_number_of_5 * 100 / 10}  />
            </div>
        </Col>
    </div>

    <div style={{ lineHeight : 1, textAlign: 'right', marginBottom: '10px', marginTop: '10px' }}>
        <span style={{ textAlign: 'right', fontSize: 12, marginRight: '10px', fontStyle: 'italic' }}>Последний тест: <Moment fromNow>{z.updatedAt}</Moment></span>
    </div>


    </Col> </div> </span>
    </div>
))}

</div></Card></div></Link>
    </Col>
))}
</Row>
{/* PAID USER - E */}

{/* Modal - Start */}
<div style={{ textAlign: 'center', marginTop: 30 }}>
    <Modal {...bindings}>
        <Modal.Subtitle><strong>Доступ</strong></Modal.Subtitle>
            <Modal.Content>
                <p>
                    Для получения доступа к платному контенту, пожалуйста, пройдите по 
                    <span> </span>
                    <Link to={`/n/n6`} className='mx-0'>
                        <span style={{ color: 'blue' }}>этой ссылке</span>
                    </Link>
                </p>
            </Modal.Content>
        <Modal.Action passive onClick={() => setVisible(false)}><strong>Закрыть</strong></Modal.Action>
    </Modal>
</div>
{/* Modal - End */}
</div>

}
</div> }
</div>}


</Container>
</div> }
</GeistProvider>
        </Container>
    )
}

export default Grade_Screen
