import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Nav, Row, Button, Navbar, NavDropdown, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {useHistory} from "react-router-dom"
import SearchBox from './SearchBox'
import { logout, getUserDetails } from '../actions/userActions'
import { GeistProvider, CssBaseline } from "@geist-ui/react"
import { Modal, useModal } from "@geist-ui/react"
import ReactPlayer from "react-player"


function Menu2 ({ _step1, _step2, _step3, _step4 }) {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails


    let history=useHistory(); 

    const goBack = () => {
        history.goBack()
    }

    const goSearch = () => {
        history.push('/k')
    }

    const dispatch = useDispatch()
    
    const logoutHandler = () => {
        history.push('/')
        dispatch(logout())
    }

    // later should be switched off !!! 
    useEffect(() => {
        // if (!user.profile) { 
            dispatch(getUserDetails('profile'))
        // }
    }, [dispatch])

    const { visible, setVisible, bindings } = useModal()


    return (
<Row className="d-xs-block d-sm-block d-md-none d-lg-none d-xl-none m-0 p-0" >

<div className="fixed-bottom m-0 p-0" >
<div class="btn-group py-0 my-0" role="group" aria-label="Basic example" style={{flexDirection: 'row', width:'100%', flex:1}} >

{/*
<button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }} >
    <LinkContainer to='/' className="py-0 my-0">
        <Nav.Link ><i className="fas fa-home" ></i><span> </span></Nav.Link>
    </LinkContainer>
</button>
*/}

{(!userInfo && false) &&
    <div>
<Button auto onClick={() => setVisible(true)}><span style={{ lineHeight: 1, fontSize: 12 }}>Видео <br/>о нас</span></Button>
      <Modal {...bindings}>
        <Modal.Subtitle><strong>Видео о нас</strong></Modal.Subtitle>
        <Modal.Content>
          <p>Узнайте за 5 минут:</p>
          <p style={{ marginLeft: 20 }}>Как улучшить <strong> математические навыки</strong> благодаря <strong>Matematika Plus</strong> </p>
            <div>
                <div>
                    <ReactPlayer 
                        url='https://vimeo.com/600168490' 
                        width='250px'
                        height='188px'
                        style={{ margin: 'auto' }}
                    />
                </div>
            </div>
        </Modal.Content>
        <Modal.Action passive onClick={() => setVisible(false)}><strong>Закрыть</strong></Modal.Action>
      </Modal>
    </div>
}

{(user && false) && <span>
    {user.profile && <span>
        {((!user.profile.act || false) && user.profile.showbuy)
            &&
            <button type="button" className="btn btn-ouline-primary btn-sm py-2 my-0" style={{ backgroundColor: '#2a9df4' }}>
                <LinkContainer to='/n/n6/' className="py-0 my-0 mt-2">
                    <Nav.Link className="p-0 m-0"><i className="far fa-credit-card" style={{ color: 'white' }} ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0', color: 'white' }}>Купить доступ</span></Nav.Link>
                </LinkContainer>
            </button>
        }
    </span> }
</span>}


{!userInfo &&
    <button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
        <LinkContainer to='/contacts/' className="py-0 my-0 mt-2">
            <Nav.Link className="p-0 m-0"><i className="far fa-comments" ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Контакты</span></Nav.Link>
        </LinkContainer>
    </button>
    }
    

{userInfo &&
    <button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
        <LinkContainer to='/db/' className="py-0 my-0 mt-2">
            <Nav.Link className="p-0 m-0"><i className="fas fa-network-wired" ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Центр</span></Nav.Link>
        </LinkContainer>
    </button>
    }
    
{(userInfo && false) &&
<button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
    <LinkContainer to='/d/' className="py-0 my-0 mt-2">
        <Nav.Link className="p-0 m-0"><i className="fas fa-list" ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Классы</span></Nav.Link>
    </LinkContainer>
</button>
}

{(userInfo && false) &&
<button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
    <LinkContainer to='/r/' className="py-0 my-0 mt-2">
        <Nav.Link ><i className="fas fa-poll-h" ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Результаты</span></Nav.Link>
    </LinkContainer>
</button>
}

{userInfo &&
<button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
    <LinkContainer to='/profile' className="py-0 my-0 mt-2">
        <Nav.Link ><i className="fas fa-address-card" ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Профайл</span></Nav.Link>
    </LinkContainer>
</button>
}

{!userInfo &&
    <button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
        <LinkContainer to='/register/' className="py-0 my-0 mt-2">
            <Nav.Link className="p-0 m-0"><i className="far fa-user" ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Зарегистрироваться</span></Nav.Link>
        </LinkContainer>
    </button>
    }

    { userInfo ? (
        <button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
            <NavDropdown className="py-0 my-0 mt-2" title={<span className="py-0 my-0"><i className="far fa-user my-0 py-0" ></i> <br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Меню</span></span>} id='username'>

            <LinkContainer to='/db/' className="py-0 my-0">
    <NavDropdown.Item><i className="far fa-" style={{ color: '#2a9df4' }}></i> Центр</NavDropdown.Item>
</LinkContainer>
<LinkContainer to='/d/' className="py-0 my-0">
    <NavDropdown.Item><i className="far fa-" style={{ color: '#2a9df4' }}></i> Классы</NavDropdown.Item>
</LinkContainer>
<LinkContainer to='/profile/' className="py-0 my-0">
    <NavDropdown.Item><i className="far fa-" style={{ color: '#2a9df4' }}></i> Профайл</NavDropdown.Item>
</LinkContainer>
<LinkContainer to='/map/' className="py-0 my-0">
    <NavDropdown.Item><i className="far fa-" style={{ color: '#2a9df4' }}></i> Программа</NavDropdown.Item>
</LinkContainer>
<LinkContainer to='/mp/' className="py-0 my-0">
    <NavDropdown.Item><i className="far fa-" style={{ color: '#2a9df4' }}></i> Усовение тем</NavDropdown.Item>
</LinkContainer>
<LinkContainer to='/r/' className="py-0 my-0">
    <NavDropdown.Item><i className="far fa-" style={{ color: '#2a9df4' }}></i> Пройденные тесты</NavDropdown.Item>
</LinkContainer>
<LinkContainer to='/i/' className="py-0 my-0">
    <NavDropdown.Item><i className="far fa-" style={{ color: '#2a9df4' }}></i> Как пользоваться и с чего начать?</NavDropdown.Item>
</LinkContainer>

<NavDropdown.Item onClick={logoutHandler}><i className="fas fa-sign-out-alt" style={{ color: '#2a9df4' }}></i> Выйти</NavDropdown.Item>

            </NavDropdown>
        </button>
        ) : (

                <button type="button" className="btn btn-ouline-primary btn-sm py-0 my-0" style={{ backgroundColor: '#eeeeee' }}>
                    <LinkContainer to='/login/' className="py-0 my-0 mt-2">
                        <Nav.Link className="p-0 m-0"><i className="fas fa-sign-in-alt" ></i><br/><span className="p-0 m-0" style={{ fontSize: 10, marginTop: '0' }}>Войти</span></Nav.Link>
                    </LinkContainer>
                </button>
            
        )}

    </div>
    </div>

</Row>
    )}

export default Menu2

