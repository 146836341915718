import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { grades_a, logMathAdd_a, screen_grades_sprints_a, stat_easy_a } from '../../actions/mathActions'
import { GeistProvider, CssBaseline } from "@geist-ui/react"
import { Card, Text, Spinner, Progress } from "@geist-ui/react"
import Moment from 'react-moment'
import moment from 'moment'
import localization from 'moment/locale/ru'
import {ToastContainer, toast, Zoom, Bounce, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

toast.configure()

function Dashboard_Screen({ history }) {

    const stat_easy_ = useSelector(state => state.stat_easy_r)
    const { stat_easy_sprints, stat_easy_fives, stat_easy_topics, stat_easy_grades, stat_easy_mastery, stat_easy_sprints_today } = stat_easy_

    const notify = () => { toast.success(
            <div>Выберите нужный класс</div>
            ,
          { 
            position: "bottom-right",
            autoClose: 4000, 
            delay: 1000, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 11,
            theme: "colored",
            transition: Slide
            //  autoClose: false
                    } ) }

    useEffect(() => { 
        if (stat_easy_topics>1 && stat_easy_sprints_today==0) {
            dispatch(notify)
        }
    }, [stat_easy_sprints, stat_easy_sprints_today])
    



    // let keyword = history.location.search

    moment.updateLocale('ru', localization)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!stat_easy_sprints || stat_easy_sprints_today==0) {
            dispatch(stat_easy_a())
        }
    }, [dispatch])

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const grades_ = useSelector(state => state.grades_r)
    const { error_grades, loading_grades, grades_grades } = grades_

    const screen_grades_sprints_ = useSelector(state => state.screen_grades_sprints_r)
    const { error_screen_grades_sprints, loading_screen_grades_sprints, screen_grades_sprints } = screen_grades_sprints_

    // let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) {
        user_log = userInfo.email
    } else {
        user_log = 'unlogged'
    }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)

    useEffect(() => {
        dispatch(logMathAdd_a({
            user_log,
            page, 
            screen_width,
            screen_height,
        }))
        dispatch(grades_a())
    }, [])

    useEffect(() => {
        dispatch(screen_grades_sprints_a())
    }, [dispatch])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const goBack = () => { history.goBack() }
    
    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>

{/* Breadcrumb */} 
<ol class="breadcrumb" style={{ backgroundColor: '', padding: 0, margin: '5px' }} >
<li class="breadcrumb-item"><Link onClick={goBack} style={{ color: "#2a9df4" }}><i className="fas fa-chevron-left" style={{ marginRight: 3 }}></i>Назад</Link></li>
<li class="breadcrumb-item"><Link to={`/db/`} style={{ color: "#2a9df4" }}>Центр</Link></li>
</ol> {/* breadcrumb-end */}
                
<h4 className="mx-1 mb-2 mt-4 p-0" style={{ fontWeight: 'bold' }} >Классы</h4>
<div className="mx-1 my-2 p-0">Выберите класс:</div>

        {loading_screen_grades_sprints || loading_grades ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
            : error_screen_grades_sprints ? <Message variant='danger'>{error_screen_grades_sprints}</Message>
                : <div>

<Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

<Row>

{grades_grades.filter((grades_grades) => grades_grades.grade < 12).map(x => (
    <Col className="m-0 p-0" key={x._id} xxs={12} xs={12} sm={12} md={6} lg={4} xl={4}>
    <div className="m-4 p-0" style={{ borderRadius: 20 }}>
<Card shadow style={{ boxShadow: "0 0 10px 5px #cec7c759", backgroundColor: '', border: 'none' }} >
<Link to={`/g/${x._id}`} style={{ textDecoration: 'none' }} className="m-0 p-0">
<div style={{ margin: '-20px', padding: '10px' }}>


<div className="m-2 p-0 mb-1" style={{ fontSize: 16, lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px",}}>
    <strong>
        {x.grade} класс
    </strong>
    <span style={{ fontSize: 14, lineHeight : 1, color: "#000000", marginLeft: 25 }} >
        <i className="far fa-bookmark" style={{ color: '#2a9df4' }} > </i> 
        <span> {x.w_topics_with_sprint + x.w_topics_without_sprint} тем </span>
    </span>
</div>

{ screen_grades_sprints.filter((screen_grades_sprints) => screen_grades_sprints.grade_object == x._id).map ( z => (
<div key={z._id}>
<span>
<hr className="m-0 mt-3 p-0" style={{ color: "grey", marginLeft: '-20px', marginRight: '-10px' }} />
<div className="m-2 p-0">
<strong style={{ marginTop: 12, fontSize: 14, lineHeight : 1, color: "#000000" }}>
Ваши результаты:</strong>

<Col style={{ paddingLeft: 10 }}>
    <div style={{ marginTop: 7, fontSize: 14, lineHeight : 1, color: "#000000" }}>
    <i className="fas fa-running" style={{ color: '#2a9df4' }}> </i><strong style={{ fontSize: 14, lineHeight : 1, color: '', backgroundColor: '' }}> {z.w_grade_sprints} </strong> тестов (в <strong>{z.w_topics_sprinted}</strong> темах)</div>

<Row className="m-0 p-0">
    <Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
        <div style={{ display: '', width: '100%', hight: '100%' }} >
            <div style={{ marginTop: 10, marginBottom: 10, fontSize: 14, lineHeight : 1, color: "#000000", width: '100%' }}>
            <i className="far fa-star" style={{ color: '#2a9df4' }}> </i>
            <span> </span> <strong> {(z.w_score*1).toFixed(1)}</strong></div> 
        </div>
    </Col>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} className="m-0 p-0">
        <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '5px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
            <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
                {(z.w_percentile*1).toFixed(0)}%
                <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
            </Link>
        </div>
    </Col>
</Row>

    <div style={{ display: '', width: '100%', hight: '100%' }} >
    <div style={{ marginTop: 5, marginLeft: -11, marginBottom: 14, fontSize: 14, lineHeight : 1, color: "#000000", width: '100%' }}>
        <strong> Мастерство: </strong>  <strong> {z.w_number_of_5} из {z.w_topics_with_sprint} тем</strong>

        <Link to={`/n/n2`} style={{ marginLeft: 5, textDecoration: 'none' }}>
            <span  style={{ marginTop: 10, marginBottom: 10, fontSize: 12, lineHeight : 1, color: "grey", width: '100%', fontWeight: 'bold' }}> Что это</span>
            <i className="far fa-question-circle" style={{ color: 'grey', marginLeft: 2 }} > </i> 
        </Link>

        </div></div>

        <div style={{ marginLeft: -12 }} >
            <Progress type="secondary" style={{ color: '#2a9df4', marginTop: '30px', paddingTop: '30px' }} value={z.w_number_of_5 * 100 / z.w_topics_with_sprint} /> 
        </div>

    </Col> </div> </span>

    { z.w_grade_sprints > 0 &&
        <div style={{ lineHeight : 1, textAlign: 'right', color: 'grey' }}>
        <span style={{ lineHeight : 1, textAlign: 'right', fontSize: 12, marginRight: '20px', fontStyle: 'italic' }}>Последний тест: <Moment fromNow>{z.updatedAt}</Moment>
        </span>
        </div>
    }

</div>
))}



</div></Link></Card></div>
    </Col>
))}
</Row>

</Container>
</div>}
</GeistProvider>
        </Container>
    )
}

export default Dashboard_Screen
