import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import Menu from '../components/Menu'
import { logMathAdd_a } from '../actions/mathActions'
import { ToastContainer, toast, Zoom, Bounce, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

toast.configure()

function AccountRegister_Screen({ location, history }) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')
    const [consent, setConsent] = useState(false)
    const [consent2, setConsent2] = useState(false)

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo } = userRegister

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        if (userInfo) {
            history.push('/db/')
            dispatch(notify)
        }
    }, [history, userInfo, redirect])

    const notify = () => {
        toast.success(
            "Поздравляем! Вы успешно зарегистрировались!",
          { 
            position: "bottom-right",
            autoClose: 6000, 
            delay: 500, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 73,
            theme: "colored",
            transition: Slide
          }
        )
    }

    const submitHandler = (e) => {
        e.preventDefault()

        if (password != confirmPassword) {
            setMessage('Пароли не совпадают')
        } else if (consent==false) {
            dispatch(notify_consent)
        } else if (consent2==false) {
            dispatch(notify_consent2)
        } else {
            dispatch(register(name, email.toLowerCase(), password))
        }
    }
    
    const notify_consent = () => {
        toast.error(
            "Регистрация доступна для лиц, ознакомившихся и согласившихся с Политикой конфиденциальности и Условиями пользования сайтом",
          { 
            position: "top-right",
            autoClose: 5000, 
            delay: 0, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            toastId: 1,
            theme: "colored",
            transition: Slide
            // autoClose: false
          }
        )
    }

    const notify_consent2 = () => {
        toast.error(
            "Регистрация доступна для лиц, давших согласие на сбор и обработку персональных данных",
          { 
            position: "top-right",
            autoClose: 5000, 
            delay: 0, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            toastId: 18,
            theme: "colored",
            transition: Slide
            // autoClose: false
          }
        )
    }
    const goBack = () => { history.goBack() }

    return (
        <div>
        <FormContainer>

        <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
            <i className="fas fa-chevron-left"/> Назад
        </Button>

            <h3 style={{ marginTop: '30px', fontWeight: 'bold' }}>Регистрация</h3>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

            <Form onSubmit={submitHandler}>
            <div>

                <Form.Group controlId='name' style={{ marginTop: '10px' }}>
                    <Form.Label>Имя / Ф.И.О.</Form.Label>
                    <Form.Control
                        required
                        type='name'
                        placeholder=''
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='email' style={{ marginTop: '10px' }}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        required
                        type='email'
                        placeholder=''
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='password' style={{ marginTop: '10px' }}>
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder=''
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='passwordConfirm' style={{ marginTop: '10px' }}>
                    <Form.Label>Подтверждение пароля</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder=''
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Row>
                <Col xxs={1} xs={1} sm={1} md={1} lg={1} xl={1}><div style={{ marginTop: 15 }}><Form.Group controlId='terminal_value'><Form.Check
                type='checkbox'
                label=''
                checked={consent}
                onChange={(e) => {
                    setConsent(e.target.checked)
                }}
    ></Form.Check></Form.Group></div></Col>

                <Col ><div style={{ textAlign: '', fontSize: 14, lineHeight: 1.1, marginTop: 18 }}>
                        Я ознакомился(-ась) и согласился(-ась) с <Link to={`/n/ca`} style={{ textDecoration: 'none', color: '#2a9df4', fontWeight: '' }}>Политикой конфиденциальности</Link> и <Link to={`/n/offer`} style={{ textDecoration: 'none', color: '#2a9df4', fontWeight: '' }}>Условиями пользования сайтом</Link> 
                    </div></Col>
                </Row>

                <Row>
                <Col xxs={1} xs={1} sm={1} md={1} lg={1} xl={1}><div style={{ marginTop: 15 }}><Form.Group controlId='terminal_value'><Form.Check
                type='checkbox'
                label=''
                checked={consent2}
                onChange={(e) => {
                    setConsent2(e.target.checked)
                }}
    ></Form.Check></Form.Group></div></Col>

                <Col ><div style={{ textAlign: '', fontSize: 14, lineHeight: 1.1, marginTop: 18 }}>
                    Я согласен(-на) на сбор и обработку персональных данных.
                </div></Col>
                </Row>

                

                <Button type='submit' variant='primary' className="my-3">
                    Зарегистрироваться
                </Button>

            </div>
            </Form>


            <Row className='py-3'>
                <Col>
                <strong>
                    Уже есть аккаунт? <Link
                        to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                        Войти
                        </Link>
                </strong>
                </Col>
            </Row>
        </FormContainer>
        </div>
    )
}

export default AccountRegister_Screen
