import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { login, getUserDetails } from '../actions/userActions'
import { logMathAdd_a } from '../actions/mathActions'
import Menu from '../components/Menu'


function AccountLogin_Screen({ location, history }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/db/'

    const userLogin = useSelector(state => state.userLogin)
    const { error, loading, userInfo } = userLogin

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email.toLowerCase(), password))
        // dispatch(getUserDetails('profile'))
    }

    const goBack = () => { history.goBack() }

    return (
        <div>

        <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
            <i className="fas fa-chevron-left"/> Назад
        </Button>

        <FormContainer>
        <h3 style={{ marginTop: '30px', fontWeight: 'bold' }}>Войти</h3>
            {error && 
                <div>
                    {error == 'No active account found with the given credentials' && 
                        <Message variant='danger'>Либо имя пользователя (емейл), либо пароль не подходят. Пользователя с таким емейлом и паролем не существует в системе.
                        <br/>
                        Вы можете воспользоваться возможностью восстановления пароля.</Message>
                    }
                    <Message variant='danger'>{error}</Message>
                </div>
            }
                
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>

                <Form.Group controlId='email'>
                    <Form.Label><span>Ваш email</span></Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Введите ваш email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>


                <Form.Group controlId='password' className="mt-3">
                    <Form.Label><span>Ваш пароль</span></Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Введите ваш пароль'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary' className="my-3">
                    Войти
                </Button>
            </Form>

            <Row className='py-3'>
                <Col>
                    Новый пользователь? <Link
                        to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                            Зарегистрироваться
                        </Link>
                </Col>
            </Row>

            <Row className='py-3'>
                <Col>
                    Забыли пароль? <Link
                        to={'/pw_reset'}>
                            Восстановить пароль
                        </Link>
                </Col>
            </Row>

        </FormContainer>
        </div>
    )
}

export default AccountLogin_Screen
