import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { pw_reset_a } from '../actions/userActions'
import Menu from '../components/Menu'
import { logMathAdd_a } from '../actions/mathActions'

function AccountPwRequest_Done_Screen({ location, history }) {

    const dispatch = useDispatch()

    const userRegister = useSelector(state => state.userRegister)
    const { userInfo } = userRegister

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])
    
    return (
        <div>
            <FormContainer>
            <div style={{  padding: 20, paddingTop: 50 }}>
                <Message variant='success'>
                    На Ваш емейл была отправлена ссылка для восстановления пароля
                </Message>
            </div>
            </FormContainer>
        </div>
    )
}

export default AccountPwRequest_Done_Screen
