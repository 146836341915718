import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import { logMathAdd_a, pi1_a, user_activate_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"
import FormContainer from '../../components/FormContainer'
import { getUserDetails } from '../../actions/userActions'



function N9_Contacts_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()

    const [isActive1, setIsActive1] = useState(false)
    const [isActive2, setIsActive2] = useState(false)
    const [isActive3, setIsActive3] = useState(false)

    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        dispatch(getUserDetails('profile'))
    }, [])

    const goBack = () => { history.goBack() }

    const firstHandler = (e) => {
        e.preventDefault()
        alert('Подписка доступна для пользователей, которые ознакомились и согласились с Политикой конфиденциальности персональных данных, а также с Публичной оффертой. Пожалуйста ознакомьтесь с документами и в случае согласия поставьте галочки.')
    }
    const secondHandler = (e) => {
        e.preventDefault()
        window.open('https://google.com')
        // window.open('https://api.paybox.money/payment.php?pg_merchant_id=527852&pg_amount=5990&pg_currency=KZT&pg_description=Matematika+Plus&pg_salt=tiZuHUoDCL4JuSHX&pg_language=ru&pg_sig=eab38e38cd2d9dcc9eac85a6da66795e')
        dispatch(user_activate_a({ subscription_months: 6 }))
        dispatch(getUserDetails('profile'))
        setTimeout(() => {
            alert('Подписка активируется автоматически в течение 5 минут после оплаты.')
         }, 1000)
    }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

    <div style={{ marginTop: 20, marginBottom: 0, fontSize: 24 }}>

<FormContainer>

<Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
    <i className="fas fa-chevron-left"/> Назад
</Button>

<Card className='my-3 mx-3 p-4' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10, border: 'none' }}>

    <div className="m-0 p-0 mb-5 mt-3" style={{ lineHeight : 1, color: "#000000", textAlign: "left", paddingTop: "0px", fontSize: 36, fontWeight: 'bold' }}>
        Контакты
    </div>

{/*
    <div className="m-0 p-0 mb-2" style={{ lineHeight : 1.2, color: "#000000", textAlign: "left", paddingTop: "0px", fontSize: 16 }}>
        <i className="far fa-comments" style={{ lineHeight : 1.2, color: "#2a9df4", textAlign: "left", paddingTop: "0px" }}> </i>
        <span> </span>
        <span> Whatsapp: </span>
    </div>
*/}

    <div className="m-0 p-0 mb-2" style={{ lineHeight : 1.2, color: "#000000", textAlign: "left", paddingTop: "0px", fontSize: 16 }}>
        <i className="far fa-envelope" style={{ lineHeight : 1.2, color: "#2a9df4", textAlign: "left", paddingTop: "0px" }}> </i>
        <span> </span>
        <span> Email: analiz.one.project@gmail.com</span>
    </div>



<div>

</div>

</Card>

</FormContainer>



</div>
</div>

</Container>
</GeistProvider>
</Container>
    )
}

export default N9_Contacts_Screen

