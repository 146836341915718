import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
import Axios from 'axios'
// import logoImage from '../../alemplus_logo5.jpg'
// import logoImage2 from '../../alemplus_logo6.jpg'
// import flower from '../../flower.jpg'
import salman from '../../salmankhan6.jpg'
import OECD from '../../OECD.jpg'
import m1 from '../../m1.jpg'
import n11 from '../../n11.jpg'
import n12 from '../../n12.jpg'
import sprint from '../../sprint.jpg'
import results from '../../results.jpg'
import text from '../../text.jpg'
import video from '../../video.jpg'
import topic_results from '../../topic_results.jpg'
import topics from '../../topics.jpg'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"



function N1_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    const goBack = () => { history.goBack() }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

    <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
        <i className="fas fa-chevron-left"/> Назад
    </Button>

    <div style={{ marginTop: 20, marginBottom: 10, fontSize: 24 }}>
        <strong  >Процентиль</strong>
    </div>
    <div style={{ display: 'flex' }} className='p-3'>
        <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
            <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
        </div>
        <div>
            Процентиль показывает долю ранее пройденных тестов, по которым оценка оказалась ниже, чем у вас. <br/><br/>
            Значение процентиля "60%" означает, что ваш результат оказался выше, чем результат по 60 процентам ранее пройденных тестов. <br/><br/>
            Чем выше значение процентиля, тем лучше. <br /><br/>
            Процентиль позволяет сравнить ваши результаты с результатами других пользователей. 
        </div>
    </div>
</div>

</Container>
</GeistProvider>
</Container>
    )
}

export default N1_Screen
