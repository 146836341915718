import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"



function N5_Offer_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    const goBack = () => { history.goBack() }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

    <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
        <i className="fas fa-chevron-left"/> Назад
    </Button>

    <div style={{ marginTop: 20, marginBottom: 0, fontSize: 24 }}>


    <h5>УСЛОВИЯ ПОЛЬЗОВАНИЯ САЙТОМ</h5>
<br/>
<p>Настоящим любому лицу разрешено бесплатно пользоваться сайтом, системой, программным обеспечением, документацией, размещенным на сайте (здесь и далее - &quot;Программное обеспечение&quot;), на следующих условиях:&nbsp;</p>
<br/>
<p>Пользователь дает согласие на сбор и обработку персональных данных.</p>

<br/>

<p>Программное обеспечение предоставляется &laquo;как есть&raquo;, без каких-либо гарантий, явных или подразумеваемых, включая, помимо прочего, гарантии товарной пригодности, пригодности для определенной цели и несоответствия. Авторы и/или владельцы Программного обеспечения ни в коем случае не несут ответственность за любой ущерб, любые претензии, требования, не несут любую другую ответственность, возникающие из или в связи с Программным обеспечением или использованием Программного обеспечения.&nbsp;</p>
<br/>
<p>***&nbsp;</p>
<br/>
<h5>TERMS OF USE OF SITE&nbsp;</h5>
<br/>
<p>Permission is hereby granted, free of charge, to any person to use this software and associated documentation (the &quot;Software&quot;) subject to the following conditions:&nbsp;</p>
<br/>
<p>Person (using Software) gives consent to collection and processing of his/her personal data.</p>
<br/>
<p>The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.&nbsp;</p>
<br/>
<p>THE SOFTWARE IS PROVIDED &quot;AS IS&quot;, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.</p>




</div>
</div>

</Container>
</GeistProvider>
</Container>
    )
}

export default N5_Offer_Screen
