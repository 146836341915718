import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import './bootstrap.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
// import Popup from 'react-popup';

ReactDOM.render(
  // wrapping 'store' to 'provider' makes store available in all components in app
  // in the beginning we have empty object as our initial state - can be seen in console.log. we created store and hereby applied it to app
  // next step (not listed  bellow) - is to apply data to store thus changing state.   
  <Provider store={store}> 
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
