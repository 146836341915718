import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"



function N4_CA_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    const goBack = () => { history.goBack() }

    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>
        <Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}>

{/* БЛОК 2 */}
<div>

    <Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
        <i className="fas fa-chevron-left"/> Назад
    </Button>

    <div style={{ marginTop: 20, marginBottom: 0, fontSize: 24 }}>


    <h1>Политика конфиденциальности персональных данных</h1>
    <p style={{ fontSize: 16 }}> </p>
    <p style={{ fontSize: 16 }}>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) является публичным Договором, заключенным между владельцем Интернет-сервиса Товариществом с ограниченной ответственностью «Primeboard Kazakhstan» (Продавец) и Покупателем в соответствии со ст.387 Гражданского кодекса Республики Казахстан, основана на Законе Республики Казахстан «О персональных данных и их защите», действует в отношении информации, содержащей персональные данные Покупателей, которую Интернет-сервис https://www.matematika.plus (далее – Интернет-сервис) может получить о Покупателях во время пользования ими сайтом Интернет-сервиса.</p>
    <p style={{ fontSize: 16 }}> </p>
    <strong>1. Определение терминов</strong>
    <p style={{ fontSize: 16 }}> </p>
    <p style={{ fontSize: 16 }}>1.1. Персональные данные - сведения, относящиеся к определенному или определяемому на их основании субъекту персональных данных, зафиксированные на электронном, бумажном и (или) ином материальном носителе.</p>
    <p style={{ fontSize: 16 }}>1.2. Неличные данные - сведения, которые автоматически передаются в процессе просмотра Покупателем рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы: адрес запрашиваемой страницы, информацию cookie, IP-адрес, информацию о браузере, реферер (адрес предыдущей страницы), время доступа.</p>
    <p style={{ fontSize: 16 }}>1.3. Сбор персональных данных - действия, направленные на получение персональных данных.</p>
    <p style={{ fontSize: 16 }}>1.4. Обработка персональных данных - действия, направленные на накопление, хранение, изменение, дополнение, использование, распространение, обезличивание, блокирование и уничтожение персональных данных.</p>
    <p style={{ fontSize: 16 }}>1.5. Защита персональных данных - комплекс мер, в том числе правовых, организационных и технических, осуществляемых в целях, установленных Законом РК «О персональных данных и их защите».</p>
    <p style={{ fontSize: 16 }}>1.6. Интернет-сервис - Интернет-сайт, расположенный на сервере в г. Алматы и имеющий адрес в сети Интернет https://www.matematika.plus, на котором представлены услуги, предлагаемые Покупателям, а также условия оплаты и оказания услуг Покупателям.</p>
    <p style={{ fontSize: 16 }}>1.7. Сервисы Интернет-сайта – все услуги, доступные для использования на сайте https://www.matematika.plus и поддоменах.</p>
    <p style={{ fontSize: 16 }}>1.8. Покупатель – пользователь Интернет-сервиса.</p>
    <p style={{ fontSize: 16 }}>1.9. Продавец – Товарищество с ограниченной ответственностью «Primeboard Kazakhstan», являющееся владельцем Интернет-сервиса, БИН 161140011459.</p>
    <p style={{ fontSize: 16 }}>1.10. Cookies — это небольшие текстовые файлы, в которые браузер записывает данные с посещенных Покупателями сайтов. Cookies исполняют целый ряд функций, например, позволяют Покупателю эффективно перемещаться между страницами, запоминая его предпочтения, и, таким образом, улучшает опыт взаимодействия с Интернет-сайтом. Они также могут способствовать тому, что реклама, которую видит Покупатель онлайн, соответствует его интересам.</p>
    <p style={{ fontSize: 16 }}>1.11. IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP, позволяющий определить местонахождение Покупателя. Интернет-сервис не отслеживает местоположение, подробнее чем город и область.</p>
    <p style={{ fontSize: 16 }}> </p>
    <strong>2. Предмет политики конфиденциальности</strong>
    <p style={{ fontSize: 16 }}> </p>
    <p style={{ fontSize: 16 }}>2.1. Настоящая Политика конфиденциальности устанавливает обязательства Продавца по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Покупатель предоставляет по запросу Продавца при регистрации на сайте Интернет-сервиса и/или при оформлении заказа для получения услуги.</p>
    <p style={{ fontSize: 16 }}>2.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Покупателем путём заполнения регистрационной формы, персональной страницы на сайте Интернет-сервиса и включают в себя следующую информацию:</p>
    <p style={{ fontSize: 16 }}>2.2.1. фамилию, имя, отчество (отчество – по желанию) Покупателя;</p>
    <p style={{ fontSize: 16 }}>2.2.2. контактный телефон Покупателя;</p>
    <p style={{ fontSize: 16 }}>2.2.3. адрес электронной почты (e-mail);</p>
    <p style={{ fontSize: 16 }}>2.2.4. информация о годе рождения Покупателя;</p>
    <p style={{ fontSize: 16 }}>2.2.5. информация о классе обучения Покупателя;</p>
    <p style={{ fontSize: 16 }}>2.2.6. информация о городе расположения школы Покупателя;</p>
    <p style={{ fontSize: 16 }}>2.2.7. информация о наименовании школы Покупателя;</p>
    <p style={{ fontSize: 16 }}>2.2.8. информация о результатах тестов Покупателя на сайте https://www.matematika.plus.</p>
    <p style={{ fontSize: 16 }}>2.3. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) также подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.4.3.2. и п.4.4. настоящей Политики конфиденциальности.</p>
    <p style={{ fontSize: 16 }}> </p>
    <strong>3. Цели сбора и обработки персональных данных Покупателей</strong>
    <p style={{ fontSize: 16 }}> </p>
    <p style={{ fontSize: 16 }}>3.1. Продавец собирает, обрабатывает и хранит только те персональные данные, которые необходимы для предоставления Сервисов и/или приобретения Покупателем услуг из Интернет-сервиса.</p>
    <p style={{ fontSize: 16 }}>3.2. Персональные данные Покупателя Продавец использует в целях:</p>
    <p style={{ fontSize: 16 }}>3.2.1. идентификации Покупателя и/или заключения осуществления продажи и/или оказания услуг;</p>
    <p style={{ fontSize: 16 }}>3.2.2. обработки и получения от Покупателя платежей;</p>
    <p style={{ fontSize: 16 }}>3.2.3. предоставления Покупателю доступа к платным услугам сервиса;</p>
    <p style={{ fontSize: 16 }}>3.2.4. предоставления Покупателю эффективной клиентской поддержки;</p>
    <p style={{ fontSize: 16 }}>3.2.5. предоставления Покупателю персонализированных Сервисов;</p>
    <p style={{ fontSize: 16 }}>3.2.6. связи с Покупателем, в том числе направление уведомлений, запросов и информации, касающихся использования Сервисов, оказания услуг, а также обработки запросов и заявок от Покупателя;</p>
    <p style={{ fontSize: 16 }}>3.2.7. улучшения качества Сервисов, удобства их использования, разработка новых Сервисов и услуг;</p>
    <p style={{ fontSize: 16 }}>3.2.8. информирования Покупателя о проводимых мероприятиях и акциях;</p>
    <p style={{ fontSize: 16 }}>3.2.9. проведения статистических и иных исследований на основе неличных данных, а также оптимизации рекламных сообщений.</p>
    <p style={{ fontSize: 16 }}>3.3. Продавец не несет ответственности за сведения, предоставленные Покупателем на интернет-сайте в общедоступной форме.</p>
    <p style={{ fontSize: 16 }}>3.4. Продавец не несет ответственности за убытки, которые Покупатель может понести в результате того, что его логин и пароль стали известны третьему лицу.</p>
    <p style={{ fontSize: 16 }}> </p>
    <strong>4. Права и обязанности сторон</strong>
    <p style={{ fontSize: 16 }}> </p>
    <p style={{ fontSize: 16 }}>4.1. Покупатель обязан:</p>
    <p style={{ fontSize: 16 }}>4.1.1. предоставлять информацию о персональных данных, необходимую для пользования Интернет-сервисом;</p>
    <p style={{ fontSize: 16 }}>4.1.2. изменять, дополнять предоставленную информацию в случае обнаружения некорректных данных при пользовании Интернет-сервисом.</p>
    <p style={{ fontSize: 16 }}>4.2. Покупатель имеет право:</p>
    <p style={{ fontSize: 16 }}>4.2.1. на доступ и редактирование информации о персональных данных в любое время через веб-интерфейс, предоставляемый в рамках сервисов.</p>
    <p style={{ fontSize: 16 }}>4.3. Продавец обязан:</p>
    <p style={{ fontSize: 16 }}>4.3.1. использовать полученную информацию исключительно для целей, указанных в разделе 3 настоящей Политики конфиденциальности;</p>
    <p style={{ fontSize: 16 }}>4.3.2. обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Покупателя переданных персональных данных, за исключением передачи данных в целях выполнения заказа Покупателя (организациям почтовой связи, банкам и т.п.), а также уполномоченным государственным органам Республики Казахстан в порядке, установленным законодательством РК;</p>
    <p style={{ fontSize: 16 }}>4.3.3. принимать меры предосторожности для защиты конфиденциальности персональных данных Покупателя согласно порядку, обычно используемого для защиты такого рода информации.</p>
    <p style={{ fontSize: 16 }}>4.4. Продавец имеет право предоставлять доступ для сбора и анализа неличных данных Покупателей третьим лицам для целей статистики и оптимизации рекламных сообщений. Использование неличных данных третьими лицами регулируется их собственными политиками конфиденциальности, и Интернет-сервис не несет ответственности за их использование.</p>
    <p style={{ fontSize: 16 }}> </p>
    <strong>5. Прочие условия</strong>
    <p style={{ fontSize: 16 }}> </p>
    <p style={{ fontSize: 16 }}>5.1. Пользование Покупателем Интернет-сервисом означает его безоговорочное согласие с настоящей Политикой конфиденциальности и условиями обработки Продавцом персональных данных Покупателя.</p>
    <p style={{ fontSize: 16 }}>5.2. В случае несогласия с условиями Политики конфиденциальности Покупатель должен прекратить пользование Сайтом, Интернет-сервисом.</p>
    <p style={{ fontSize: 16 }}>5.3. Настоящая Политика конфиденциальности применяется только к сайту Интернет-сервиса https://www.matematika.plus и поддоменам. Интернет-сервис не несет ответственность за сайты третьих лиц, на которые Покупатель может перейти по ссылкам, доступным на сайте Интернет-сервиса.</p>
    <p style={{ fontSize: 16 }}>5.4. Продавец, в силу специфики способа получения информации, не проверяет достоверность предоставленных Покупателем персональных данных и не осуществляет контроль их актуальности. Однако, Продавец исходит из того, что Покупатель предоставляет достоверные персональные данные и поддерживает эти данные в актуальном состоянии. Всю ответственность, а также возможные последствия за предоставление недостоверных или не актуальных персональных данных несёт Покупатель.</p>
    <p style={{ fontSize: 16 }}>5.5. Отключение Сookies может повлечь невозможность доступа к частям сайта Интернет-сервиса, требующим авторизации.</p>
    <p style={{ fontSize: 16 }}>5.6. Интернет-сервис может осуществлять сбор статистики об IP-адресах своих Покупателей. Данная информация может использоваться с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.</p>
    <p style={{ fontSize: 16 }}>5.7. К настоящей Политике конфиденциальности и отношениям между Покупателем и Продавцом применяется действующее законодательство Республики Казахстан.</p>
    <p style={{ fontSize: 16 }}>5.8. Продавец оставляет за собой право на одностороннее изменение настоящей Политики конфиденциальности для дальнейшего совершенствования системы безопасности в соответствии с действующим законодательством РК.</p>
    <p style={{ fontSize: 16 }}>5.9. Все предложения или вопросы по поводу настоящей Политики конфиденциальности следует направлять на электронную почту: analiz.one.project@gmail.com.</p>
    <p style={{ fontSize: 16 }}>5.10. Продавец не несет ответственности за действия третьих лиц, получивших в результате использования Интернета доступ к информации о Покупателе, за последствия использования информации, которая доступна любому пользователю сети Интернет.</p>

    
    </div>
</div>

</Container>
</GeistProvider>
</Container>
    )
}

export default N4_CA_Screen
