import { logout } from '../../actions/userActions'
import React, { useState, useEffect, Component, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Table, Image, Pagination } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Link, useParams, Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { logMathAdd_a, topics_a, sprintAdd_a, lastSprint_a, grades_a, answerAdd_a, sprintsUser_a, video_a } from '../../actions/mathActions'
import { GeistProvider, CssBaseline } from "@geist-ui/react"
import { Card, Text, Tabs, Spinner, Collapse, Progress } from "@geist-ui/react"
// import HorizontalScroll from 'react-scroll-horizontal'
// import ScrollBox from '../../components/ScrollBox'
import PropTypes from 'prop-types'
import useScrollBox from '../../components/useScrollBox'
import '../../components/scrollBox.css'
import Moment from 'react-moment'
import moment from 'moment'
import localization from 'moment/locale/ru'


function Results_Screen({ match, history }) {

    moment.updateLocale('ru', localization)

    let keyword = history.location.search
    console.log(keyword)

    const scrollWrapperRef = useRef()
    const { isDragging } = useScrollBox(scrollWrapperRef)
    // let { slug } = useParams()
    // let keyword = history.location.search

    const dispatch = useDispatch()

    const [grade, setGrade] = useState('Все')
    const [topic, setTopic] = useState('Все')
    const [dateMin, setDateMin] = useState('')
    const [dateMax, setDateMax] = useState('')
    const [scoreMin, setScoreMin] = useState('')
    const [scoreMax, setScoreMax] = useState('')
    const [timeMin, setTimeMin] = useState('')
    const [timeMax, setTimeMax] = useState('')

    let history_from_search = useHistory()

    const submitHandler = (e) => {
        e.preventDefault()
        if (grade || topic || dateMin || dateMax || scoreMin || scoreMax || timeMax || timeMin ) {
            history_from_search.push(`/r/?g=${grade}&t=${topic}&dmin=${dateMin}&dmax=${dateMax}&smin=${scoreMin}&smax=${scoreMax}&tmin=${timeMin}&tmax=${timeMax}&page=1`)
        } else {
            history_from_search.push(history_from_search.push(history_from_search.location.pathname))
        }
    }

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const grades_ = useSelector(state => state.grades_r)
    const { error_grades, loading_grades, grades_grades } = grades_
    const topics_ = useSelector(state => state.topics_r)
    const { error_topics, loading_topics, topics_topics } = topics_

    const sprints_user_ = useSelector(state => state.sprintsUser_r)
    const { error_sprints_user, loading_sprints_user, sprints_user, sprints_user_page, sprints_user_pages } = sprints_user_

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        dispatch(grades_a())
        dispatch(topics_a())
    }, [dispatch])

    useEffect(() => {
        dispatch(sprintsUser_a(keyword))
    }, [dispatch, keyword])

    const goBack = () => { history.goBack() }

    return (
        <Container fluid={true} className="m-1 p-0">
        <GeistProvider>

{/* Форма */}
<Collapse.Group>
  <Collapse title="Question A">

  <Text>1
  </Text></Collapse>
</Collapse.Group>

{/* Breadcrumb */} 
<ol class="breadcrumb" style={{ backgroundColor: '', padding: 0, margin: '5px' }} >
<li class="breadcrumb-item"><Link onClick={goBack} style={{ color: "#2a9df4" }}><i className="fas fa-chevron-left" style={{ marginRight: 3 }}></i>Назад</Link></li>
<li class="breadcrumb-item"><Link to={`/db/`} style={{ color: "#2a9df4" }}>Центр</Link></li>
</ol> {/* breadcrumb-end */}

<h4 className="m-0 mt-4 mb-3 p-0">Все результаты ваших предыдущих тестов</h4>

<div style={{ marginTop: '30px', marginBottom: '30px', marginLeft: '20px' }}>
        На этой странице вы можете ознакомиться с результатами любых тестов, которые вы прошли в прошлом 
</div>

<div class="alert alert-dismissible alert-secondary" style={{ background: '#f6f6f6', marginTop: '20px', marginBottom: '20px', padding: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
<strong className='ml-1'>Фильтр для поиска результатов теста</strong>
<Form onSubmit={submitHandler} className='ml-2'><span>

<Row >
    <Col xxs={12} xs={12} sm={12} md={6} lg={6} xl={6} >
    <div class="form-group">
      <label for="exampleSelect1" class="form-label-sm mt-1 p-0 pl-2" >Класс</label>
      <select class="form-select" id="exampleSelect1" style={{ padding: '0px', paddingLeft: '10px' }} value={grade} onChange={(e) => {
        setGrade(e.target.value); } }>
        <option >Все</option>
        {grades_grades.map(x => (<option value={x._id} > 
            {x.grade} класс</option> ))}
      </select>
    </div></Col>

    <Col xxs={12} xs={12} sm={12} md={6} lg={6} xl={6} >
    <div class="form-group">
      <label for="exampleSelect1" class="form-label-sm mt-1 p-0 pl-2" >Тема</label>
      <select class="form-select" id="exampleSelect1" style={{ padding: '0px', paddingLeft: '10px' }} value={topic} onChange={(e) => {
        setTopic(e.target.value); }}>
        <option >Все</option> 
        {topics_topics.filter((topics_topics) => topics_topics.grade.grade == grade).map(x => (<option value={x._id}> {x.topic} </option> ))}
        </select>
        </div></Col>
</Row>

{/*
<Row >
    <Col xxs={12} xs={12} sm={12} md={6} lg={6} xl={6}>
    <div>Год рождения (только цифры)</div>
    <Row>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
    <Form.Group controlId='birthYearMin'>
    <Form.Label className='m-0 p-0'>от:</Form.Label>
    <Form.Control
    size='sm'
    className='m-0 p-0 px-2'
    type='text'
    placeholder=''
    value={birthYearMin}
    onChange={(e) => setBirthYearMin(e.target.value)}
    style={{ background: ' white' }}>
    </Form.Control>
    </Form.Group>
    </Col>
    <Col xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
    <Form.Group controlId='birthYearMax' className='mr-2'>
    <Form.Label className='m-0 p-0'>до:</Form.Label>
    <Form.Control
        size='sm'
        className='m-0 p-0 px-2'
        type='text'
        placeholder=''
        value={birthYearMax}
        onChange={(e) => setBirthYearMax(e.target.value)}
        style={{ background: ' white' }}>
    </Form.Control>
    </Form.Group>
    </Col>
    </Row>
    </Col>
</Row>
*/}

<button type="submit" class="btn btn-outline-secondary btn-sm" style={{ margin: '10px', marginLeft: '0px', paddingTop: '3px', paddingBottom: '3px' }}>Найти</button>

</span>
</Form>
</div>



{/* Результаты */}
{(loading_sprints_user) ? <Spinner size="large" style={{ width: '35px', margin: 'auto', marginTop: '150px', display: 'block' }}/>
    : error_sprints_user ? <Message variant='danger'>{error_sprints_user}</Message>
        : <div>

        { sprints_user &&
            <div>
                { sprints_user.length == 0 &&
                <div class="alert alert-dismissible alert-primary" style={{ margin: '30px', marginTop: '50px' }}>
                    Вы пока не прошли ни одного теста. <br/>После прохождения тестов, вы сможете найти результаты на этой странице 
                    <i className="fas fa-check" style={{ color: '#2a9df4', marginLeft: '5px' }}> </i>
                    
                </div>
            } </div> }

        <Row>
{/* TEST CARDS - START */}
{sprints_user.map(x => (
    <Col xxs={12} xs={12} sm={12} md={6} lg={6} xl={4} className="m-0 p-0">
    <Link to={`/result/${x._id}`} style={{ textDecoration: 'none' }} className="m-0 p-0">
                <div key={x._id} className="my-4 mx-4 p-0" style={{ borderRadius: 20, border: 'none' }} xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card style={{ boxShadow: "0 0 9px 3px #cec7c759", backgroundColor: '', border: 'none' }} ><div style={{ margin: '-20px', padding: '10px', paddingBottom: '7px', textAlign: 'left', border: 'none' }}>

<span style={{ marginLeft: '7px' }}> {x.topic.grade.grade} класс : </span>
<span>{x.topic.topic}</span>
<hr style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '-5px', marginRight: '-5px',  padding: '0px' }} />

<Row className="my-0 py-0">
<Col style={{ textAlign: "left" }} xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
<i className="fas fa-star" style={{ color: '#2a9df4', marginLeft: '20px' }}> </i><strong> {(x.score*1).toFixed(1)} </strong></Col>

<Col style={{ textAlign: "left" }} xxs={8} xs={8} sm={8} md={8} lg={8} xl={8} className="m-0 p-0">
<i className="fas fa-check" style={{ color: '#2a9df4', marginLeft: '0px' }}> </i><span> Правильно: {(x.nca/x.nq*100).toFixed(0)}% </span><span style={{ fontSize: 10, marginLeft: '3px', color: 'grey' }}> [{x.nca} из {x.nq}]</span></Col>
</Row>

<div style={{ display: '', width: '100%', hight: '100%', marginBottom: '10px', marginTop: '10px' }} >
<Progress type="secondary" style={{ color: '#2a9df4', marginTop: '30px', paddingTop: '30px' }} value={(x.nca/x.nq*100)} /> 
</div>

<Row className="m-0 p-0">
<Col style={{ textAlign: "left", }} xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
<i className="fas fa-stopwatch" style={{ color: '#2a9df4', marginLeft: '10px' }}> </i><span > {(x.time/1000).toFixed(1)} сек</span>
</Col>
<Col xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
    <div style={{ color: '#2a9df4', border: '1px solid #2a9df4', marginTop: '1px', width: '55px', fontSize: '10px', textAlign: 'center', fontWeight: 'bold', padding: '2px' }}>
        <Link to={`/n/n1`} style={{ textAlign: 'center', textDecoration: 'none' }}>
            {x.percentile}% 
            <i className="fas fa-question-circle" style={{ color: '#2a9df4', marginLeft: '8px', fontSize: '10px' }}> </i>
        </Link>
    </div>
</Col>
<Col style={{ textAlign: "right", lineHeight : 1 }} xxs={4} xs={4} sm={4} md={4} lg={4} xl={4} className="m-0 p-0">
<span style={{ fontSize: 12, marginRight: '10px', fontStyle: 'italic' }}> <Moment fromNow>{x.createdAt}</Moment></span>
</Col>
</Row >

    </div></Card> </div>
    </Link></Col>
))}
{/* TEST CARDS - END */}
        </Row>

        {sprints_user_pages > 1 && (
            <Pagination className="my-3" style={{ textAlign: 'center' }}>
                {[...Array(sprints_user_pages).keys()].map((x) => (
                    <LinkContainer
                        key={x + 1}
                        to={`/r/?g=${grade}&t=${topic}&dmin=${dateMin}&dmax=${dateMax}&smin=${scoreMin}&smax=${scoreMax}&tmin=${timeMin}&tmax=${timeMax}&page=${x + 1}`}
                    >                    
                        <Pagination.Item active={x + 1 === sprints_user_page}>{x + 1}</Pagination.Item>
                    </LinkContainer> ))} </Pagination> ) }

</div> }
</GeistProvider>        </Container>
    )
}
  
export default Results_Screen
