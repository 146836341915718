import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
// import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
import Axios from 'axios'
// import logoImage from '../../alemplus_logo5.jpg'
// import logoImage2 from '../../alemplus_logo6.jpg'
// import flower from '../../flower.jpg'
import salman from '../../salmankhan6.jpg'
import OECD from '../../OECD.jpg'
import m1 from '../../m1.jpg'
import n11 from '../../n11.jpg'
import n12 from '../../n12.jpg'
import sprint from '../../sprint.jpg'
import results from '../../results.jpg'
import text from '../../text.jpg'
import video from '../../video.jpg'
import topic_results from '../../topic_results.jpg'
import topics from '../../topics.jpg'
import { useHistory } from 'react-router-dom'
import { logMathAdd_a, pi1_a, process_grade_a } from '../../actions/mathActions'
// import main1 from '../../coding.png'
import { GeistProvider, CssBaseline, Spinner, Tabs, Text, Code, Spacer } from "@geist-ui/react"
import ReactPlayer from "react-player"


function Kommentarii2_Screen({ history }) {

    let keyword = history.location.search

    const dispatch = useDispatch()

    const [value, setValue] = useState('1')
    const switchHandler1 = () => setValue('1')
    const switchHandler2 = () => setValue('2')
    const switchHandler3 = () => setValue('3')
    const changeHandler = val => setValue(val)

    const [value2, setValue2] = useState('1')
    const switchHandler1001 = () => setValue2('1')
    const switchHandler1002 = () => setValue2('2')
    const switchHandler1003 = () => setValue2('3')
    const changeHandler2 = val2 => setValue2(val2)

    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        dispatch(process_grade_a())
    }, [])

    const pi_button = () => { 
        dispatch(pi1_a({
            pg_order_id: "00102",
            pg_merchant_id: "503623",
            pg_amount: "10",
            pg_description: "Ticket",
            pg_salt: "some random string",
            pg_sig: "f85868e9dec2b83af5b78c6287affbb2",
        }))
    }

    const data1 = { 
            pg_order_id: "00102",
            pg_merchant_id: "503623",
            pg_amount: "10",
            pg_description: "Ticket",
            pg_salt: "some random string",
            pg_sig: "f85868e9dec2b83af5b78c6287affbb2",
    }

    const postdata1 = () => {
        const url1 = 'https://api.paybox.money/init_payment.php';
        Axios.put(url1, data1)
            .then((res) => {
                console.log(res);
            } )
            .catch((err) => console.log(err))
    }

    const data2 = { 
        Customer: "Customer1"
    }

    const postdata2 = () => {
        const url2 = "https://reqbin.com/echo/post/json";
        Axios.post(url2, data2)
            .then((res) => {
                console.log(res);
            } )
            .catch((err) => console.log(err))
    }

    const data3 = { 
        "Customer": "Customer1"
    }

    const postdata3 = () => {
        const url3 = "https://reqbin.com/echo/post/json";
        Axios.post(url3, data3)
            .then((res) => {
                console.log(res);
            } )
            .catch((err) => console.log(err))
    }


    return (
        <Container fluid={true} className="m-0 p-0">
        <GeistProvider>

{/*MOBILE 1*/}
<Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}
className="d-xs-block d-sm-block d-md-none d-lg-none d-xl-none m-0 p-0" >

{/* БЛОК 1 */}
<Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
    <Card className='m-0 my-4 mb-5' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }}>
    <Card.Img src={m1} className="m-0 p-0" width="100%" height="200px" style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }} />
    <Card.ImgOverlay className='p-3 py-4'>
        <div className='p-0 m-1 my-3' style={{ fontSize: 40, lineHeight : 1.2, color: '#d0efff', display: "flex", justifyContent: 'center', backgroundColor: "", borderRadius: "%", fontWeight: "", opacity: '100%' }}>
            <Code style={{ fontSize: 27, lineHeight : 1.2, color: '#a1caf1', fontWeight: 'bold' }}>[matematika.plus]</Code>
        </div>
        <div class="text-center">
            <Code class="mb-0" style={{ fontSize: 21, lineHeight : 1.2, color: 'white', display: "", justifyContent: 'center', backgroundColor: "", borderRadius: "%", fontWeight: "", }}>
            Система для улучшения <span style={{ fontSize: 21, lineHeight : 1.2, color: 'white'}}>
            математических</span> навыков</Code>
        </div>
    </Card.ImgOverlay>
    </Card>
</Col>
{/* БЛОК 1 - end */}

{/* БЛОК 2 */}
    <div style={{ marginBottom: 40 }}>
{/* <div className='p-0 m-1 my-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', backgroundColor: "", borderRadius: "%", fontWeight: "", }}>
    <strong >Система позволяет:</strong>
</div> */}

<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-bar" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Изучайте <strong>тему за темой</strong>
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-brain" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Улучшайте навыки</strong>, проходя  тесты 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="far fa-bookmark" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Находите пробелы </strong> благодаря тестам и устраняйте их 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-play" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Смотрите отобранные  <strong> видео </strong>материалы (из открытых источников) 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-file-alt" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Знакомтесь с <strong> конспектами</strong>, содержащими основные выводы по темам
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Получайте статистику по своим занятиям - <strong>сколько задач</strong> решено сегодня, <strong>какие оценки</strong> получены, <strong>насколько улучшился</strong> результат? 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-line" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Получайте <strong>графики</strong>, показывающие улучшение ваших баллов (навыков) со временем
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-trophy" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Узнавайте <strong>как соотносятся</strong> ваши результаты с результатами других пользователей 
    </div>
</div>
    </div>
{/* БЛОК 2 - конец */}

{/* БЛОК 3 */}
    <div style={{ backgroundColor: "#f2f2f2", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}
    className='py-5 my-3'>
<div className='p-0 m-1 my-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', borderRadius: "%", fontWeight: "", }}>
    <strong >Почему выбирают нас?</strong>
</div>

<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-thumbs-up" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Улучшение навыков по доступной цене: </strong>полугодичный доступ к системе стоит всего <strong style={{ backgroundColor: '#2a9df4', color: 'white', paddingLeft: 5, paddingRight: 5, marginTop: 0 }}>3990</strong><strong> тенге </strong> 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-fighter-jet" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Улучшение скорости</strong> решения задач в среднем на <strong>32%</strong> - после 4 недель занятий
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-tasks" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Устранение пробелов</strong> в ранее пройденных темах и <strong>увеличение среднего балла</strong> по ним на <strong>76%</strong> - после 4 недель занятий
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Получение аналитики по <strong>успеваемости, прогрессу, сравнению</strong> с другими пользователями
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-check-square" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Дети занимаются самостоятельно!</strong> Родители не отвлекаются на обучение, тратя всего <strong>1 минуту в день на контроль</strong> прогресса при помощи аналитики
    </div>
</div>
    </div>
{/* БЛОК 3 - конец */}


{/* БЛОК 8 */}
    <div className='py-3 my-5' style={{ backgroundColor: '', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
    <Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
    <figure class="text-end">
    <blockquote class="blockquote">
      <p class="mb-0" style={{ lineHeight : 1.1, fontSize: '16px', marginTop: '0px'}}>
        <i className="fas fa-quote-left" style={{ color: 'grey', marginRight: 10,  }}> </i>
            <strong>99% детей</strong> способны <strong>полностью усвоить</strong> школьный материал <strong>по математике</strong> – <br/>за счёт устранения пробелов и обучения в своём темпе 
        <i className="fas fa-quote-right" style={{ color: 'grey', marginLeft: 10,  }}> </i>
      </p>
    </blockquote>
    <figcaption class="blockquote-footer" style={{ lineHeight : 1.1, fontSize: '', marginTop: '30px'}}>
      Salman Khan, <cite title="Source Title">Основатель Khan Academy</cite>
    </figcaption>
  </figure>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
    <div class="shadow p-2 mb-5 bg-white rounded">
        <Card class="shadow p-2 mb-5 bg-white rounded" style={{ border: 'none' }}>
            <Card.Img src={salman} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
        </Card>
    </div>
</Col>
    </Row>
    </div>
{/* БЛОК 8 - конец */}


{/* БЛОК 9 */}
<div className='py-5 my-0' style={{ backgroundColor: "#e6f1fd", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}>
<Container>

    <div className='p-0 m-1 my-3' style={{ fontSize: 30, lineHeight : 1.1, color: 'black', textAlign: 'center' }}>
        <strong>Стоимость доступа к системе</strong>
    </div>

<Row>
<Col >
    <div className='p-0 m-1 my-3 ml-2' >
        <div style={{ fontSize: 24, lineHeight : 1.2, color: '#2a9df4', textAlign: 'center'  }}><strong>[ 3990 тенге / полгода ]</strong></div>
        <div style={{  lineHeight : 1.2, textAlign: 'center', marginTop: '10px',  fontWeight: "bold" }}>Доступ к системе предоставляется на полгода</div>
    </div>
    <div className='p-0 m-1 mt-5 mb-4 ml-2' style={{ fontSize: '', lineHeight : 1.2, color: 'black', textAlign: 'center', fontStyle: 'italic' }}>
        Стоимость полугодового доступа к занятиям в системе matematika.plus равняется стоимости всего 1 часа занятий с репетитором
    </div>
</Col>
</Row>

</Container></div>
{/* БЛОК 9 - конец */}


{/* БЛОК 8 */}
<div className='py-5 my-5' style={{ backgroundColor: '', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
<Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
<figure class="text-end">
<blockquote class="blockquote">
    <p class="mb-0" style={{ lineHeight : 1.1, fontSize: '16px', marginTop: '0px'}}>
        <i className="fas fa-quote-left" style={{ color: 'grey', marginRight: 10,  }}> </i>
            <strong>Различия в заработной плате</strong>, связанные с различиями в математических навыках, <strong>очевидны</strong>. <br/><br/>
            Согласно исследованиям - увеличение на одно стандартное отклонение в оценке<strong> математической грамотности</strong> приводит к увеличению <strong>заработной платы</strong> в большинстве стран на <strong>12–15%</strong>
        <i className="fas fa-quote-right" style={{ color: 'grey', marginLeft: 10,  }}> </i>
    </p>
</blockquote>
<figcaption class="blockquote-footer" style={{ lineHeight : 1.1, fontSize: '', marginTop: '30px'}}>
Отчет OECD, <cite title="Source Title">"Numeracy Practices And Numeracy Skills" (OECD Working Paper No. 177)</cite>
</figcaption>
</figure>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
    <div class="shadow p-2 mb-5 bg-white rounded">
        <Card  style={{ border: 'none' }}>
            <Card.Img src={OECD} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
        </Card>
    </div>
</Col>
</Row>
</div>
{/* БЛОК 8 - конец */}


{/* БЛОК - Вопросы 
<div style={{ backgroundColor: "#f2f2f2", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}
className='py-3 my-3'>
<div className='p-0 m-1 my-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', borderRadius: "%", fontWeight: "", }}>
<strong >Вопросы и ответы</strong>
</div>

<button type="button" class="btn btn-link" size="small" onClick={switchHandler1} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[1] Что включает система?</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler2} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[2] За счет чего улучшаются результаты?</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler3} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[3] Кому подойдёт система?</Code></Text></button>

      <Tabs value={value} onChange={changeHandler} style={{ marginLeft: '-5000px' }}>
      
    <Tabs.Item label="[1] Что включает система?" value="1"  >
        <div style={{ marginLeft: '5000px' }}>
            <div style={{ display: 'flex' }} className='p-3 pt-0'>
                <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
                    <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
                </div>
                <div>
                    <strong>Что включает система?</strong>
                    <div>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </div>
                </div>
            </div>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[2] За счет чего улучшаются результаты?" value="2" >
        <div style={{ marginLeft: '5000px' }}>
            <div style={{ display: 'flex' }} className='p-3 pt-0'>
                <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
                    <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
                </div>
                <div>
                    <strong>За счет чего улучшаются результаты?</strong>
                    <div>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. 
                    </div>
                </div>
            </div>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[3] Кому подойдёт система?" value="3" >
        <div style={{ marginLeft: '5000px' }}>
            <div style={{ display: 'flex' }} className='p-3 pt-0'>
                <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
                    <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
                </div>
                <div>
                    <strong>Кому подойдёт система?</strong>
                    <div>
                        Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </div>
                </div>
            </div>
        </div>
    </Tabs.Item>

</Tabs>
</div>
*/}
{/* БЛОК - Вопросы - конец */}

{/* БЛОК - Скриншоты */}
<div style={{ backgroundColor: "#f2f2f2", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}
className='py-5 my-3'>
<div className='p-0 m-1 my-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', borderRadius: "%", fontWeight: "", }}>
<strong >Скриншоты: Что включает система?</strong>
</div>

{/*
<button type="button" class="btn btn-link" size="small" onClick={switchHandler1001} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[1] Скриншот этого</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler1002} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[2] Скриншот того</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler1003} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[3] Ещё скриншот</Code></Text></button>
*/}

<Tabs initialValue="1" style={{ marginLeft: '30px' }}>
      
    <Tabs.Item label="[1]" value="1"  >
        <div style={{ marginLeft: '' }}>
            <div style={{ display: '' }} className='p-1 pt-0' >
                <div >
                    <strong>Темы 6 класса (пример)</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </div>
            <div className="m-3 p-0">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={topics} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </div>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[2]" value="2"  >
        <div style={{ marginLeft: '' }}>
            <div style={{ display: '' }} className='p-1 pt-0'>
                <div >
                    <strong>Страница с результатами прошлых тестов по теме</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </div>
            <div className="m-3 p-0">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={topic_results} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </div>
        </div>
    </Tabs.Item>


    <Tabs.Item label="[3]" value="3"  >
        <div style={{ marginLeft: '' }}>
            <div style={{ display: '' }} className='p-1 pt-0'>
                <div >
                    <strong>Видеоматериалы по теме</strong>
                </div>
                <div>
                    Видеоматериалы представлены отобранными качественными видео из открытых источников, хорошо раскрывающими тему
                </div>
            </div>
            <div className="m-3 p-0">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={video} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </div>
        </div>
    </Tabs.Item>


    <Tabs.Item label="[4]" value="4"  >
        <div style={{ marginLeft: '' }}>
            <div style={{ display: '' }} className='p-1 pt-0'>
                <div >
                    <strong>Конспекты по теме</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </div>
            <div className="m-3 p-0">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={text} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </div>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[5]" value="5"  >
        <div style={{ marginLeft: '' }}>
            <div style={{ display: '' }} className='p-1 pt-0'>
                <div >
                    <strong>Тестовый вопрос</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </div>
            <div className="m-3 p-0">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={sprint} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </div>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[6]" value="6"  >
        <div style={{ marginLeft: '' }}>
            <div style={{ display: '' }} className='p-1 pt-0'>
                <div >
                    <strong>Страница с результатами прошлых тестов по всем темам</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </div>
            <div className="m-3 p-0">
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={results} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </div>
        </div>
    </Tabs.Item>



    </Tabs>
</div>

{/* БЛОК - Скриншоты - конец */}

{/* БЛОК 8 
<div className='py-3 my-5' style={{ backgroundColor: '#e6f1fd', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
<Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
    <div class="mb-0" style={{ lineHeight : 1.1, fontSize: '', marginTop: '0px'}}>
        <strong>matematika.plus полезна и для взрослых</strong>
        Повышайте остроту ума, закрепляйте знания в математических темах!
    </div>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
<div class="shadow p-2 mb-5 bg-white rounded">
    <Card class="shadow p-2 mb-5 bg-white rounded" style={{ border: 'none' }}>
        <Card.Img src={salman} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
    </Card>
</div>
</Col>
</Row>
</div>
БЛОК 8 - конец */}


{/* БЛОК - ПОЛЕЗНО ДЛЯ ВСЕХ 1 */}
<hr className='my-3' style={{ color: '', opacity: '0%' }} />
<Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
    <Card className='m-0 my-4 mb-5' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }}>
    <Card.Img src={n12} className="m-0 p-0" width="100%" height="300px" style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }} />
    <Card.ImgOverlay className='p-3 py-3'>

    <Code style={{ fontSize: 24, lineHeight : 1.1, color: '#a1caf1', fontWeight: '' }}>[matematika.plus] полезна и для <span>взрослых</span></Code>

        <Code className="m-0 pl-5">
            <div className="m-4 p-0" style={{ fontSize: 18, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Повышайте остроту ума
            </div>
            <div className="m-4 p-0" style={{ fontSize: 18, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Улучшайте знания
            </div>
            <div className="m-4 p-0" style={{ fontSize: 18, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Понимайте больше в современных технологиях и трендах!
            </div>
        </Code>
    </Card.ImgOverlay>
    </Card>
</Col>
{/* БЛОК - end */}

{/* БЛОК - МАТЕМАТИКА В КАРМАНЕ */}
<Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
    <Card className='m-0 my-4 mb-5' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }}>
    <Card.Img src={n11} className="m-0 p-0" width="100%" height="250px" style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }} />
    <Card.ImgOverlay className='p-3 py-4'>

    
    <Code style={{ fontSize: 24, lineHeight : 1.1, color: '#a1caf1', fontWeight: '' }}>Всегда<br /> при себе</Code>

        <Code className="m-0 pl-5">
            <div className="m-4 p-0" style={{ fontSize: 18, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Заниматься легко
            </div>
            <div className="m-4 p-0" style={{ fontSize: 18, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                В любой момент
            </div>
            <div className="m-4 p-0" style={{ fontSize: 18, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                В любом месте
            </div>
        </Code>
    </Card.ImgOverlay>
    </Card>
</Col>
{/* БЛОК - end */}



{/* БЛОК 8 */}
<div className='py-5 my-0' style={{ backgroundColor: '' }}>
<Container><strong className='p-0 m-0'>

<div className='p-0 m-1 mb-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', textAlign: 'center' }}>
<strong style={{ color: '' }}>
Начните улучшать навыки уже сегодня</strong>
</div>

<div  className='m-0 mb-5'>
<Row  className='m-0'  style={{ textAlign: 'center' }}>  
<Link to={`/register`} className='mx-2'><button type="button" class="btn btn-primary btn p-1 px-2"> <i className="fas fa-sign-in-alt"> </i> Зарегистрироваться</button></Link>
</Row></div>

</strong></Container></div>
{/* БЛОК 8 - конец */}

{/* БЛОК 3 - начало
<div class="alert alert-dismissible alert-primary" style={{ marginLeft: '5px', marginRight: '5px', marginTop: '35px', marginBottom: '5px', padding: '15px', backgroundColor: '#e3f1ff', opacity: '100%' }}>
<div style={{ marginTop: '0px', marginBottom: '12px', fontSize: 14, lineHeight : 1.2, }}>Cвыше <strong>200 тем</strong> (1-11 классы)</div>
<div style={{ marginTop: '12px', marginBottom: '12px', fontSize: 14, lineHeight : 1.2, }}>Более <strong>100 тысяч тестовых задач</strong></div> 
<div style={{ marginTop: '12px', marginBottom: '0px', fontSize: 14, lineHeight : 1.2, }}>30+ часов отобранных обучающих <strong>видеороликов</strong> (из открытых источников)</div>
</div>
БЛОК 3 - конец */}

</Container>
{/*MOBILE 2*/}

{/*DESCTOP 1*/}
<Container fluid={true} className="m-0 p-0" style={{ backgroundColor: 'white' }}
className="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block m-0 p-0" >

{/* БЛОК 1 */}
<Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
    <Card className='m-0 my-4 mb-5' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }}>
    <Card.Img src={m1} className="m-0 p-0" width="100%" height="250px" style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }} />
    <Card.ImgOverlay className='p-3 py-5'>
        <div className='p-0 m-1 my-3' style={{ fontSize: 40, lineHeight : 1.2, color: '#d0efff', display: "flex", justifyContent: 'center', backgroundColor: "", borderRadius: "%", fontWeight: "", opacity: '100%' }}>
            <Code style={{ fontSize: 42, lineHeight : 1.2, color: '#a1caf1', fontWeight: 'bold' }}>[matematika.plus]</Code>
        </div>
        <div class="text-center">
            <Code class="mb-0" style={{ fontSize: 27, lineHeight : 1.2, color: 'white', display: "", justifyContent: 'center', backgroundColor: "", borderRadius: "%", fontWeight: "", }}>
            Система для улучшения <span style={{ fontSize: 30, lineHeight : 1.2, color: 'white'}}>
            математических</span> навыков</Code>
        </div>
    </Card.ImgOverlay>
    </Card>
</Col>
{/* БЛОК 1 - end */}

{/* БЛОК 2 */}
    <div style={{ marginBottom: 40, fontSize: 21, paddingLeft: '10%', paddingRight: '10%' }}>
{/* <div className='p-0 m-1 my-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', backgroundColor: "", borderRadius: "%", fontWeight: "", }}>
    <strong >Система позволяет:</strong>
</div> */}

<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-bar" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Изучайте <strong>тему за темой</strong>
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-brain" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Улучшайте навыки</strong>, проходя  тесты 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="far fa-bookmark" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Находите пробелы </strong> благодаря тестам и устраняйте их 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-play" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Смотрите отобранные  <strong> видео </strong>материалы (из открытых источников) 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-file-alt" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Знакомтесь с <strong> конспектами</strong>, содержащими основные выводы по темам
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Получайте статистику по своим занятиям - <strong>сколько задач</strong> решено сегодня, <strong>какие оценки</strong> получены, <strong>насколько улучшился</strong> результат? 
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-chart-line" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Получайте <strong>графики</strong>, показывающие улучшение ваших баллов (навыков) со временем
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-trophy" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Узнавайте <strong>как соотносятся</strong> ваши результаты с результатами других пользователей 
    </div>
</div>
    </div>
{/* БЛОК 2 - конец */}

{/* БЛОК 3 */}
    <div style={{ backgroundColor: "#f2f2f2", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}
    className='py-5 my-3'>
<div className='p-0 m-1 my-3' style={{ fontSize: 36, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', borderRadius: "%", fontWeight: "", }}>
    <strong >Почему выбирают нас?</strong>
</div>

<div style={{ fontSize: 21, paddingLeft: '10%', paddingRight: '10%' }}>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-thumbs-up" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Улучшение навыков по доступной цене: </strong>полугодичный доступ к системе стоит всего <strong style={{ backgroundColor: '#2a9df4', color: 'white', paddingLeft: 5, paddingRight: 5, marginTop: 0 }}>3990 тенге</strong>
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-fighter-jet" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Улучшение скорости</strong> решения задач в среднем на <strong>32%</strong> - после 4 недель занятий
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-tasks" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Устранение пробелов</strong> в ранее пройденных темах и <strong>увеличение среднего балла</strong> по ним на <strong>76%</strong> - после 4 недель занятий
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        Получение аналитики по <strong>успеваемости, прогрессу, сравнению</strong> с другими пользователями
    </div>
</div>
<div style={{ display: 'flex' }} className='p-3'>
    <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
        <i className="fas fa-check-square" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
    </div>
    <div>
        <strong>Дети занимаются самостоятельно!</strong> Родители не отвлекаются на обучение, тратя всего <strong>1 минуту в день на контроль</strong> прогресса при помощи аналитики
    </div>
</div>
</div>
    </div>
{/* БЛОК 3 - конец */}


{/* БЛОК 8 */}
    <div className='py-3 my-5' style={{ backgroundColor: '', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
    <Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
    <figure class="text-end">
    <blockquote class="blockquote">
      <p class="mb-0" style={{ lineHeight : 1.1, fontSize: '21px', marginTop: '20px'}}>
        <i className="fas fa-quote-left" style={{ color: 'grey', marginRight: 10,  }}> </i>
            <strong>99% детей</strong> способны <strong>полностью усвоить</strong> школьный материал <strong>по математике</strong> – <br/>за счёт устранения пробелов и обучения в своём темпе 
        <i className="fas fa-quote-right" style={{ color: 'grey', marginLeft: 10,  }}> </i>
      </p>
    </blockquote>
    <figcaption class="blockquote-footer" style={{ lineHeight : 1.1, fontSize: '21px', marginTop: '30px'}}>
      Salman Khan, <cite title="Source Title">Основатель Khan Academy</cite>
    </figcaption>
  </figure>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
    <div class="shadow p-2 mb-5 bg-white rounded">
        <Card class="shadow p-2 mb-5 bg-white rounded" style={{ border: 'none' }}>
            <Card.Img src={salman} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
        </Card>
    </div>
</Col>
    </Row>
    </div>
{/* БЛОК 8 - конец */}


{/* БЛОК 9 */}
<div className='py-5 my-0' style={{ backgroundColor: "#e6f1fd", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}>
<Container>

    <div className='p-0 m-1 my-3' style={{ fontSize: 36, lineHeight : 1.1, color: 'black', textAlign: 'center' }}>
        <strong>Стоимость доступа к системе</strong>
    </div>

<Row>
<Col >
    <div className='p-0 m-1 my-3 ml-2' >
        <div style={{ fontSize: 30, lineHeight : 1.2, color: '#2a9df4', textAlign: 'center'  }}><strong>[ 3990 тенге / полгода ]</strong></div>
        <div style={{  fontSize: 24, lineHeight : 1.2, textAlign: 'center', marginTop: '10px',  fontWeight: "bold" }}>Доступ к системе предоставляется на полгода</div>
    </div>
    <div className='p-0 m-1 mt-5 mb-4 ml-2' style={{ fontSize: 21, lineHeight : 1.2, color: 'black', textAlign: 'center', fontStyle: 'italic' }}>
        Стоимость полугодового доступа к занятиям в системе matematika.plus равняется стоимости всего 1 часа занятий с репетитором
    </div>
</Col>
</Row>

</Container></div>
{/* БЛОК 9 - конец */}


{/* БЛОК 8 */}
<div className='py-5 my-5' style={{ backgroundColor: '', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
<Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
<figure class="text-end">
<blockquote class="blockquote">
    <p class="mb-0" style={{ lineHeight : 1.1, fontSize: '21px', marginTop: '0px'}}>
        <i className="fas fa-quote-left" style={{ color: 'grey', marginRight: 10,  }}> </i>
            <strong>Различия в заработной плате</strong>, связанные с различиями в математических навыках, <strong>очевидны</strong>. <br/><br/>
            Согласно исследованиям - увеличение на одно стандартное отклонение в оценке<strong> математической грамотности</strong> приводит к увеличению <strong>заработной платы</strong> в большинстве стран на <strong>12–15%</strong>
        <i className="fas fa-quote-right" style={{ color: 'grey', marginLeft: 10,  }}> </i>
    </p>
</blockquote>
<figcaption class="blockquote-footer" style={{ lineHeight : 1.1, fontSize: 21, marginTop: '30px'}}>
Отчет OECD, <cite title="Source Title">"Numeracy Practices And Numeracy Skills" (OECD Working Paper No. 177)</cite>
</figcaption>
</figure>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
    <div class="shadow p-2 mb-5 bg-white rounded">
        <Card  style={{ border: 'none' }}>
            <Card.Img src={OECD} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
        </Card>
    </div>
</Col>
</Row>
</div>
{/* БЛОК 8 - конец */}


{/* БЛОК - Вопросы 
<div style={{ backgroundColor: "#f2f2f2", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}
className='py-3 my-3'>
<div className='p-0 m-1 my-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', borderRadius: "%", fontWeight: "", }}>
<strong >Вопросы и ответы</strong>
</div>

<button type="button" class="btn btn-link" size="small" onClick={switchHandler1} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[1] Что включает система?</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler2} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[2] За счет чего улучшаются результаты?</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler3} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[3] Кому подойдёт система?</Code></Text></button>

      <Tabs value={value} onChange={changeHandler} style={{ marginLeft: '-5000px' }}>
      
    <Tabs.Item label="[1] Что включает система?" value="1"  >
        <div style={{ marginLeft: '5000px' }}>
            <div style={{ display: 'flex' }} className='p-3 pt-0'>
                <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
                    <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
                </div>
                <div>
                    <strong>Что включает система?</strong>
                    <div>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </div>
                </div>
            </div>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[2] За счет чего улучшаются результаты?" value="2" >
        <div style={{ marginLeft: '5000px' }}>
            <div style={{ display: 'flex' }} className='p-3 pt-0'>
                <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
                    <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
                </div>
                <div>
                    <strong>За счет чего улучшаются результаты?</strong>
                    <div>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. 
                    </div>
                </div>
            </div>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[3] Кому подойдёт система?" value="3" >
        <div style={{ marginLeft: '5000px' }}>
            <div style={{ display: 'flex' }} className='p-3 pt-0'>
                <div style={{ width: '30px', marginLeft: 5, marginRight: 20 }}  >
                    <i className="fas fa-poll" style={{ color: '#2a9df4', marginTop: 0, fontSize: 24 }}> </i>
                </div>
                <div>
                    <strong>Кому подойдёт система?</strong>
                    <div>
                        Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </div>
                </div>
            </div>
        </div>
    </Tabs.Item>

</Tabs>
</div>
*/}
{/* БЛОК - Вопросы - конец */}

{/* БЛОК - Скриншоты */}
<div style={{ backgroundColor: "#f2f2f2", opacity: '100%', marginLeft: '-200px', marginRight: '-20px', paddingLeft: '200px', paddingRight: '20px' }}
className='py-5 my-3'>
<div className='p-0 m-1 my-3' style={{ fontSize: 36, lineHeight : 1.2, color: 'black', display: "flex", 
justifyContent: 'center', borderRadius: "%", fontWeight: "", }}>
<strong >Скриншоты: Что включает система?</strong>
</div>

{/*
<button type="button" class="btn btn-link" size="small" onClick={switchHandler1001} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[1] Скриншот этого</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler1002} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[2] Скриншот того</Code></Text></button>
<button type="button" class="btn btn-link" size="small" onClick={switchHandler1003} style={{ margin: '0px', padding: '0px', marginBottom: '0px' }}><Text style={{ margin: '0px', padding: '0px' }}><Code style={{ margin: '0px', padding: '0px', paddingLeft: '10px', color: 'blue' }}>[3] Ещё скриншот</Code></Text></button>
*/}

<Tabs initialValue="1" style={{ marginLeft: '30px', fontSize: 21, paddingLeft: '10%', paddingRight: '10%' }}>
      
    <Tabs.Item label="[1]" value="1"  >
        <div style={{ marginLeft: '', display: 'flex' }}>
            <Col style={{  }} className='p-1 pt-0' xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div >
                    <strong>Темы 6 класса (пример)</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </Col>
            <Col className="m-3 p-0" xxs={6} xs={6} sm={6} md={6} lg={6} xl={6} >
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={topics} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </Col>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[2]" value="2"  >
        <div style={{ marginLeft: '', display: 'flex' }}>
            <Col style={{ display: '' }} className='p-1 pt-0'  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div >
                    <strong>Страница с результатами прошлых тестов по теме</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </Col>
            <Col className="m-3 p-0"  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={topic_results} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </Col>
        </div>
    </Tabs.Item>


    <Tabs.Item label="[3]" value="3"  >
        <div style={{ marginLeft: '', display: 'flex' }}>
            <Col style={{ display: '' }} className='p-1 pt-0'  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div >
                    <strong>Видеоматериалы по теме</strong>
                </div>
                <div>
                    Видеоматериалы представлены отобранными качественными видео из открытых источников, хорошо раскрывающими тему
                </div>
            </Col>
            <Col className="m-3 p-0"  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={video} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </Col>
        </div>
    </Tabs.Item>


    <Tabs.Item label="[4]" value="4"  >
        <div style={{ marginLeft: '', display: 'flex' }}>
            <Col style={{ display: '' }} className='p-1 pt-0'  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div >
                    <strong>Конспекты по теме</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </Col>
            <Col className="m-3 p-0"  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={text} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </Col>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[5]" value="5"  >
        <div style={{ marginLeft: '', display: 'flex' }}>
            <Col style={{ display: '' }} className='p-1 pt-0'  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div >
                    <strong>Тестовый вопрос</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </Col>
            <Col className="m-3 p-0"  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={sprint} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </Col>
        </div>
    </Tabs.Item>

    <Tabs.Item label="[6]" value="6"  >
        <div style={{ marginLeft: '', display: 'flex' }}>
            <Col style={{ display: '' }} className='p-1 pt-0'  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div >
                    <strong>Страница с результатами прошлых тестов по всем темам</strong>
                </div>
                <div>
                    Какой нибудь текст
                </div>
            </Col>
            <Col className="m-3 p-0"  xxs={6} xs={6} sm={6} md={6} lg={6} xl={6}>
                <div xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: '20%', paddingRight: '20%'}}>
                    <Image src={results} fluid className="m-0 p-0" style={{ widthFactor: 0.5, border: '0px solid white', margin: '10px', padding: '10px', backgroundColor: 'white', boxShadow: "0 0 10px 5px #cec7c759", borderRadius: 10 }} />
                </div>
            </Col>
        </div>
    </Tabs.Item>



    </Tabs>
</div>

{/* БЛОК - Скриншоты - конец */}

{/* БЛОК 8 
<div className='py-3 my-5' style={{ backgroundColor: '#e6f1fd', marginLeft: -120, marginRight: -20, paddingLeft: 120, paddingRight: 20 }}>
<Row>
<Col xxs={8} xs={8} sm={8} md={9} lg={10} xl={10}>
    <div class="mb-0" style={{ lineHeight : 1.1, fontSize: '', marginTop: '0px'}}>
        <strong>matematika.plus полезна и для взрослых</strong>
        Повышайте остроту ума, закрепляйте знания в математических темах!
    </div>
</Col>

<Col xxs={4} xs={4} sm={4} md={3} lg={2} xl={2}>
<div class="shadow p-2 mb-5 bg-white rounded">
    <Card class="shadow p-2 mb-5 bg-white rounded" style={{ border: 'none' }}>
        <Card.Img src={salman} className="m-0 pt-0" class="rounded" width="100%" height="100%" />
    </Card>
</div>
</Col>
</Row>
</div>
БЛОК 8 - конец */}


{/* БЛОК - ПОЛЕЗНО ДЛЯ ВСЕХ 1 */}
<hr className='my-3' style={{ color: '', opacity: '0%' }} />

<Row>
<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6}>
    <Card className='m-0 my-4 mb-5' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }}>
    <Card.Img src={n12} className="m-0 p-0" width="100%" height="400px" style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }} />
    <Card.ImgOverlay className='p-3 py-3' >

    <div style={{ padding: 50 }} >
    <Code style={{ fontSize: 32, lineHeight : 1.1, color: '#a1caf1', fontWeight: '' }}>[matematika.plus] полезна и для <span>взрослых</span></Code>

        <Code className="m-0 pl-5">
            <div className="m-4 p-0" style={{ fontSize: 21, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Повышайте остроту ума
            </div>
            <div className="m-4 p-0" style={{ fontSize: 21, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Улучшайте знания
            </div>
            <div className="m-4 p-0" style={{ fontSize: 21, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Понимайте больше в современных технологиях и трендах!
            </div>
        </Code>
    </div>
    </Card.ImgOverlay>
    </Card>
</Col>
{/* БЛОК - end */}

{/* БЛОК - МАТЕМАТИКА В КАРМАНЕ */}
<Col xxs={12} xs={12} sm={12} md={12} lg={6} xl={6}>
    <Card className='m-0 my-4 mb-5' style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }}>
    <Card.Img src={n11} className="m-0 p-0" width="100%" height="400px" style={{ boxShadow: "0 0 10px 7px #cec7c759", borderRadius: 10 }} />
    <Card.ImgOverlay className='p-3 py-4'>

    <div style={{ padding: 50 }} >
    <Code style={{ fontSize: 32, lineHeight : 1.1, color: '#a1caf1', fontWeight: '' }}>Всегда<br /> при себе</Code>

        <Code className="m-0 pl-5">
            <div className="m-4 p-0" style={{ fontSize: 21, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                Заниматься легко
            </div>
            <div className="m-4 p-0" style={{ fontSize: 21, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                В любой момент
            </div>
            <div className="m-4 p-0" style={{ fontSize: 21, lineHeight : 1.1, color: 'white', fontWeight: "", }}>
                В любом месте
            </div>
        </Code>
    </div>
    </Card.ImgOverlay>
    </Card>
</Col>
{/* БЛОК - end */}
</Row>


{/* БЛОК 8 */}
<div className='py-5 my-0' style={{ backgroundColor: '' }}>
<Container><strong className='p-0 m-0'>

<div className='p-0 m-1 mb-3' style={{ fontSize: 24, lineHeight : 1.2, color: 'black', textAlign: 'center' }}>
<strong style={{ color: '' }}>
Начните улучшать навыки уже сегодня</strong>
</div>

<div  className='m-0 mb-5'>
<Row  className='m-0'  style={{ textAlign: 'center' }}>  
<Link to={`/register`} className='mx-2'><button type="button" class="btn btn-primary btn p-1 px-2"> <i className="fas fa-sign-in-alt"> </i> Зарегистрироваться</button></Link>
</Row></div>

</strong></Container></div>
{/* БЛОК 8 - конец */}

{/* БЛОК 3 - начало
<div class="alert alert-dismissible alert-primary" style={{ marginLeft: '5px', marginRight: '5px', marginTop: '35px', marginBottom: '5px', padding: '15px', backgroundColor: '#e3f1ff', opacity: '100%' }}>
<div style={{ marginTop: '0px', marginBottom: '12px', fontSize: 14, lineHeight : 1.2, }}>Cвыше <strong>200 тем</strong> (1-11 классы)</div>
<div style={{ marginTop: '12px', marginBottom: '12px', fontSize: 14, lineHeight : 1.2, }}>Более <strong>100 тысяч тестовых задач</strong></div> 
<div style={{ marginTop: '12px', marginBottom: '0px', fontSize: 14, lineHeight : 1.2, }}>30+ часов отобранных обучающих <strong>видеороликов</strong> (из открытых источников)</div>
</div>
БЛОК 3 - конец */}

</Container>
{/*DESCTOP 2*/}


</GeistProvider>        </Container>
    )
}

export default Kommentarii2_Screen
