// store.js - описывается initial state, то есть состояние на момент загрузки страницы (не обязательно нули, так как: есть user may be signed in, local storage)
// Redux - is a State Mngt library
// react-redux - is a package to connect to Redux
// thunk - allows to create async requests from action creators - sort of middleware to stores
// redux-devtools-extension - a library that allows to connect store to browser extension - so that we can see it tool at browser 

import { createStore, combineReducers, applyMiddleware } from 'redux'   // this will be used on this page: 3 f
import thunk from 'redux-thunk' 
import { composeWithDevTools } from 'redux-devtools-extension'  // f that applies store to redux devtools

import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    // userShop_rReducer,
    userUpdateProfileReducer,
    userListReducer,
    userDeleteReducer,
    userUpdateReducer,
    // pw_reset2_r,

} from './reducers/userReducers'


import {
    grades_r,
    topics_r,
    topicsGrade_r,
    topic_r,
    logMathAdd_r,
    sprintAdd_r,
    lastSprint_r,
    randomQuestion_r,
    answerAdd_r,
    lastAnswer_r,
    answerNotFirstAdd_r,
    sprintsUser_r,
    sprint_r,
    video_r,
    grade_r,
    screen_grades_sprints_r,
    screen_topics_sprints_r,
    screen_all_topics_sprints_r,
    sprintsTopicUser_r,
    pi1_r,
    pw_change_request_r,
    new_pw_r,
    stat_r,
    process_grade_r,
    stat_easy_r,
    user_activate_r,

} from './reducers/mathReducers'

// included in 'reducer' bellow - will apear in Redux>State !!!!!!!!!!!!!!!!!!!!!!!!!
const reducer = combineReducers({       // this was created amongst FIRST // this includes reducers key-value
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userUpdateProfileReducer,
    userListReducer,
    // pw_reset2_r,
    new_pw_r,
    pw_change_request_r,
    grades_r,
    topics_r,
    topicsGrade_r,
    topic_r,
    logMathAdd_r,
    sprintAdd_r,
    lastSprint_r,
    randomQuestion_r,
    answerAdd_r,
    lastAnswer_r,
    answerNotFirstAdd_r,
    sprintsUser_r,
    sprint_r,
    video_r,
    grade_r,
    screen_grades_sprints_r,
    screen_topics_sprints_r,
    screen_all_topics_sprints_r,
    sprintsTopicUser_r,
    pi1_r,
    stat_r,
    process_grade_r,
    stat_easy_r,
    user_activate_r,

})


// const cartItemsFromStorage = localStorage.getItem('cartItems') ?
//     JSON.parse(localStorage.getItem('cartItems')) : []

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

// const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
//     JSON.parse(localStorage.getItem('shippingAddress')) : {}

// const collectionIdFromStorage = localStorage.getItem('collectionId') ?
//     JSON.parse(localStorage.getItem('collectionId')) : {}

// const collectionNameFromStorage = localStorage.getItem('collectionName') ?
//     JSON.parse(localStorage.getItem('collectionName')) : {}

// const subcollectionIdFromStorage = localStorage.getItem('subcollectionId') ?
//     JSON.parse(localStorage.getItem('subcollectionId')) : {}

// const subcollectionNameFromStorage = localStorage.getItem('subcollectionName') ?
//     JSON.parse(localStorage.getItem('subcollectionName')) : {}

// const subsubcollectionIdFromStorage = localStorage.getItem('subsubcollectionId') ?
//     JSON.parse(localStorage.getItem('subsubcollectionId')) : {}

// const subsubcollectionNameFromStorage = localStorage.getItem('subsubcollectionName') ?
//     JSON.parse(localStorage.getItem('subsubcollectionName')) : {}

// initialState - at start is an empty Object:
// - reducers - create and change state
// - const initialState - defines state at load - empty or previously filled

const initialState = {
    // cart: {
    //     cartItems: cartItemsFromStorage,
    //     shippingAddress: shippingAddressFromStorage,
    // },
    userLogin: { userInfo: userInfoFromStorage },

    // subcollections_r: {
    //     collectionId: collectionIdFromStorage,
    //     collectionName: collectionNameFromStorage,
    //     subcollections: [],
    // },
    // subsubcollections_r: {
    //     subcollectionId: subcollectionIdFromStorage,
    //     subcollectionName: subcollectionNameFromStorage,
    //     subsubcollections: [],
    // },
    // products_S3_r: {
    //     subsubcollectionId: subsubcollectionIdFromStorage,
    //     subsubcollectionName: subsubcollectionNameFromStorage,
    //     products_s3: [],
    // },
}

const middleware = [thunk]


const store = createStore(reducer, initialState,        // 1st. Creating Store. there is initial state, and reducers that perform actions
    composeWithDevTools(applyMiddleware(...middleware)))
// ... middleware - spread operator

export default store    // sintaxis. on other pages 'store' is being imported: index.js




