import { logout } from '../../actions/userActions'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'  // useDispatch - to fire off listProducts as seen in code bellow
                                                        // useSelector - allows to select certain parts of state (or redux store) 
import { Container, Row, Col, Form, Button, Table, Card, Image } from 'react-bootstrap'
// import Product from '../components/Product'
import Loader from '../../components/Loader'
import Spinner from '../../components/Spinner'
import Message from '../../components/Message'
// import Paginate from '../components/Paginate'
import { Link, useParams, Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import Navigation from '../../components/Navigation'
import Menu from '../../components/Menu'
// import logoImage from '../../alemplus_logo5.jpg'
import { useHistory } from 'react-router-dom'
// import main1 from '../../coding.png'
import { logMathAdd_a, topics_a, sprintAdd_a, lastSprint_a, topic_a, answerAdd_a, sprintsUser_a, video_a } from '../../actions/mathActions'



function Video_Screen({ match, history }) {

    // let { slug } = useParams()
    console.log(match)
    // let keyword = history.location.search

    const dispatch = useDispatch()


    // const [keyword_from_search, setKeyword_from_search] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const topics_ = useSelector(state => state.topics_r)
    const { error_topics, loading_topics, topics_topics, topics_page, topics_pages } = topics_

    const topic_ = useSelector(state => state.topic_r)
    const { error_topic, loading_topic, topic_topic, topic_page, topic_pages } = topic_

    const sprints_user_ = useSelector(state => state.sprintsUser_r)
    const { error_sprints_user, loading_sprints_user, sprints_user, sprints_user_page, sprints_user_pages } = sprints_user_

    const lastSprint_ = useSelector(state => state.lastSprint_r)
    const { error_last_sprint, loading_last_sprint, last_sprint } = lastSprint_

    const sprintAdd_ = useSelector(state => state.sprintAdd_r)
    const { success_sprint_add, loading_sprint_add } = sprintAdd_

    const video_ = useSelector(state => state.video_r)
    const { error_video, loading_video, video_video } = video_

    // let history_from_search = useHistory()

    let user_log = ''
    if (userInfo) { user_log = userInfo.email } else { user_log = 'unlogged' }
    let page = history.location.pathname
    let screen_width = useState(window.innerWidth)
    let screen_height = useState(window.innerHeight)
    useEffect(() => { dispatch(logMathAdd_a({ user_log, page, screen_width, screen_height, })) }, [])

    useEffect(() => {
        dispatch(topic_a(match.params.id))
        localStorage.setItem('topic', JSON.stringify(match.params.id))
    }, [])

    useEffect(() => {
        dispatch(sprintAdd_a(match.params.id))
    }, [])

    useEffect(() => {
        dispatch(lastSprint_a())
    }, [success_sprint_add])

    useEffect(() => {
        dispatch(video_a(match.params.id))
    }, [])

    const goBack = () => { history.goBack() }

    const parseLines = (value) => value.replace(/(\\n)/g, "\n");



    return (
        <Container fluid={true} className="m-1 p-0">

        {loading_video ? <Spinner />
            : error_video ? <Message variant='danger'>{error_video}</Message>
                :
<div>
{/* Breadcrumb */} <ol class="breadcrumb">
<li class="breadcrumb-item"><Link to={`/d/`}>Классы</Link></li>
{ (topic_topic.grade) ? (
    <li class="breadcrumb-item"><Link to={`/g/${topic_topic.grade._id}`}>
        {topic_topic.grade.grade} класс
    </Link></li>    ):(<p> </p>) }
{ (topic_topic) ? (
    <li class="breadcrumb-item"><Link to={`/to/${topic_topic._id}/?t=3`}>            
        {topic_topic.topic}
    </Link></li>    ):(<p> </p>) }
<li class="breadcrumb-item">Видео-материалы</li>
</ol> {/* breadcrumb-end */}

<Button variant="outline-secondary" onClick={goBack} size="sm" className="mb-3">
    <i className="fas fa-chevron-left"/> Назад
</Button>

<h3 className="m-0 mb-3 p-0">Обучающий материал по теме "{topic_topic.topic}"</h3>

    {video_video.map(x => (
<Col key={x._id} className="my-4 mx-0 p-0" xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>                                
<div className="shadow-sm p-1 mb-3 bg-white rounded"><Card.Body className="my-0 mx-0 px-1 py-2">
    <Col xxs={12} xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="m-0 my-0 mb-2 p-2" style={{ fontSize: 14, lineHeight : 1.2, color: 'black' }}>
            <div name="description"              
            multiline
            required
            type="text"
            value={parseLines(x.description)}
            >
                {x.description}
            </div>
        </div></Col>
    <iframe src={x.url} frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video1' />
</Card.Body> </div> </Col>
    ))}

{topic_topic.testDisabled && 
    <div  className='m-0 my-5' >
    <div>Тема является описательной и ознакомительной. Поэтому для данной темы тест не предусматривается.</div>
    </div>}    

{!topic_topic.testDisabled && 
<div  className='m-0 mb-3' >
<span><Link to={`/s/${last_sprint._id}`} className='mx-0'><button type="button" class="btn btn-primary btn-sm p-1 px-2 m-3" disabled={(loading_last_sprint || loading_sprint_add)}> <i className="fas fa-"></i>+ Пройти новый тест</button></Link>
</span></div> }

</div> }

        </Container>
    )
}

export default Video_Screen
